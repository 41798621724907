import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FlightOffer, FlightRequest } from 'src/models/FlightOffer';

@Injectable({
  providedIn: 'root'
})
export class FlightsService {

  constructor(private http: HttpClient) { }

  getFlight(Origin: string, Destination: string,  DepartureDate: string, ReturnDate: string, Adults: number): Observable<FlightRequest> {
    return this.http.get<FlightRequest>('/api/flights/flight/' + Origin + '/' + Destination + '/' + DepartureDate + '/' + ReturnDate + '/' + Adults)
      .pipe(
        retry(2)
      )
  }

  getCheapDates(Origin: string, Destination: string): Observable<FlightOffer[]> {
    return this.http.get<FlightOffer[]>('/api/flights/cheapDates/' + Origin + '/' + Destination)
      .pipe(
        retry(2)
      )
  }
}
