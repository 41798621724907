<div class="Viator" *ngIf="this.willHaveViatorProducts">
    <div class="Viator-title">
        <!-- Wanna tour? -->
        View tours
    </div>
    <div class="Viator-showTours" (click)="this.rotate()">
        {{ this.showTours ? 'Collapse tours' : 'Show highest rated tours from Viator below' }}  <img class="Viator-chevron" [ngClass]="this.showTours ? 'rotate' : ''" src="../../assets/chevron.svg">
    </div>

    <div *ngIf="this.showLoadingIcon" class="Viator-loading">
        Loading....
    </div>
    
    <div class="Viator-tours" *ngIf="this.viatorProducts && this.showTours" [@slideInOut]>
        <div class="Viator-tourWrapper" *ngFor="let product of this.viatorProducts.products">
            <img class="Viator-tourImage" src="{{product.images[0].variants[2].url}}" />

            <div class="Viator-tourContentWrapper">
                <div class="Viator-tourPlace">
                    {{ this.place }}
                </div>
                <div class="Viator-tourTitle">
                    {{product.title}}
                </div>
                
                <div class="Viator-tourRating">
                        <div class="ratings">
                                <div class="empty-stars"></div>
                                <div class="full-stars" [ngStyle]="{'width': '' + product.reviews.combinedAverageRating / 5 * 100 + '%'}"></div>
                        </div>
                        <div class="Viator-tourReviews">
                            {{product.reviews.totalReviews}}
                        </div>
                </div> 
                <div class="Viator-tourPrice">
                    from ${{product.pricing.summary.fromPrice}}
                </div>
                <div class="Viator-tourLinkWrapper">
                    <a class="Viator-tourLink" href="{{product.productUrl}}" target="_blank">View on Viator</a>
                </div>
            </div>
        </div>
    </div>
</div>
