<section class="Itinerary">
    <div class="Itinerary-header">
        <div class="Itinerary-close">
            <a href="/"><img src="../../assets/orangeclose.svg"></a>
        </div>
        <div class="Itinerary-header--center">
            {{ this.itineraryName ? this.itineraryName : 'Itinerary'}}
        </div>
        <div class="Itinerary-headerGroup">
            <button class="Itinerary-shareGroup" (click)="showItineraryShareModal()">
                Share <img class="Itinerary-shareImage" src="../../assets/link.svg">
            </button>
            <button class="Itinerary-button" (click)="this.itineraryId === -1 ? showItineraryCreateModal(): saveItinerary()" *ngIf="!this.isSaving && !this.isSaved">
                Save
            </button>
            <button class="Itinerary-button Itinerary-saving" *ngIf="this.isSaving">
                Saving...
            </button>
            <button class="Itinerary-button Itinerary-saved" *ngIf="this.isSaved" [@rotator]>
                Saved!
            </button>
        </div>
    </div>
    
    <div class="Itinerary-content">
        <div class="Itinerary-separator Itinerary-separator--black"></div>
        <div *ngIf="this.itineraryDestinations" cdkDropList (cdkDropListDropped)="drop($event)">
            <div *ngFor="let destination of this.itineraryDestinations; let i = index" cdkDrag>
                <!-- TODO, this should probably be a caller to set the height rather than hardcoded -->
                <div *cdkDragPlaceholder style="height: 216px"></div>
                <app-transit cdkDragDisabled [(transit)]="this.itineraryTransits[i]"></app-transit>
                <div class="Itinerary-separator Itinerary-separator--black"></div>
                <app-itinerarycard [(destination)]="this.itineraryDestinations[i]" [(destinations)]="this.itineraryDestinations" [(transits)]="this.itineraryTransits"></app-itinerarycard>
                <div class="Itinerary-separator Itinerary-separator--black"></div>
            </div>
        </div>

        <div class="Itinerary-addNext">
            <img class="Itinerary-plusCircle" src="../../assets/plus-circle.svg" (click)="openSearch()">
        </div>

    </div>
    <div class="Itinerary-footer">
        <div class="Itinerary-footerItem">
            <div class="Itinerary-footer--bigText">{{ this.itineraryDestinations.length }}</div> place{{ this.itineraryDestinations.length == 1 ? '' : 's'}}
        </div>
        <div class="Itinerary-footerItem">
            <div class="Itinerary-footer--bigText">{{ this.getDays() }}</div> day{{ this.getDays() == 1 ? '': 's' }}
        </div>
        <div class="Itinerary-footerItem">
            <div class="Itinerary-footer--bigText">${{ this.getTotal() | number: '1.2-2' }}</div> total
        </div>
    </div>
</section>

<app-modal width="1000" type="itinerary-search">
    <div class="Itinerary-searchAreaWrapper">
        <div class="Itinerary-searchArea">
            <div class="Itinerary-searchInputWrapper">
                <img src="../../assets/search.svg">
                <input list="placeData" class="Itinerary-searchInput" (input)="this.watchPlaceInput($event.target.value)" placeholder="Madrid" [(ngModel)]="this.userEnteredPlace">
            </div>
            <div class="Itinerary-typeAheadPlaces">
                <div *ngFor="let place of this.typeAheadPlaces">
                    <div class="Itinerary-typeAhead" (click)="this.addItineraryNode(place)">
                        <img *ngIf="place.TestPhoto" [src]="place.TestPhoto.small" class="Itinerary-typeAheadImage">
                        <div class="Itinerary-typeAheadContent">
                            <div class="Itinerary-typeAheadTitle">
                                {{ place.Name }}
                            </div>
                            <div *ngIf="place.TestPhoto" class="Itinerary-typeAheadCredit">
                                Photo by <a class="Card-photoLink" href="{{place.TestPhoto.links}}">{{place.TestPhoto.photographer}}</a> on <a class="Card-photoLink" href="{{place.TestPhoto.htmlPhoto}}">Unsplash</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<app-modal width="1000" type="itinerary-save">
    <div class="Itinerary-modal">
        <div class="Itinerary-modalContentWrapper">
            <div class="Itinerary-modalContent">
                <div class="Itinerary-modalInput">
                    <input  class="Itinerary-input" placeholder="Itinerary Name" [(ngModel)]="itineraryName">
                </div>
                <div class="Itinerary-modalInput">
                    <input  class="Itinerary-input" placeholder="Notes" [(ngModel)]="itineraryNotes">
                </div>
                <div>
                    <button class="Itinerary-modalSave" (click)="saveItinerary()">Save</button>
                </div>
            </div>
        </div>
    </div>
</app-modal>


<app-modal width="1000" type="itinerary-share">
    <div class="Itinerary-share Itinerary-modal" *ngIf="showItineraryShare">
        <div class="Itinerary-modalContentWrapper">
            <div class="Itinerary-modalContent">
                <div>
                    <input  class="Itinerary-input" class="Itinerary-modalInput" placeholder="Friend Email" [(ngModel)]="friendEmail">
                </div>
                <div>
                    <input  class="Itinerary-input" class="Itinerary-modalInput" placeholder="Friend Name" [(ngModel)]="friendName">
                </div>
                <div>
                    <button class="Itinerary-modalShare" (click)="shareItinerary()">Share</button>
                </div>
            </div>

        </div>
    </div>
</app-modal>