import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { ItineraryService } from './itinerary.service';
import { Place, Photo } from 'src/models/Place';
import { SharedApiService } from '../shared/shared-api.service';
import { Transit } from 'src/models/Transit';
import { Destination, Luxury, Itinerary } from 'src/models/Itinerary';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { OverlayService } from '../overlay/overlay.service';
import { SharedService } from '../shared/shared.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable, Subscription } from 'rxjs';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));


@Component({
  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-10%)'}),
        animate('350ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('350ms ease-in', style({transform: 'translateY(-10%)'}))
      ])
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({transform: 'translateY(100%)'}),
        animate('350ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('350ms ease-in', style({transform: 'translateY(100%)'}))
      ])
    ]),
    trigger('rotator', [
      transition(':enter', [
        style({transform: 'rotate(180deg)'}),
        animate('350ms ease-in', style({transform: 'rotate(0deg)'}))
      ])
    ])
  ]
})
export class ItineraryComponent implements OnInit {
  rotateChevron: boolean
  numPeople: number;
  typeAheadPlaces: Place[];
  userEnteredPlace: Place;
  itineraryDestinations: Destination[];
  itineraryDays: number[];
  itineraryCosts: number[];
  itineraryTransits: Transit[];
  itineraryPublic: boolean;
  itineraryName: string;
  itineraryNotes: string;
  showSearch: boolean;
  showItineraryModal: boolean;
  showItineraryShare: boolean;
  itineraryId: number = -1;
  friendEmail: string;
  friendName: string;
  isSaving: boolean;
  isSaved: boolean;
  shareToken: string;

  constructor(
    private itineraryService: ItineraryService, 
    private sharedApiService: SharedApiService, 
    private sharedService: SharedService, 
    private route: ActivatedRoute, 
    private authService: AuthService, 
    private overlayService: OverlayService) { }

  ngOnInit() {
    this.itineraryDestinations = [];

    this.route.params.subscribe(params => {
      if (!params.id) {
        return;
      }
      this.itineraryId = params.id;
      if (this.authService.isLoggedIn()) {
        this.itineraryService.userIsAuthorized(params.id).subscribe(isAuthorized => {
          if (isAuthorized) {
            this.itineraryService.getItineraryWithId(params.id).subscribe(itinerary => {
              this.itineraryDestinations = itinerary[0].Destinations;
              this.itineraryTransits = itinerary[0].Transits;
              this.itineraryName = itinerary[0].Name;
            });
          } else {
            window.location.assign('/');    
          }
        });
      } else {
        this.route.queryParams.subscribe(queryParams => {
          if (queryParams.share) {
            this.shareToken = queryParams.share;
            this.itineraryService.getTokenIsAuthorizedForId(this.itineraryId, this.shareToken).subscribe(isAuthorized => {
              if (isAuthorized) {
                this.itineraryService.getItineraryWithIdAndToken(this.itineraryId, this.shareToken).subscribe(itinerary => {
                  this.itineraryDestinations = itinerary[0].Destinations;
                  this.itineraryTransits = itinerary[0].Transits;
                  this.itineraryName = itinerary[0].Name;
                });
              } else {
                window.location.assign('/');
              }
            });
          } else {
            window.location.assign('/');
          }
        }) 

      }
    });
    this.rotateChevron = false;
    
    this.itineraryDays = [];
    this.itineraryCosts = [];
    this.itineraryTransits = [];
    this.itineraryPublic = false;
    this.showSearch = false;
    this.showItineraryModal = false;
    this.showItineraryShare = false;
    this.isSaved = false;
    this.isSaving = false;
  }

  rotate() {
    this.rotateChevron = !this.rotateChevron;
  }

  getQueryParams(): Subscription {
    return this.route.queryParams.subscribe(params => {
      if (params.share) {
        return params.share;
      }
    })
  }

  setQueryParams() {
    
  }

  getImages() {
    this.typeAheadPlaces.forEach(element => {
      this.sharedApiService.getPhotosForPlaceName(element.Name)
        .subscribe(data => {
          element.TestPhoto = this.sharedService.randomPhoto(data);
        })
    })
  }

  getDays(): number {
    return this.itineraryDestinations.reduce((sum, destination) => sum + destination.NumberOfDays, 0);
  }

  getTotal(): number {
    return this.itineraryDestinations.reduce((sum, destination) => sum + destination.Cost, 0);
  }

  addItineraryNode(node: Place) {
    let destination = {
      Place: node,
      Cost: node.WeightedMidRangeCost,
      Luxury: Luxury.MidRange,
      NumberOfPeople: 1,
      NumberOfDays: 1,
      Notes: "",
    };
    this.itineraryDestinations.push(destination);
    this.itineraryTransits.push({
      Origin: null,
      Destination: null,
      Mode: null,
      Duration: null,
      Cost: 0
    });
    this.overlayService.close();
  }

  watchPlaceInput(value: string) {
    if (value.length > 1) {
      this.itineraryService.getPlacesWithFragment(value).subscribe(data => {
        this.typeAheadPlaces = data;
        this.getImages();
      });
    }
  }

  async waitIsSaved() {
    await sleep(1000);
    this.isSaved = false;
  }

  // TODO: figure out is this should be async, and fix date issue
  saveItinerary() {
    if (!this.authService.isLoggedIn()) {
      return this.overlayService.open("login");
    }
    this.isSaving = true;

    const itinerary = {
      Name: this.itineraryName,
      Destinations: this.itineraryDestinations,
      Days: this.getDays(),
      Cost: this.getTotal(),
      Transits: this.itineraryTransits,
      StartDate: new Date(2023, 3, 18),
      EndDate: new Date(2023, 3, 18),
      Public: false,
      Notes: this.itineraryNotes,
      Id: this.itineraryId,
    } as Itinerary;

    if (this.itineraryId !== -1) {
      this.itineraryService.updateItinerary(itinerary).subscribe((data) => {
        this.showItineraryModal = false;
      });
      this.isSaving = false;
      this.isSaved = true;
      this.waitIsSaved();
      return;
    }
    this.itineraryService.postNewItinerary(itinerary).subscribe((data) => {
      this.itineraryId = data.Id;
      this.showItineraryModal = false;
      this.overlayService.close();
      this.isSaving = false;
      this.isSaved = true;
      return this.waitIsSaved();
    });
  }

  showItineraryCreateModal() {
    this.overlayService.open('itinerary-save');
  }

  openSearch() {
    this.overlayService.open('itinerary-search');
  }
  
  // first you must be logged in
  showItineraryShareModal() {
    if (this.authService.isLoggedIn()) {
      this.showItineraryShare = true;
      this.overlayService.open('itinerary-share')
    } else {
      this.overlayService.open("login");
    }
  }

  // then we must make sure the id != -1, and if it does we need to first save the itinerary, catch the id, then share it.
  async shareItinerary() {
    if (this.itineraryId == -1) {
      // this won't work because posting a new itinerary is an asynchronous function
      await this.saveItinerary();
    }

    this.itineraryService.shareItinerary(this.itineraryId, this.friendName, this.friendEmail).subscribe( () => {
      // need to show a success message
      this.showItineraryShare = false;
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.itineraryDestinations, event.previousIndex, event.currentIndex);
    moveItemInArray(this.itineraryTransits, event.previousIndex, event.currentIndex);
  }
}
