<div class="Search-header">
    Where are you going?
</div>

<section class="Search-section">
    <div class="Search Search-main" [ngClass]="searchExecuted == true ? 'Search-main--right' : ''">
        <div class="Search-content">
            <div class="Search-row Search-searchBar">
                <span class="Search-budgetText">
                    Destination:
                </span>
                <input class="Search-input" placeholder="Singapore" [(ngModel)]="trip.Place" (keyup.enter)="navigate()">
                <button class="Search-cta Search-travel" (click)="navigate()">
                    Go
                </button>
                <!-- <img class="Search-filters" src="../../assets/filter.svg" (click)="sharedService.openFilters()"> -->
            </div>
        </div>
        <!-- TODO <div>
            Show costs based on filters rather than pppd
            <button>
                off
            </button>
        </div> -->
    </div>
</section>

<div *ngIf="this.tripTrips && this.tripTrips.length > 0">
    <section class="Search-photos" *ngIf="this.tripTrips">
        <app-banner [trip]="this.firstTrip()"></app-banner>
    </section>
        
    
    <div class="Search-budgets--text" *ngIf="this.query != ''">
        Costs
    </div>
    
    <section class="Search-budgets">
    
        <article class="Search-averageArticle" *ngFor="let average of averageCosts; let i=index">
            <div class="Search-averages" (click)="filterButton(i)"
            [ngClass]="i == 0 && budgetFilter == true ? 'Search-averagesFilledBudget' : 
            i == 1 && mediumFilter == true ? 'Search-averagesFilledMedium' :
            i == 2 && luxuryFilter == true ? 'Search-averagesFilledLuxury' : ''"
            >
                <h2 class="Search-heading" 
                [ngClass]="i == 0 && budgetFilter == false ? 'Search-heading--' + i : 
            i == 1 && mediumFilter == false ? 'Search-heading--' + i :
            i == 2 && luxuryFilter == false ? 'Search-heading--' + i : ''">
                    {{i == 0 ? 'Budget' : i == 1 ? 'Mid-range' : i == 2 ? 'Luxury' : ''}}
                </h2>
                <div>
                    <div class="Search-cost">
                        <div>
                            <!-- <span *ngIf="this.average == '0'">We don't have enough information yet! <a href="/create"> Help us out</a></span> -->
                            <span *ngIf="this.average == '0'">We don't have enough information yet!</span>
                            <span *ngIf="this.average != '0'">Average: <span class="Search-cost--average">${{this.average | number: '1.0-0'}}</span> / pppd</span>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </section>
    
    <div class="Search-budgets--text" *ngIf="this.query != '' && this.tripAirport">
        Flights
    </div>

    <section class="Search-flights" *ngIf="this.query != ''">
        <div *ngIf="this.tripAirport">
            <app-flights [tripAirport]='tripAirport'></app-flights>
        </div>
    </section>

    <div class="Search-budgets--text" *ngIf="this.query != '' && this.visa">
        Visas
    </div>
    <div *ngIf="this.visa">
        <app-visas [visa]='this.visa' [visaColor]='this.visaColor'></app-visas>
    </div>
    
    <div class="Search-budgets--text" *ngIf="this.query != ''">
        Learn
    </div>
    <section class="Search-ai" *ngIf="this.query != ''">
        <div class="Search-fullRow">
            <div class="Search-aiText">
                Wanna learn something random about {{ this.query }}?<sup> * </sup> 
            </div>
            <div class="Search-execution">
                <input [(ngModel)]="this.aiQuery" placeholder="Tell me something about {{ this.query }}" class="Search-input Search-aiInput">
                <button (click)="this.askAI()" class="Search-buttonFlat">Ask ChatGPT</button>
            </div>
            <div *ngIf="this.showAiLoadingIcon" class="Search-aiLoading">
                Loading....
            </div>
            <div class="Search-aiResponse" id="typewriter">
                {{ this.typewriterDisplay }}
            </div>
            <div>
                <sup> * </sup>Data from ChatGPT can be inaccurate.
            </div>
        </div>
    </section>

    <div> 
        <div class="Search-budgets--text">
            Tour
        </div>
        <div class="Search-viator">
            <app-viator [place]='trip.Place' [country]='trip.Country'></app-viator>
        </div>
    </div>
    
    <div class="Search-switch">
        <div *ngIf="searchExecuted">
            {{ this.query }} Trips
        </div>
    </div>
    
    <section class="Search-list" *ngIf="this.budgetFilter">
        <article *ngFor="let trip of budgetTrips; let i=index">
            <app-card [trip]='trip'></app-card>
        </article>
    </section>
    
    <section class="Search-list" *ngIf="this.mediumFilter">
        <article *ngFor="let trip of mediumTrips; let i=index">
            <app-card [trip]='trip'></app-card>
        </article>
    </section>
    
    <section class="Search-list" *ngIf="this.luxuryFilter">
        <article *ngFor="let trip of luxuryTrips; let i=index">
            <app-card [trip]='trip'></app-card>
        </article>
    </section>
    
    <div class="Search-insert"></div>
</div>

<div class="Search-noResults" *ngIf="this.tripTrips && this.tripTrips.length == 0 && this.trip && this.trip.Place != ''">
    <div>
        Oh no! We don't have what you're looking for :( drat...
    </div>
    <div>
        Try searching <a href="/place?q=Vietnam">Vietnam</a> instead?
    </div>
</div>

<div class="Search-noResults" *ngIf="!this.tripTrips && !this.query">
    Looks like you've landed on the moon! Can I interest you in <a href="/place?q=Thailand">Thailand</a> instead?
</div>