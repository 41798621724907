import { Injectable } from '@angular/core';
import { Place } from 'src/models/Place';
import { retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Itinerary, Destination } from 'src/models/Itinerary';

@Injectable({
  providedIn: 'root'
})
export class ItineraryService {

  constructor(private http: HttpClient) { }

  getPlacesWithFragment(Framgent: string): Observable<Place[]> {
    return this.http.get<Place[]>('/api/places/startsWith/' + Framgent)
      .pipe(
        retry(2)
      )
  }

  getItineraryWithId(id: number): Observable<Itinerary> {
    return this.http.get<Itinerary>(`/api/itineraries/${id}`)
      .pipe(
        retry(2)
      )
  }

  getItineraryWithIdAndToken(id: number, token: string): Observable<Itinerary> {
    return this.http.get<Itinerary>(`/api/itineraries/${id}/${token}`)
      .pipe(
        retry(2)
      )
  }

  userIsAuthorized(itineraryId: number): Observable<Boolean> {
    return this.http.get<Boolean>(`/api/itineraries/userIsAuthorized/${itineraryId}`)
      .pipe(
        retry(2)
      )
  }

  getTokenIsAuthorizedForId(id: number, token: string): Observable<Boolean> {
    return this.http.get<Boolean>(`/api/itineraries/tokenIsAuthorized/${id}/${token}`)
    .pipe(
      retry(2)
    )
  }

  postNewItinerary(itinerary: Itinerary) {
      return this.http.post<Itinerary>('/api/itineraries/newItinerary', {
        ...itinerary
      })
        .pipe(
          retry(2)
        )
    }
  
  updateItinerary(itinerary: Itinerary) {
    return this.http.put<Itinerary>(`/api/itineraries/${itinerary.Id}`, {
      ...itinerary
    }).pipe(
      retry(2)
    );
  }

  shareItinerary(ItineraryId: number, ReceiverName: string, ReceiverEmail: string) {
    return this.http.post<string>('/api/itineraries/share', {
      ItineraryId: ItineraryId,
      ReceiverName: ReceiverName,
      ReceiverEmail: ReceiverEmail
    })
      .pipe(
        retry(2)
      )
  }
}
