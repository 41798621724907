import { Place } from './Place';

interface Transit {
    Origin: Place,
    Destination: Place,
    Mode: TransitMode,
    Duration: string,
    Cost: number,

    // Backend Only
    Id?: number
}

enum TransitMode {
    Walk = 1,
    Bicycle = 2,
    Car = 3,
    Bus = 4,
    Train = 5,
    Plane = 6,
    Metro = 7,
    LightRail = 8,
    Boat = 9,
    Helicopter = 10,
    Rickshaw = 11,
    Tuktuk = 12,
    Motorcycle = 13,
    Moped = 14
}


export {
    Transit,
    TransitMode
}