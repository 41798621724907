import { Place } from './Place';
import { Transit } from './Transit';

interface Itinerary {
    Name: string,
    Destinations: Destination[],
    Days: number,
    Cost: number,
    Transits: Transit[],
    StartDate: Date,
    EndDate: Date,
    // How will we design the system to accommodate different transportation methods?

    // Implement the public private situation later, will want to check for people with link for example
    Public: boolean,
    Url: string,
    Notes: string,
    CreatedDate: Date,
    MapId: number,
    // Backend Only
    Id?: number,
    UserId?: number,
    SharedWithEmailsEdit: SharePerson[],
    SharedWithEmailsView: SharePerson[],
    ShareToken: string,
}

interface SharePerson {
    Name: string,
    Email: string,
}

enum Luxury {
    Budget,
    MidRange,
    Luxury,
}

interface Destination {
    Place: Place,
    Cost: number,
    Luxury: Luxury,
    NumberOfPeople: number,
    NumberOfDays: number,
    Notes: string
}

interface FullDay {
    Place: Place,
    Cost: number,
    Luxury: string,
    DayComponents: DayComponent[],
    Notes: string
}

interface DayComponent {
    Place: Place,
    RawText: string,
    Cost: number,
    Luxury: string,
    Category: Category
}

enum Category {
    Transportation = 1,
    Accommodation = 2,
    Breakfast = 3,
    Lunch = 4,
    Dinner = 5,
    Activity = 6,
    Tour = 7,
    Souvenir = 8
}

export {
    Itinerary,
    Destination,
    Luxury,
}


// Wellington = Itinerary Name
// Wellington = Place
// 4 days
// $6200
// No transit
// start/end n/a
// Days
    // Day 1
        // 1
            // Wellington
            // Private transfer to luxury hotel
            // $150
            // Transportation
        // 2
            // Wellington
            // Hotel
            // $1500
            // Accommodation
    // Day 2
    // Day 3
    // Day 4
