import { Component, OnInit } from '@angular/core';
import { Trip } from '../../models/Trip';
import { AuthService } from '../auth.service';
import { SearchService } from './search.service';
import { Place } from '../../models/Place';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { SharedApiService } from '../shared/shared-api.service';
import { Airport } from 'src/models/Airport';
import { CountryDetail, Passport } from 'src/models/Passport';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss', '../app.component.scss', '../card/card.component.scss']
})
export class SearchComponent implements OnInit {
  tripTrips: Trip[]
  trip: Trip
  tripAirport: Airport;
  place: Place
  places: Place[]
  budgetTrips: Trip[]
  mediumTrips: Trip[]
  luxuryTrips: Trip[]
  budgetFilter: boolean
  mediumFilter: boolean
  luxuryFilter: boolean
  searchExecuted: boolean
  averageCosts: String[]
  searchParam: string
  luxuryParam: string
  query: string
  aiQuery: string
  aiResponse: string
  typewriterDisplay: string = "";
  visa: CountryDetail;
  visaColor: string;
  showAiLoadingIcon: boolean;

  constructor(
    private searchService: SearchService, 
    private authService: AuthService, 
    private route: ActivatedRoute, 
    private sharedService: SharedService, 
    private sharedApiService: SharedApiService,
    private router: Router
    ) {}

  ngOnInit() {
    this.formInit();
    this.searchExecuted = false;
    this.route.queryParams.subscribe(params => {
        this.searchParam = params['q'];
        this.luxuryParam = params['luxury'];
        
        if (this.searchParam != undefined) {
          this.trip.Place = this.searchParam;
          this.search();
        }
        if (this.luxuryParam != undefined) {
          this.mediumFilter = false;
          this.luxuryFilter = false;
          this.budgetFilter = false;
          if (this.luxuryParam == "Luxury") {
            this.luxuryFilter = true;
          } else if (this.luxuryParam == "Mid-range") {
            this.mediumFilter = true;
          } else if (this.luxuryParam == "Budget") {
            this.budgetFilter = true;
          }
        } else {
          this.budgetFilter = true;
          this.mediumFilter = true;
          this.luxuryFilter = true;
        }
    });
    this.showAiLoadingIcon = false;
    this.preloadAskAI();
  }

  search() {
    this.budgetTrips = [];
    this.mediumTrips = [];
    this.luxuryTrips = [];
    this.tripTrips = [];

    this.searchService.executeSearch(this.trip.Place).subscribe(async (t: Trip[]) => {
      this.tripTrips = this.tripTrips.concat(t);
      this.finishSearch();
    })
   
  }

  navigate() {
    this.query = this.trip.Place;
    this.router.navigate(['/place'], { queryParams: {q: this.trip.Place}}).then(
      () => {
        window.location.reload();
      }
    );
  }

  finishSearch() {
    this.classifyTrips();
    this.averageCost();
    this.searchExecuted = true;
    this.query = this.trip.Place;
    if (this.tripTrips.length > 0) {
      if (this.tripTrips[0].Airports != null && this.tripTrips[0].Airports.length > 0) {
        this.tripAirport = this.tripTrips[0].Airports[0];
      }
    }
    this.preloadAskAI();
    this.setVisa();
  }

  getCountryWithPassport(passport: Passport, country: string) {
    this.visaColor = this.sharedService.processPassportColor(passport, country);
    this.visa = this.sharedService.returnVisaDetailsForPassportAndCountry(passport, country);
  } 

  setVisa() {
    if (this.authService.isLoggedIn()) {
      this.sharedApiService.getPassportCountry().subscribe((t: string) => {
        if (t == "") {
          this.sharedApiService.getPassport("USA").subscribe((u: Passport) => {
            if (this.tripTrips.length > 0) {
              this.getCountryWithPassport(u[0], this.tripTrips[0].Country);
            }
          });
        } else {
          this.sharedApiService.getPassport(t).subscribe((u: Passport) => {
            if (this.tripTrips.length > 0) {
              this.getCountryWithPassport(u[0], this.tripTrips[0].Country);
            }
          });
        }
      });
    } else {
      this.sharedApiService.getPassport("USA").subscribe((u: Passport) => {
        if (this.tripTrips.length > 0) {
          this.getCountryWithPassport(u[0], this.tripTrips[0].Country);
        }
      })
    };
  }

  parseCost(cost: string) {
    if (cost[0] == "$") {
      return cost.split('$')[1];
    } else {
      return cost;
    }
  }

  average(myArray: Trip[]): String {
    if (myArray.length == 0) {
      return '0';
    }
    var sum = 0;
    var eligibleLength = 0;
    
    myArray.forEach(element => {
      if (element.Cost && element.Duration && element.NumberOfPeople) {
        sum += parseFloat(this.parseCost(element.Cost)) / parseInt(this.sharedService.convertDurationToDays(element.Duration)) / parseInt(element.NumberOfPeople);
        eligibleLength += 1;
      }
    });
    return (sum / eligibleLength).toFixed(2);
  }

  averageCost(): void {
    this.averageCosts = [];
    this.averageCosts.push(this.average(this.budgetTrips));
    this.averageCosts.push(this.average(this.mediumTrips));
    this.averageCosts.push(this.average(this.luxuryTrips));
  }

  classifyTrips(): void {
    this.tripTrips.forEach(element => {
      if (!element.Component) {
        if (element.Luxury == "Medium" || element.Luxury == "Mid-range") {
          this.mediumTrips.push(element);
        }
        if (element.Luxury == "Budget") {
          this.budgetTrips.push(element);
        }
        if (element.Luxury == "Luxury") {
          this.luxuryTrips.push(element);
        }
      }
    });
  }

  firstTrip() {
    return this.tripTrips[0];
  }

  formInit(): void {
    this.trip = <Trip> {
      Place: ''
    };
    this.place = <Place> {
      Name: ''
    };
    this.query = "";
    this.aiQuery = "";
  }

  filterButton(option): void {
    // this.preload();
    if (option == "budget" || option == 0) {
      if (this.budgetFilter == false) {
        this.budgetFilter = true;
      } else {
        this.budgetFilter = false;
      }
    } else if (option == "medium" || option == 1) {
      if (this.mediumFilter == false) {
        this.mediumFilter = true;
      } else {
        this.mediumFilter = false;
      }
    } else if (option == "luxury" || option == 2) {
      if (this.luxuryFilter == false) {
        this.luxuryFilter = true;
      } else {
        this.luxuryFilter = false;
      }
    }
    // this.reload();
  }

  preload(): void {
    if (this.budgetFilter == true && this.mediumFilter == true && this.luxuryFilter == true) {
      this.budgetFilter = false;
      this.mediumFilter = false;
      this.luxuryFilter = false;
    }
  }

  reload(): void {
    if (this.budgetFilter == false && this.mediumFilter == false && this.luxuryFilter == false) {
      this.budgetFilter = true;
      this.mediumFilter = true;
      this.luxuryFilter = true;
    }
  }

  preloadAskAI() {
    this.aiQuery = "Tell me something random about " + this.trip.Place;
    // this.askAI();
  }

  typingCallback(that) {
    let total_length = that.aiResponse.length;
    let current_length = that.typewriterDisplay.length;
    if (current_length < total_length) {
      that.typewriterDisplay += that.aiResponse[current_length];
      setTimeout(that.typingCallback, 20, that);
    } else {
      if (current_length != total_length) {
        that.typewriterDisplay = "";
      }      
    }
  }

  askAI() {
    this.showAiLoadingIcon = true;
    this.searchService.askAi(this.aiQuery).subscribe((t => {
      this.aiResponse = t;
      this.typewriterDisplay = "";
      this.showAiLoadingIcon = false;
      this.typingCallback(this);
    }));
  }
}
