import { Injectable } from '@angular/core';
import { Trip } from 'src/models/Trip';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Maps } from 'src/models/Maps';

@Injectable({
  providedIn: 'root'
})
export class TripdetailService {

  constructor(private http: HttpClient) { }

  getTrip(Id: string): Observable<Trip> {
    return this.http.get<Trip>('/api/internalTrips/getById/' + Id)
      .pipe(
        retry(2)
      )
  }

  getTripAi(Id: string): Observable<Trip> {
    return this.http.get<Trip>('/api/aiTrips/getById/' + Id)
      .pipe(
        retry(2)
      )
  }

  getMaps(Place: string): Observable<Maps[]> {
    return this.http.get<Maps[]>('/api/maps/place/' + Place)
      .pipe(
        retry(2)
      )
  }

  assignFlag(Id: number, value: boolean): Observable<Maps> {
    return this.http.put<Maps>('/api/maps/assignFlag', {Id: Id, Value: value})
      .pipe(
        retry(2)
      )
  }
}
