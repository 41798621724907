import { Component } from '@angular/core';
import { User } from '../../models/User';
import { AuthService } from '../auth.service';
import { OverlayService } from '../overlay/overlay.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  password: string;
  username: string;
  user: User;
  rUsername: string;
  rPassword: string;
  rFirstName: string;
  rLastName: string;
  showLogin: boolean;

  constructor(private authService: AuthService, private overlayService: OverlayService) {}

  ngOnInit() {
    this.showLogin = true;
  }

  submit() {
    return this.authService.login(this.username, this.password)
      .subscribe(
        () => {
          if (this.authService.isLoggedIn()) {
            this.close();
            window.location.reload();
          }
        }
      );
  }

  register() {
    this.authService.register(this.rFirstName, this.rLastName, this.rUsername, this.rPassword)
      .subscribe(() => {
          this.username = this.rUsername;
          this.password = this.rPassword;
          this.submit();
        } 
      );
    this.showLogin = false;
  }

  back(e) {
    e.stopPropagation();
    this.showLogin = true;
  }

  close() {
    this.overlayService.close();
    this.showLogin = true;
  }

  switch(e) {
    e.stopPropagation()
    this.showLogin = !this.showLogin;
  }

  logout() {
    this.authService.logout();
  }
}
