<div class="Transit">
    <div *ngIf="this.showAddTransit" [@fadeIn]>
        <button class="Transit-button" (click)="this.showOptions=!this.showOptions" [ngClass]="this.showOptions ? 'Transit-button--borderBottomOff' : ''">
            Add Transit  <img class="Transit-downArrow" src="../../assets/orangedownarrow.svg">
        </button>
        <div *ngIf="this.showOptions" [@slideInOut]>
            <div class="Transit-option" (click)="this.selectTransit('Plane')">
                <div>
                    Plane
                </div>
                <div>
                    <img src="../../assets/orangeplane.svg">
                </div>
            </div>
            <div class="Transit-option" (click)="this.selectTransit('Train')">
                <div>
                    Train
                </div>
                <div>
                    <img src="../../assets/orangetrain.svg">
                </div>
            </div>
            <div class="Transit-option" (click)="this.selectTransit('Bus')">
                <div>
                    Bus 
                </div>
                <div>
                    <img src="../../assets/orangebus.svg">
                </div>
            </div>
            <div class="Transit-option" (click)="this.selectTransit('Car')">
                <div>
                    Car 
                </div>
                <div>
                    <img src="../../assets/orangecar.svg">
                </div>
            </div>
            <div class="Transit-option" (click)="this.selectTransit('Boat')">
                <div>
                    Boat    
                </div>
                <div>
                    <img src="../../assets/orangeboat.svg">
                </div>
            </div>
        </div>
    </div>
    <div class="Transit-selectedWrapper" *ngIf="this.transitSelected" [@fadeIn]>
        <div class="Transit-gridItem Transit-gridItem--left">
            <img class="Transit-image" [src]="this.selectedImage">
        </div>
        <div class="Transit-gridItem" (click)="this.deselectTransit()">
            <img class="Transit-image" src="../../assets/orangeclose.svg">
        </div>
    </div>

</div>