<section class="Daycard" [ngStyle]="{'background-image': place.Photo.small && this.showPhoto ? 'url(' + place.Photo.small + ')' : '', 'background-size': 'cover'}"
    *ngIf="place.Photo" (click)="this.navigate($event.target)">
    <div class="Daycard-content">
        <div>
            <div class="Daycard-middle">
                <div class="Daycard-name">
                    <a class="Daycard-nameLink" [routerLink]="['/place']"
                        [queryParams]="{q: place.Name, luxury: this.selectedLuxury}">{{ place.Name | titlecase }}
                    </a>
                </div>
            </div>
            <div class="Daycard-pill">
                <div class="Daycard-pillButton Daycard-pillButton--budget" [ngClass]="this.selectedLuxury == 'Budget' ? 'Daycard-pill--budget' : ''" (click)="this.changeSelected('budget')" *ngIf="this.budgetPassing">
                    budget
                </div>
                <div class="Daycard-pillButton Daycard-pillButton--midrange" [ngClass]="this.selectedLuxury == 'Mid-range' ? 'Daycard-pill--midrange' : ''" (click)="this.changeSelected('midrange')" *ngIf="this.midrangePassing">
                    mid-range
                </div>
                <div class="Daycard-pillButton Daycard-pillButton--luxury" [ngClass]="this.selectedLuxury == 'Luxury' ? 'Daycard-pill--luxury' : ''" (click)="this.changeSelected('luxury')" *ngIf="this.luxuryPassing">
                    luxury
                </div>
            </div>
            <div class="Daycard-bottom" [ngClass]="this.showCredit ? 'Daycard-bottomLess' : ''">
                <div class="Daycard-cost--perDay">
                    <span class="Daycard-cost--days" *ngIf="search != 0">{{ this.selectedDays | number: '1.0-0' }} day{{ (this.selectedDays | number: '1.0-0') == "1" ? '' : 's' }}</span>
                    <span class="Daycard-cost--at" *ngIf="search != 0"> &#64;</span>
                    <span class="Daycost-cost--dollars"> ${{ this.pppd | number: '1.0-0' }}</span>
                    <span class="Daycard-cost--pppd"> / pppd</span> 
                </div>
                <div class="Daycard-bottomRight">
                    <div class="Daycard-heartWrapper" (click)="heart()">
                        <img class="Daycard-heart" [src]="isFilled ? '../../assets/orangeheartchecked.svg' : '../../assets/whiteheart.svg'">
                    </div>
                </div>
            </div>
            <div *ngIf="place.Photo.small && this.showCredit" class="Daycard-photo">
                Photo by <a class="Daycard-photoLink" href="{{place.Photo.links}}?utm_source=Ballpark&utm_medium=referral">{{place.Photo.photographer}}</a> on <a class="Daycard-photoLink" href="{{place.Photo.htmlPhoto}}?utm_source=Ballpark&utm_medium=referral">Unsplash</a>
            </div>
        </div>
    </div>
</section>

<div class="Daycard-flagWrapper" *ngIf="this.userIsAdmin && place.Photo">
    {{ this.flagIsFilled ? 'Unf' : 'F'}}lag the above photo
    <div (click)="toggleFlag()" *ngIf="!this.flagIsFilled">
            <img class="Daycard-flag" src="../../assets/flag-empty.svg" >
    </div>
    
    <div (click)="toggleFlag()" *ngIf="this.flagIsFilled">
        <img class="Daycard-flag" src="../../assets/flag-filled.svg" >
    </div>        
</div>