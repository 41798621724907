<div class="ItineraryCard-place ItineraryCard-fullRow" *ngIf="this.destination">
    <div (click)="remove()" class="ItineraryCard-close"> <img src="../../assets/orangeclose.svg"> </div>
    <div class="ItineraryCard-place--title">
        <span class="ItineraryCard-place--name">{{ destination.Place.Name }}</span>
        <img *ngIf="this.mapCode"
        src="https://flagcdn.com/40x30/{{this.mapCode}}.png"
        srcset="https://flagcdn.com/80x60/{{this.mapCode}}.png 2x,
          https://flagcdn.com/120x90/{{this.mapCode}}.png 3x"
        width="40"
        height="30"
        alt="{{ destination.Place.Country }}">
    </div>
    <div class="ItineraryCard-title--secondary">
        <div class="ItineraryCard-place--days">
            {{ this.destination.NumberOfDays }} Day{{ this.destination.NumberOfDays == 1 ? '' : 's' }}
        </div>
        <div class="ItineraryCard-place--cost" (click)="this.showTotal=!this.showTotal">
            <span *ngIf="this.showTotal">${{ this.getTotalCost() | number: '1.2-2'}} total</span>
            <span *ngIf="!this.showTotal">${{ this.getCurrentLuxuryAverageCost() * this.destination.NumberOfDays | number: '1.2-2' }} per person</span>
        </div>
    </div>

    


    <div class="ItineraryCard-belowFold" *ngIf="this.rotateChevron" [@slideInOut]>
        <div>
            <div class="ItineraryCard-flexRow">
                <div class="ItineraryCard-icon">
                    <img class="ItineraryCard-icon--person" src="../../assets/orangeperson.svg">
                </div>
                <div class="ItineraryCard-numPeople">
                    <button class="ItineraryCard-plusminus ItineraryCard-minus" (click)="handleMinus('people')">-</button>
                    <div class="ItineraryCard-inputCenter">
                        <input type="number" class="ItineraryCard-number"  [ngModel]="this.destination.NumberOfPeople" (ngModelChange)="updateCost()" />
                        <span class="ItineraryCard-daysText" >{{ this.destination.NumberOfPeople == 1 ? 'Person' : 'People' }}</span>
                    </div>
                    <button class="ItineraryCard-plusminus ItineraryCard-plus" (click)="handlePlus('people')">+</button>
                </div>
            </div>
            <div class="ItineraryCard-flexRow">
                <div class="ItineraryCard-icon">
                    <img src="../../assets/orangecalendar.svg">
                </div>
                <div class="ItineraryCard-numPeople">
                    <button class="ItineraryCard-plusminus ItineraryCard-minus" (click)="handleMinus('days')">-</button>
                    <div class="ItineraryCard-inputCenter">
                        <input type="number" class="ItineraryCard-number"  [(ngModel)]="this.destination.NumberOfDays" (ngModelChange)="updateCost()" />
                        <span class="ItineraryCard-daysText" >Day{{ this.destination.NumberOfDays == 1 ? '' : 's' }}</span>
                    </div>
                    <button class="ItineraryCard-plusminus ItineraryCard-plus" (click)="handlePlus('days')">+</button>
                </div>
            </div>

            <div class="ItineraryCard-flexRow">
                <div class="ItineraryCard-icon">
                    <img src="../../assets/orangemoney.svg">
                </div>
                <span class="ItineraryCard-boldText">${{ this.getCurrentLuxuryAverageCost() * this.destination.NumberOfDays | number: '1.2-2' }} per person</span>
            </div>
        </div>
        <div>
            <div class="ItineraryCard-luxuryGroup" (click)="setLuxury(this.getLuxuryTypes().Luxury)">
                <div class="ItineraryCard-heading--right ItineraryCard-heading--Luxury" [ngClass]="this.destination.Luxury === this.getLuxuryTypes().Luxury ? '' : 'ItineraryCard-heading--disabled'">
                    Luxury
                </div>
                <div [ngClass]="this.destination.Luxury === this.getLuxuryTypes().Luxury ? '' : 
                this.calculateAveragePriceDifference(destination.Place.LuxuryAverageCost)[0] == '+' ? 'ItineraryCard-heading--red ItineraryCard-heading--disabled' :
                'ItineraryCard-heading--disabled'">
                    {{ this.destination.Luxury === this.getLuxuryTypes().Luxury ? '' : this.calculateAveragePriceDifference(destination.Place.WeightedLuxuryCost) + ' / pppd'}}
                </div>
                
            </div>
            
            <div class="ItineraryCard-luxuryGroup" (click)="setLuxury(this.getLuxuryTypes().MidRange)">
                <div class="ItineraryCard-heading--right ItineraryCard-heading--Medium" [ngClass]="this.destination.Luxury === this.getLuxuryTypes().MidRange ? '' : 'ItineraryCard-heading--disabled'">
                    Mid-range
                </div>
                <div [ngClass]="this.destination.Luxury === this.getLuxuryTypes().MidRange ? '' : 
                this.calculateAveragePriceDifference(destination.Place.MidRangeAverageCost)[0] == '+' ? 'ItineraryCard-heading--red ItineraryCard-heading--disabled' :
                'ItineraryCard-heading--disabled'">
                    {{ this.destination.Luxury === this.getLuxuryTypes().MidRange ? '' : this.calculateAveragePriceDifference(destination.Place.WeightedMidRangeCost) + ' / pppd'}} 
                </div>
            </div>
            <div class="ItineraryCard-luxuryGroup" (click)="setLuxury(this.getLuxuryTypes().Budget)">
                <div class="ItineraryCard-heading--right ItineraryCard-heading--Budget" [ngClass]="this.destination.Luxury === this.getLuxuryTypes().Budget ? '' : 'ItineraryCard-heading--disabled'">
                    Budget
                </div>
                <div [ngClass]="this.destination.Luxury === this.getLuxuryTypes().Budget ? '' : 
                this.calculateAveragePriceDifference(destination.Place.BudgetAverageCost)[0] == '+' ? 'ItineraryCard-heading--red ItineraryCard-heading--disabled' :
                'ItineraryCard-heading--disabled'">
                    {{ this.destination.Luxury === this.getLuxuryTypes().Budget ? '' : this.calculateAveragePriceDifference(destination.Place.WeightedBudgetCost) + ' / pppd' }} 
                </div>
            </div>
        </div>
        <div>
            <div *ngIf="this.visaStatus && this.visaMessage">
                {{ destination.Place.Name }} {{ this.visaMessage }}
            </div>
            <div>
                <div class="ItineraryCard-notesHeader">
                    Notes
                </div>
                <textarea class="ItineraryCard-notes" [(ngModel)]="this.destination.Notes"></textarea>
            </div>
        </div>
    </div>


    <div class="ItineraryCard-place--details" (click)="this.rotate()">
        {{ this.rotateChevron ? 'Collapse details' : 'Show details' }} <img class="ItineraryCard-place--chevron" [ngClass]="this.rotateChevron ? 'rotate' : ''" src="../../assets/chevron.svg">
    </div>

</div>