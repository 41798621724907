import { Component, OnInit } from '@angular/core';
import { User } from '../../models/User';
import { AuthService } from '../auth.service';
import { ProfileService } from './profile.service';
import { Trip, ApprovalStatus } from '../../models/Trip';
import { Place } from '../../models/Place';
import { CostedTrip } from 'src/models/CostedTrip';
import { SharedApiService } from '../shared/shared-api.service';
import { Passport } from 'src/models/Passport';
import { SharedService } from '../shared/shared.service';
import { Typeahead } from 'src/models/Typeahead';
import { Airport } from 'src/models/Airport';
import { ActivatedRoute } from '@angular/router';
import { Itinerary } from 'src/models/Itinerary';
import { Maps } from 'src/models/Maps';
import { Personality } from 'src/models/Personality';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  email: string;
  userPassport: string;
  id: string;
  passportCountry: string;
  recentTrips: Trip[];
  admin: boolean;
  userCreatedTrips: Trip[];
  userLikedPlaces: Place[];
  costedTrips: CostedTrip[];
  userLikedTrips: Trip[];
  userItineraries: Itinerary[];
  itinerariesVisible: boolean;
  createdVisible: boolean;
  likedVisible: boolean;
  settingsVisible: boolean;
  passportObject: Passport;
  userPassportUnspecified: boolean;
  showInput: boolean;
  selectedPassport: string;
  iataports: Typeahead[];
  userEnteredAirport: string;
  userAirport: Airport;
  flaggedMapTrips: Trip[];
  pageParam: string;
  newMapQuery: string;
  newLocalMapURL: string;
  newMapLat: string;
  newMapLng: string;
  flaggedMaps: Maps[];
  newMapImage;
  newMapJSON: JSON;
  personality: Personality;


  constructor(private authService: AuthService, private route: ActivatedRoute, private profileService: ProfileService, private sharedApiService: SharedApiService, private sharedService: SharedService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.pageParam = params['page'];
      if (this.pageParam == 'created') {
        this.createdVisible = true;
        this.itinerariesVisible = false;
        this.likedVisible = false;
        this.settingsVisible = false;
      } else if (this.pageParam == 'liked') {
        this.createdVisible = false;
        this.itinerariesVisible = false;
        this.likedVisible = true;
        this.settingsVisible = false;
      } else if (this.pageParam == 'settings') {
        this.createdVisible = false;
        this.itinerariesVisible = false;
        this.likedVisible = false;
        this.settingsVisible = true;
      } else if (this.pageParam == 'itineraries') {
        this.createdVisible = false;
        this.itinerariesVisible = true;
        this.likedVisible = false;
        this.settingsVisible = false;
      } else {
        this.createdVisible = false;
        this.itinerariesVisible = false;
        this.likedVisible = true;
        this.settingsVisible = false;
      }
    });

    this.admin = false;
    this.costedTrips = [];
    this.profileService.getUserIsAdmin()
      .subscribe((isAdmin: boolean) => {
        this.admin = isAdmin;
    });
    this.getUserEmail();
    this.getUserCreatedTrips();
    this.getUserLikedPlaces();
    this.getItineraries();
    this.getUserLikedTrips();
    this.getPassportCountry();
    this.getPersonality();
    this.getUserAiport();
    this.showInput = false;
    this.selectedPassport = "";
  };

  logout() {
    this.authService.logout();
    location.assign("/");
  }

  getUserEmail(): void {
    this.profileService.getUserEmail().subscribe((t: string) => {
      this.email = t['Email'];
    });
  }

  getUserCreatedTrips(): void {
    this.profileService.getUserCreatedTrips().subscribe((t: Trip[]) => {
      this.userCreatedTrips = t;
    });
  }

  getPassportCountry(): void {
    this.sharedApiService.getPassportCountry().subscribe((t: string) => {
      this.userPassport = t;
      if (this.userPassport == "") {
        this.userPassportUnspecified = true;
      } else {
        this.sharedApiService.getPassport(this.userPassport).subscribe((u: Passport) => {
          this.passportObject = u[0];
        });
        this.userPassportUnspecified = false;
      }
    });
  }

  getPersonality(): void {
    this.sharedService.getPersonality().subscribe(personality => {
      if (personality != null) {
        this.personality = personality;
      } else {
        this.personality = Personality.Professional;
      }
    });
  }

  getUserAiport(): void {
    this.sharedApiService.getUserAirport().subscribe((t: Airport) => {
      this.userAirport = t;
    });
  }

  // This bug means that we need to restructure the DB, because we can't identify which level of luxury a user liked (or the number of days for that matter)
  getUserLikedPlaces(): void {
    this.profileService.getLikedPlaces()
    .subscribe((t: Place[]) => {
      this.userLikedPlaces = t;
    });
  }

  getUserLikedTrips(): void {
    this.profileService.getLikedTrips()
    .subscribe((t: Trip[]) => {
      this.userLikedTrips = t;
    });
  }

  // Will need to handle permissioning
  getItineraries(): void {
    this.profileService.getItineraries()
    .subscribe((t: Itinerary[]) => {
      this.userItineraries = t;
    });
  }

  showCreated() {
    this.createdVisible = true;
    this.settingsVisible = false;
    this.likedVisible = false;
    this.itinerariesVisible = false;
  }
  
  showLiked() {
    this.likedVisible = true;
    this.createdVisible = false;
    this.settingsVisible = false;
    this.itinerariesVisible = false;
  }

  showSettings() {
    this.settingsVisible = true;
    this.createdVisible = false;
    this.likedVisible = false;
    this.itinerariesVisible = false;
  }

  showItineraries() {
    this.itinerariesVisible = true;
    this.settingsVisible = false;
    this.createdVisible = false;
    this.likedVisible = false;
  }

  updatePassport(): void {
    if (this.selectedPassport != "Country" && this.selectedPassport != "Other" && this.selectedPassport.trim() != "") {
      this.profileService.updatePassportCountry(this.selectedPassport).subscribe(() => {
        location.assign('/');
      });
    } else {
      if (this.passportCountry.trim() != "" && this.passportCountry != null) {
        this.profileService.updatePassportCountry(this.passportCountry).subscribe(() => {
          location.assign('/');
        });
      }
    }
  }

  watchForOtherSelect(value: string) {
    this.selectedPassport = value;
    if (value == "Other") {
      this.showInput = true;
    } else {
      this.showInput = false;
    }
  }

  watchAirportInput(value: string) {
    if (value.length > 2) {
      this.sharedApiService.getAirportTypeAhead(value).subscribe(data => {
        this.iataports = data;
      });
    }
  }

  updateAirports() {
    if (this.userEnteredAirport != "" && this.userEnteredAirport != null) {
      this.iataports.forEach(element => {
        if (element.iataCode == this.userEnteredAirport) {
          let userAirport: Airport = {
            City: element.address.cityName,
            IATACode: element.iataCode,
            Country: element.address.countryName,
            Continent: "",
          };
          this.sharedApiService.updateAirport(userAirport).subscribe();
        }
      });
    }
  }

  public get personalityType(): typeof Personality {
    return Personality; 
  }

  updatePersonality() {
    if (this.personality != null) {
      this.profileService.updatePersonality(this.personality).subscribe();
    }
  }
}
