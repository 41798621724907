import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  isOpen: boolean;
  type: string;

  constructor() {
    this.isOpen = false;
  }

  open(type:string) {
    this.type = type;
    this.isOpen = true;
  }

  close() {
    this.type = undefined;
    this.isOpen = false;
  }
}
