<section class="Card" [ngStyle]="{'background-image': trip.Photo ? 'url(' + trip.Photo.small + ')' : 'url( ' + trip.ImageBlob + ')', 'background-size': 'cover'}"    
    *ngIf="this.photoLoaded"
    (click)="this.navigate($event.target)"
>
    <div class="Card-heading--right Card-heading--{{trip.Luxury}}">
        <h2>
            {{ trip.Luxury == 'Medium' ? 'Mid-range' : trip.Luxury }}
        </h2>
        <div>
            {{ trip.Tags | titlecase }}
        </div>
    </div>
    <div class="Card-content">
        <div>
            <div class="Card-middle">
                <div class="Card-name">
                    <a class="Card-nameLink" [href]="trip.AiGenerated ? '/aiTrip/' + trip.Id : '/trip/' + trip.Id"> {{ trip.Place | titlecase }} </a>
                </div>
            </div>
            <div class="Card-bottom" [ngClass]="this.showCredit ? 'Card-bottomLess' : ''">
                <div *ngIf="trip.NumberOfPeople && trip.Duration && trip.Cost" class="Card-cost--perDay">
                    <div>
                        ${{this.perPersonPerDay | number: '1.0-0' }}
                        <span class="Card-cost--perDaySpan" (mouseover)="hovered = true" (mouseout)="hovered = false">/ pppd </span> 
                        <div class="Card-tooltip" [ngClass]="hovered == true ? 
                        'Card-tooltip--displayed' : ''">
                            <span class="Card-cost--hover">per person per day</span>
                        </div>
                    </div>
                </div>
                <div class="Card-heartWrapper" (click)="heart()">
                    <img class="Card-heart" [src]="isFilled ? '../../assets/orangeheartchecked.svg' : '../../assets/whiteheart.svg'">
                </div>
            </div>
            <div *ngIf="trip.Photo && this.showCredit" class="Card-photo">
                Photo by <a class="Card-photoLink" href="{{trip.Photo.links}}?utm_source=Ballpark&utm_medium=referral">{{trip.Photo.photographer}}</a> on <a class="Card-photoLink" href="{{trip.Photo.htmlPhoto}}?utm_source=Ballpark&utm_medium=referral">Unsplash</a>
            </div>
        </div>
    </div>
</section>