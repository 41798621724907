
<div class="Flight-flightWrapper" *ngIf="this.tripAirport">
    <div class="Flight-flight">
        <img class="Flight-plane" src="../../assets/Plane.svg">
        <div class="Flight-plane--text Flight-cardRow--text">
            <!-- Wanna fly? -->
            View flights
            <!-- 
                Pompous: Inform me of aerial options
                Professional: View flights
                Salty: Wanna fly
            -->
        </div>
    </div>
    <!-- TODO: whenever cheapest flights are allowed again by API <div class="Flight-cheapFlight">
        Show me the cheapest dates!

        <button (click)="this.getCheapDates()" class="Flight-button">
            Cheaper the better
        </button>
            Available query params:
            departure date
            one way?
            duration
            nonstop?
    </div> -->

    <div class="Flight-flightComponent">
        <div class="Flight-flightRow--wrapper">
            <div class="Flight-flightRow">
                Depart: 
                <input class="Flight-input" type="date" [(ngModel)]="this.departureDate">
            </div>
            <div class="Flight-flightRow">
                Return (optional): 
                <input class="Flight-input" type="date" [(ngModel)]="this.returnDate">
            </div>
            <div class="Flight-flightRow">
                <!-- Number of peeps:  -->
                Number of People
                <input class="Flight-input" type="number" [(ngModel)]="this.numberOfPeeps">
            </div>
            <div class="Flight-flightRow" *ngIf="this.showAirport">
                Home Airport

                <input class="Flight-input" list="airportsData" [(ngModel)]="this.airport" placeholder="Washington" (input)="watchAirportInput($event.target.value)">
                <datalist id="airportsData" *ngIf="this.iataports">
                    <option *ngFor="let port of this.iataports" [value]="port.iataCode">
                        {{ port.name }}
                    </option>
                </datalist>
            </div>
            
            <button (click)="this.getFlight()" class="Flight-button">
                <!-- Get flight -->
                Estimate Flight Cost
                <!-- 
                    Pompous: Deliver an estimate of the charge to enter the cloud space
                    Professional: Estimate Flight Cost
                    Salty: tell me the cost yo
                -->
            </button>
        </div>



        <div *ngIf="this.showLoadingIcon" class="Flight-loading">
            Loading....
        </div>
        <div *ngIf="this.showError">
            Oh deary me, we seem to have encountered an error. Please try a different date!
        </div>
        <div *ngIf="this.flightCost">
            Flights from {{ this.airportCity | titlecase }} to {{ this.tripAirport.City | titlecase }} start at <span *ngIf="this.flightCost">${{ this.flightCost }}</span> for {{ this.numberOfPeeps }} {{ this.numberOfPeeps == 1 ? 'person' : 'people' }} {{ this.returnDate == '' || this.returnDate == 'none' ? 'one way' : 'round trip'}}
        </div>
    </div>
</div>