import { Component, OnInit } from '@angular/core';
import { CostSearchService } from './cost-search.service';
import { Place } from '../../models/Place';
import { SharedApiService } from '../shared/shared-api.service';
import { SharedService } from '../shared/shared.service';
import { Passport } from 'src/models/Passport';
import { AuthService } from '../auth.service';
import { OverlayService } from '../overlay/overlay.service';
import { Personality } from 'src/models/Personality';
import { CopyService } from '../shared/copy.service';

@Component({
  selector: 'app-cost-search',
  templateUrl: './cost-search.component.html',
  styleUrls: ['./cost-search.component.scss']
})
export class CostSearchComponent implements OnInit {
  showFilter: boolean;
  showAddTrip: boolean;
  searchExecuted: boolean;
  cost: string;
  expense: number;
  places: Place[];
  allPassingPlaces: Place[];
  randomCost: number;
  userPassport: string;
  passportObject: Passport;
  priceSortedDescending: boolean;
  aiAllowed: boolean;
  scrollIndex: number;
  numberOfPhotosGotten: number;
  // Should implement a hash map that allows us to set the name of the place equal to a Photo object
  photoHashMap: {};
  rotateChevron: boolean;
  personality: Personality;
  costSearchHeader: string;
  costSearchBudgetText: string;
  costSearchCTA: string;
  costSearchFilterCTA: string;
  costSearchSortPrice: string;
  costSearchShuffle: string;

  constructor(
    private costSearchService: CostSearchService, 
    private sharedApiService: SharedApiService, 
    private sharedService: SharedService, 
    private authService: AuthService,
    private overlayService: OverlayService,
    private copyService: CopyService
  ) {}

  ngOnInit() {
    this.formInit();
    this.showFilter = false;
    this.showAddTrip = false;
    this.searchExecuted = false;
    this.priceSortedDescending = false;
    this.aiAllowed = true;
    this.scrollIndex = 16;
    this.numberOfPhotosGotten = 0;
    this.setAiAllowed();
    this.subscribePersonality();

    if (localStorage.getItem("randomCost") != null) {
      this.randomCost = parseFloat(localStorage.getItem("randomCost"));
    }

    if (this.authService.isLoggedIn()) {
      this.sharedApiService.getPassportCountry().subscribe((t: string) => {
        this.userPassport = t;
        if (this.userPassport == "") {
          this.userPassport = "USA"
          this.sharedApiService.getPassport("USA").subscribe((u: Passport) => {
            this.passportObject = u[0];
            this.getPlaces();
          });
        } else {
          this.sharedApiService.getPassport(this.userPassport).subscribe((u: Passport) => {
            this.passportObject = u[0];
            this.getPlaces();
          });
        }
      });
    } else {
      this.userPassport = "USA";
      this.sharedApiService.getPassport("USA").subscribe((u: Passport) => {
        this.passportObject = u[0];
        this.getPlaces();
      });
    }

    this.subscribePlaces();
  }


  subscribePlaces() {
    this.sharedService.passingPlaces$.subscribe(places => {
      this.allPassingPlaces = places;
      this.shuffleAllPlaces();
    })
  }

  openFilters() {
    this.overlayService.open('app-filters');
  }

  setAiAllowed() {
    this.sharedService.aiAllowed$.subscribe(bool => {
      this.aiAllowed = bool;
    })
  }

  getPlaces(): void {
    // we want this to hit shared service now
    this.costSearchService.getPlaces()
      .subscribe((t: Place[]) => {
        this.places = t;
        this.shufflePlaces();
        this.searchRandomCost();
      });
  }

  getPhotosForSubset(startIndex, endIndex) {
    for (let index = startIndex; index < endIndex; index++) {
      const element = this.places[index];
      if (element.Name != "" && element.Name != null) {
        this.sharedApiService.getPhotosForPlaceName(element.Name)
        .subscribe(data => {
          if (data.length != 0) {
            element.Photo = this.sharedService.randomPhoto(data);

          }
        });
      }
    }
  }

  onScrollDown() {
    this.scrollIndex += 16;
    this.numberOfPhotosGotten += 16;
  }

  onScrollUp() {
    if (this.scrollIndex > 16) {
      this.scrollIndex -= 16;
    }
  }

  search(): void {
    this.searchExecuted = true;
    if (!this.cost) {
      this.cost = '$';
    }
    let expense = this.sharedService.convertCostToNumber(this.cost);
    this.expense = expense;
    this.allPassingPlaces = this.sharedService.getPassingPlaces(this.places, expense, this.passportObject, this.userPassport);
    this.shuffleAllPlaces();
    this.costSearchService.sendSearch(this.cost).subscribe();
  }

  scroll() {
    document.getElementById("scrollToSection").scrollIntoView({behavior: 'smooth'});
  }

  searchRandomCost(): void {
    this.searchExecuted = true;
    if (!this.randomCost) {
      this.cost = '$'
    } else {
      this.cost = "$" + this.randomCost.toString();
    }
    this.expense = this.sharedService.convertCostToNumber(this.cost);
    this.allPassingPlaces = this.sharedService.getPassingPlaces(this.places, this.expense, this.passportObject, this.userPassport);
    this.shuffleAllPlaces();
    this.randomCost = Math.max(Math.floor(Math.random()*5000), 500);
  }

  sortByPrice() {
    if (this.priceSortedDescending) {
      this.allPassingPlaces = this.sharedService.sortByDaysPlaceAscending(this.allPassingPlaces);
    } else {
      this.allPassingPlaces = this.sharedService.sortByDaysPlaceDescending(this.allPassingPlaces, this.expense, this.sharedService.passesDaysPure);
    }
    this.priceSortedDescending = !this.priceSortedDescending;
    this.rotateChevron = !this.rotateChevron;
  }

  shuffleAllPlaces() {
    return this.sharedService.shuffle(this.allPassingPlaces);
  }

  shufflePlaces() {
    return this.sharedService.shuffle(this.places);
  }

  formInit(): void {
    this.cost = "";
  }

  filter(): void {
    if (this.showFilter == false) {
      this.showFilter = true;
    } else {
      this.showFilter = false;
    }
  }

  randomFromArray(array) {
    return array[Math.round(Math.random() * 100) % array.length];
  }

  randomCostSearchHeader() {
    this.costSearchHeader = this.copyService.randomCostSearchHeader(this.personality);
  }

  randomCostSearchBudgetText() {
    this.costSearchBudgetText = this.copyService.randomCostSearchBudgetText(this.personality);
  }

  randomCostSearchCTA() {
    this.costSearchCTA = this.copyService.randomCostSearchCTA(this.personality);
  }

  randomCostSearchFilterCTA() {
    this.costSearchFilterCTA = this.copyService.randomCostSearchFilterCTA(this.personality);
  }

  randomCostSearchSortPrice() {
    this.costSearchSortPrice = this.copyService.randomCostSearchSortPrice(this.personality);
  }

  randomCostSearchShuffle() {
    this.costSearchShuffle = this.copyService.randomCostSearchShuffle(this.personality);
  }

  subscribePersonality() {
    this.sharedService.personality$.subscribe(p => {
      this.personality = p;
      this.randomCostSearchHeader();
      this.randomCostSearchBudgetText();
      this.randomCostSearchCTA();
      this.randomCostSearchFilterCTA();
      this.randomCostSearchShuffle();
      this.randomCostSearchSortPrice();
    })
  }
}