import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { User } from '../../models/User';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { Trip } from '../../models/Trip';
import { Place } from '../../models/Place';
import { Itinerary } from 'src/models/Itinerary';
import { Maps } from 'src/models/Maps';
import { Personality } from 'src/models/Personality';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  getUserIsAdmin(): Observable<boolean> {
    return this.http.get<boolean>('/api/users/userIsAdmin')
      .pipe(
        retry(2)
      );
  }

  getUserEmail(): Observable<string> {
    return this.http.get<string>('/api/users/userEmail')
      .pipe(
        retry(2)
      );
  }

  getUserCreatedTrips(): Observable<Trip[]> {
    return this.http.get<Trip[]>('/api/recentlyAdded/userAdded')
      .pipe(
        retry(2)
      );
  }

  getTripsForApproval(): Observable<Trip[]> {
    return this.http.get<Trip[]>('/api/recentlyAdded')
      .pipe(
        retry(2)
      );
  }

  getLikedPlaces(): Observable<Place[]> {
    return this.http.get<Place[]>('/api/places/userLiked')
      .pipe(
        retry(2)
      );
  }

  getLikedTrips(): Observable<Trip[]> {
    return this.http.get<Trip[]>('/api/internalTrips/userLiked')
      .pipe(
        retry(2)
      );
  }

  getItineraries(): Observable<Itinerary[]> {
    return this.http.get<Itinerary[]>('/api/itineraries/userProfile')
      .pipe(
        retry(2)
      )
  }

  updateTripStatus(id, body): Observable<Trip> {
    return this.http.put<Trip>('/api/recentlyAdded/' + id, body)
      .pipe(
        retry(2)
      );
  }

  updatePassportCountry(passport): Observable<User> {
    return this.http.put<User>('/api/users/updatePassport', {PassportCountry: passport})
      .pipe(
        retry(2)
      );
  }

  updatePersonality(personality: Personality): Observable<User> {
    return this.http.put<User>('/api/users/updatePersonality', {Personality: personality})
      .pipe(
        retry(2)
      );
  }

  getFlaggedMaps(): Observable<Maps[]> {
    return this.http.get<Maps[]>('/api/maps/getFlaggedMaps')
      .pipe(
        retry(2)
      );
  }

  searchForNewMap(query: string): Observable<string[]> {
    return this.http.get<string[]>('/api/maps/geocodeWithQuery/' + query)
      .pipe(
        retry(2)
      );
  }

  issueProvisionalWithLatLng(latitude: string, longitude: string): Observable<JSON> {
    return this.http.post<JSON>('/api/maps/issueProvisionalWithLatLng', {Longitude: longitude, Latitude: latitude})
      .pipe(
        retry(2)
      );
  }

  issueProvisionalWithPlace(place: string): Observable<JSON> {
    return this.http.post<JSON>('/api/maps/issueProvisionalWithPlace', {Place: place})
      .pipe(
        retry(2)
      );
  }

  updateMap(id: string, latitude: string, longitude: string, place: string): Observable<string> {
    return this.http.put<string>('/api/maps/acceptNewMap/' + id, {Latitude: latitude, Longitude: longitude, PlaceQuery: place})
      .pipe(
        retry(2)
      );
  }

  toggleMapFlag(id: string, value: boolean): Observable<Maps> {
    return this.http.put<Maps>('/api/maps/assignFlag', {Id: id, Value: value})
      .pipe(
        retry(2)
      );
  }
}
