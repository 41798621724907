import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service';
import { OverlayService } from '../overlay/overlay.service';
import { Personality } from 'src/models/Personality';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  numberOfPeople: number;
  northAmerica: boolean;
  southAmerica: boolean;
  europe: boolean;
  africa: boolean;
  asia: boolean;
  oceania: boolean;
  domestic: boolean;
  aiAllowed: boolean;
  lowDays: number;
  highDays: number;
  budgetFilter: boolean;
  midRangeFilter: boolean;
  luxuryFilter: boolean;
  visaVF: boolean;
  visaVR: boolean;
  visaVOA: boolean;
  visaVE: boolean;
  naSrc: string;
  saSrc: string;
  euSrc: string;
  afSrc: string;
  asSrc: string;
  ocSrc: string;
  doSrc: string;
  vfSrc: string;
  veSrc: string;
  voaSrc: string;
  vrSrc: string;
  useDays: boolean;
  daysValue: number;
  personality: Personality;
  filtersHeading: string;
  filtersPeopleHeading: string;
  filtersDaysHeading: string;
  filtersBudgetText: string;
  filtersMidrangeText: string;
  filtersLuxuryText: string;
  filtersContinentText: string;

  @Input() showAppFilters: boolean;

  constructor(private sharedService: SharedService, private overlayService: OverlayService) {}

  ngOnInit() {
    this.subscribeAll();
  }

  close() {
    this.overlayService.close();
    this.subscribeAll();
  }

  clickNumberOfPeople(people: number) {
    this.numberOfPeople = people;
  }

  clickRange(target: string) {
    if (target == 'days') {
      if (!this.useDays) {
        this.useDays = true;
      }
    } else if (target == 'range') {
      if (this.useDays) {
        this.useDays = false;
      }
    }
  }

  handleMinus(target: string) {
    if (target == 'days') {
      if (this.daysValue > 1) {
        this.daysValue -= 1;
      };
    } else if (target == 'rangeLow') {
      if (this.lowDays > 1) {
        this.lowDays -= 1;
      };
    } else if (target == 'rangeHigh') {
      if (this.highDays > 1) {
        this.highDays -= 1;
      };
    }
  }

  handlePlus(target : string) {
    if (target == 'days') {
      this.daysValue += 1;
    } else if (target == 'rangeLow') {
      this.lowDays += 1;
    } else if (target == 'rangeHigh') {  
      this.highDays += 1;
    }
  }

  // there's a reload bug here when a user selects one filter it implicitly assumes the others to be true.
  filterButton(option): void {
    // this.preload();
    if (option == "Budget" || option == 0) {
      this.budgetFilter = !this.budgetFilter;
      // this.sharedService.budgetFilter(this.budgetFilter);
    } else if (option == "MidRange" || option == 1) {
      this.midRangeFilter = !this.midRangeFilter;
      // this.sharedService.midRangeFilter(this.midRangeFilter);
    } else if (option == "Luxury" || option == 2) {
      this.luxuryFilter = !this.luxuryFilter;
      // this.sharedService.luxuryFilter(this.luxuryFilter);
    }
    // this.reload();
  }

  preload(): void {
    if (this.budgetFilter == true && this.midRangeFilter == true && this.luxuryFilter == true) {
      this.budgetFilter = false;
      // this.sharedService.budgetFilter(this.budgetFilter);
      this.midRangeFilter = false;
      // this.sharedService.midRangeFilter(this.midRangeFilter);
      this.luxuryFilter = false;
      // this.sharedService.luxuryFilter(this.luxuryFilter);
    }
  }

  reload(): void {
    if (this.budgetFilter == false && this.midRangeFilter == false && this.luxuryFilter == false) {
      this.budgetFilter = true;
      // this.sharedService.budgetFilter(this.budgetFilter);
      this.midRangeFilter = true;
      // this.sharedService.midRangeFilter(this.midRangeFilter);
      this.luxuryFilter = true;
      // this.sharedService.luxuryFilter(this.luxuryFilter);
    }
  }

  apply() {
    this.sharedService.numberOfPeople(this.numberOfPeople);
    this.sharedService.useDays(this.useDays);
    this.sharedService.numberOfDays(this.daysValue);
    this.sharedService.lowDays(this.lowDays);
    this.sharedService.highDays(this.highDays);
    this.sharedService.budgetFilter(this.budgetFilter);
    this.sharedService.midRangeFilter(this.midRangeFilter);
    this.sharedService.luxuryFilter(this.luxuryFilter);
    this.sharedService.northAmerica(this.northAmerica);
    this.sharedService.southAmerica(this.southAmerica);
    this.sharedService.europe(this.europe);
    this.sharedService.africa(this.africa);
    this.sharedService.asia(this.asia);
    this.sharedService.oceania(this.oceania);
    this.sharedService.domestic(this.domestic);
    this.sharedService.visaVF(this.visaVF);
    this.sharedService.visaVOA(this.visaVOA);
    this.sharedService.visaVE(this.visaVE);
    this.sharedService.visaVR(this.visaVR);
    this.sharedService.aiAllowed(this.aiAllowed);
    this.sharedService.setPassingPlacesWithoutInputData();
    this.close();
  }

  clearFilters() {
    let defaultNumberOfPeople = 1;
    let defaultUseDays = false;
    let defaultLowDays = 1;
    let defaultHighDays = 100;
    let defaultBudgetFilter = true;
    let defaultMidRangeFilter = true;
    let defaultLuxuryFilter = true;
    let defaultNorthAmerica = true;
    let defaultSouthAmerica = true;
    let defaultEurope = true;
    let defaultAfrica = true;
    let defaultAsia = true;
    let defaultOceania = true;
    let defaultDomestic = false;
    let defaultVisaFree = true;
    let defaultVisaOnArrival = true;
    let defaultVisaEVisa = true;
    let defaultVisaRequired = true;
    let defaultAiAllowed = true;
    
    this.numberOfPeople = defaultNumberOfPeople;
    this.useDays = defaultUseDays;
    this.lowDays = defaultLowDays;
    this.highDays = defaultHighDays;
    this.budgetFilter = defaultBudgetFilter;
    this.midRangeFilter = defaultMidRangeFilter;
    this.luxuryFilter = defaultLuxuryFilter;
    this.northAmerica = defaultNorthAmerica;
    this.southAmerica = defaultSouthAmerica;
    this.europe = defaultEurope;
    this.africa = defaultAfrica;
    this.asia = defaultAsia;
    this.oceania = defaultOceania;
    this.domestic = defaultDomestic;
    this.visaVF = defaultVisaFree;
    this.visaVOA = defaultVisaOnArrival;
    this.visaVE = defaultVisaEVisa;
    this.visaVR = defaultVisaRequired;
    this.aiAllowed = defaultAiAllowed;

    this.apply();
  }


  subscribeAll() {
    this.subscribeNumberOfPeople();
    this.subscribeFilters();
    this.subscribeDays();
    this.subscribeVisas();
    this.subscribeContinents();
    this.subscribeDomestic();
    this.subscribeAi();
    this.subscribePersonality();
  }

  subscribeAi() {
    this.sharedService.aiAllowed$.subscribe(bool => {
      this.aiAllowed = bool;
    })
  }

  subscribeNumberOfPeople() {
    this.sharedService.numberOfPeople$.subscribe(num => {
      this.numberOfPeople = num;
    });
  }

  subscribeFilters() {
    this.sharedService.budgetFilter$.subscribe(filter => {
      this.budgetFilter = filter;
    });

    this.sharedService.midRangeFilter$.subscribe(filter => {
      this.midRangeFilter = filter;
    });

    this.sharedService.luxuryFilter$.subscribe(filter => {
      this.luxuryFilter = filter;
    });
  }

  subscribeDays() {
    this.sharedService.useDays$.subscribe(bool => {
      this.useDays = bool;
    })

    this.sharedService.numberOfDays$.subscribe(num => {
      if (num == -1) {
        this.daysValue = 7;
      } else {
        this.daysValue = num
      }
    })


    this.sharedService.lowDays$.subscribe(num => {
      if (num == -1) {
        this.lowDays = 1;
      } else {
        this.lowDays = num;
      }
    });

    this.sharedService.highDays$.subscribe(num => {
      if (num == 999999) {
        this.highDays = 100;
      } else {
        this.highDays = num;
      }
    });
  }

  subscribeVisas() {
    this.sharedService.visaVF$.subscribe(visa => {
      this.visaVF = visa;
      if (this.visaVF) {
        this.vfSrc = "../../assets/orangecheckmark.svg"
      } else {
        this.vfSrc = "../../assets/graycheckmark.svg"
      };
    });
    this.sharedService.visaVOA$.subscribe(visa => {
      this.visaVOA = visa;
      if (this.visaVOA) {
        this.voaSrc = "../../assets/orangecheckmark.svg"
      } else {
        this.voaSrc = "../../assets/graycheckmark.svg"
      };
    });
    this.sharedService.visaVE$.subscribe(visa => {
      this.visaVE = visa;
      if (this.visaVE) {
        this.veSrc = "../../assets/orangecheckmark.svg"
      } else {
        this.veSrc = "../../assets/graycheckmark.svg"
      };
    });
    this.sharedService.visaVR$.subscribe(visa => {
      this.visaVR = visa;
      if (this.visaVR) {
        this.vrSrc = "../../assets/orangecheckmark.svg"
      } else {
        this.vrSrc = "../../assets/graycheckmark.svg"
      };
    });
  }

  subscribeContinents() {
    this.sharedService.northAmerica$.subscribe(continent => {
      this.northAmerica = continent;
      if (this.northAmerica) {
        this.naSrc = "../../assets/orangecheckmark.svg"
      } else {
        this.naSrc = "../../assets/graycheckmark.svg"
      }
    });
    this.sharedService.southAmerica$.subscribe(continent => {
      this.southAmerica = continent;
      if (this.southAmerica) {
        this.saSrc = "../../assets/orangecheckmark.svg"
      } else {
        this.saSrc = "../../assets/graycheckmark.svg"
      }
    });
    this.sharedService.europe$.subscribe(continent => {
      this.europe = continent;
      if (this.europe) {
        this.euSrc = "../../assets/orangecheckmark.svg"
      } else {
        this.euSrc = "../../assets/graycheckmark.svg"
      }
    });
    this.sharedService.africa$.subscribe(continent => {
      this.africa = continent;
      if (this.africa) {
        this.afSrc = "../../assets/orangecheckmark.svg"
      } else {
        this.afSrc = "../../assets/graycheckmark.svg"
      }
    });
    this.sharedService.asia$.subscribe(continent => {
      this.asia = continent;
      if (this.asia) {
        this.asSrc = "../../assets/orangecheckmark.svg"
      } else {
        this.asSrc = "../../assets/graycheckmark.svg"
      }
    });
    this.sharedService.oceania$.subscribe(continent => {
      this.oceania = continent;
      if (this.oceania) {
        this.ocSrc = "../../assets/orangecheckmark.svg"
      } else {
        this.ocSrc = "../../assets/graycheckmark.svg"
      }
    });
  }

  subscribeDomestic() {
    this.sharedService.domestic$.subscribe(allowed => {
      this.domestic = allowed;
      if (this.domestic) {
        this.doSrc = "../../assets/orangecheckmark.svg"
      } else {
        this.doSrc = "../../assets/graycheckmark.svg"
      }
    });
  }

  randomFromArray(array) {
    return array[Math.round(Math.random() * 100) % array.length];
  }

  randomFiltersHeading() {
    if (this.personality == Personality.Pompous) {
      this.filtersHeading = this.randomFromArray([
        'Parameters by which to define the bounds of the adventure'
      ])
    } else if (this.personality == Personality.Professional) {
      this.filtersHeading = this.randomFromArray([
        'Filters'
      ])
    } else if (this.personality == Personality.Salty) {
      this.filtersHeading = this.randomFromArray([
        'Filter this king'
      ])
    }
  }

  randomFiltersPeopleHeading() {
    if (this.personality == Personality.Pompous) {
      this.filtersPeopleHeading = this.randomFromArray([
        'The number of humans with which you will travel inclusive of yourself:'
      ])
    } else if (this.personality == Personality.Professional) {
      this.filtersPeopleHeading = this.randomFromArray([
        'How many people:'
      ])
    } else if (this.personality == Personality.Salty) {
      this.filtersPeopleHeading = this.randomFromArray([
        'How many goons:',
      ])
    }
  }

  randomFiltersDaysHeading() {
    if (this.personality == Personality.Pompous) {
      this.filtersDaysHeading = this.randomFromArray([
        'The length, nay, duration of yon sojourn'
      ])
    } else if (this.personality == Personality.Professional) {
      this.filtersDaysHeading = this.randomFromArray([
        'How many days'
      ])
    } else if (this.personality == Personality.Salty) {
      this.filtersDaysHeading = this.randomFromArray([
        'how long you wanna be gone',
      ])
    }
  }

  randomBudgetText() {
    if (this.personality == Personality.Pompous) {
      this.filtersBudgetText = this.randomFromArray([
        'Accommodations sharing the presence of other mortals, underwhelming food originating either from the stove in the lackluster abode or purchased while walking about the neighboring environs'
      ])
    } else if (this.personality == Personality.Professional) {
      this.filtersBudgetText = this.randomFromArray([
        'Shared hostel room, street food or cooking, walking, etc.'
      ])
    } else if (this.personality == Personality.Salty) {
      this.filtersBudgetText = this.randomFromArray([
        'Shoestrings homie, I can sleep in a shared hostel room. BYOC(ockroaches)',
      ])
    }
  }

  randomMidrangeText() {
    if (this.personality == Personality.Pompous) {
      this.filtersMidrangeText = this.randomFromArray([
        'Own room, restaurants, taxi'
      ])
    } else if (this.personality == Personality.Professional) {
      this.filtersMidrangeText = this.randomFromArray([
        'Own room, restaurants, taxi'
      ])
    } else if (this.personality == Personality.Salty) {
      this.filtersMidrangeText = this.randomFromArray([
        'Nah trick, I\'m too old for that, knees buckled under the weight of my 9-5 job. I need my own room and I don\'t want to cook, I\'m on vacation',
      ])
    }
  }

  randomLuxuryText() {
    if (this.personality == Personality.Pompous) {
      this.filtersLuxuryText = this.randomFromArray([
        'An exceptional experience on all counts, evincing royalty in composition'
      ])
    } else if (this.personality == Personality.Professional) {
      this.filtersLuxuryText = this.randomFromArray([
        'Hotel suite, Michellin restaurants, hot air balloon rides'
      ])
    } else if (this.personality == Personality.Salty) {
      this.filtersLuxuryText = this.randomFromArray([
        'They used table salt instead of pink himalayan sea salt on my line caught branzino, brb crying',
      ])
    }
  }

  randomContinentsText() {
    if (this.personality == Personality.Pompous) {
      this.filtersContinentText = this.randomFromArray([
        'Which tectonic partition of the globe suits you?'
      ])
    } else if (this.personality == Personality.Professional) {
      this.filtersContinentText = this.randomFromArray([
        'I\ll go to'
      ])
    } else if (this.personality == Personality.Salty) {
      this.filtersContinentText = this.randomFromArray([
        'Continents you\re cool with',
        'Where d\'ya wanna go?',
        'Continents you could be caught @'
      ])
    }
  }

  subscribePersonality() {
    this.sharedService.personality$.subscribe(p => {
      this.personality = p;
      this.randomFiltersHeading();
      this.randomFiltersPeopleHeading();
      this.randomFiltersDaysHeading();
      this.randomBudgetText();
      this.randomMidrangeText();
      this.randomLuxuryText();
      this.randomContinentsText();
    })
  }
}
