import { Component, OnInit, Input } from '@angular/core';
import { Place } from '../../models/Place';
import { AuthService } from '../auth.service';
import { SharedApiService } from '../shared/shared-api.service';
import { OverlayService } from '../overlay/overlay.service';

@Component({
  selector: 'app-placard',
  templateUrl: './placard.component.html',
  styleUrls: ['./placard.component.scss']
})
export class PlacardComponent implements OnInit {
  @Input() place: Place;
  luxuries = ["Budget", "Mid-range", "Luxury"];
  costs: number[];
  photos: string[];
  hovered: boolean;


  constructor(
    private authService: AuthService, 
    private sharedApiService: SharedApiService, 
    private overlayService: OverlayService
  ) {}

  // TODO: Get three separate images, refactor HTML to not be super repetative.

  ngOnInit() {
    this.hovered = false;
    this.costs = [
      this.place.BudgetAverageCost,
      this.place.MidRangeAverageCost,
      this.place.LuxuryAverageCost
    ];
    
    if (this.place.PhotoURL) {
      this.sharedApiService.getPhoto(this.place.PhotoURL)
      .subscribe( data => {
        //let max = data['total'];
        //let pictureIndex = Math.floor(Math.random() * max);
        let pictureIndex = Math.floor(Math.random() * 10); // There are 10 photos per page
        this.place.Photo = data['results'][pictureIndex]['urls']['small'];
        this.photos = [data['results'][pictureIndex]['urls']['small']];
        this.photos.push(data['results'][pictureIndex+1 % 10]['urls']['small']);
        this.photos.push(data['results'][pictureIndex+2 % 10]['urls']['small']);
      })
    }
  }

  heart() {
    if (this.authService.isLoggedIn()) {
      this.registerHeart(this.place);
    } else {
      this.overlayService.open("login");
    }
  }

  registerHeart(place: Place) {
    
  }

}
