import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ViatorProducts } from 'src/models/ViatorProduct';
import { ViatorDestination } from 'src/models/ViatorDestination';

@Injectable({
  providedIn: 'root'
})
export class ViatorService {

  constructor(private http: HttpClient) { }

  getViatorDestination(place: string, country: string): Observable<ViatorDestination[]> {
    return this.http.get<ViatorDestination[]>(`/api/viator/getByPlace/${place}/${country}`).
      pipe(
        retry(2)
      )
  }

  getViatorTours(destinationId: number, lowestPrice: number, highestPrice: number, startDate: string, endDate: string, durationFrom: number, durationTo: number): Observable<ViatorProducts> {
    return this.http.post<ViatorProducts>('/api/viator/tours', {
      destinationId: destinationId,
      lowestPrice: lowestPrice,
      highestPrice: highestPrice,
      startDate: startDate,
      endDate: endDate,
      durationFrom: durationFrom,
      durationTo: durationTo
    }).
      pipe(
        retry(2)
      )
  }

}
