<div class="CostSearch-header">
    {{ this.costSearchHeader }}
</div>

<section class="CostSearch-section" id="scrollToSection">
    <div class="CostSearch CostSearch-main" [ngClass]="searchExecuted == true ? 'CostSearch-main--right' : ''">
        <div class="CostSearch-content">
            <div class="CostSearch-row CostSearch-searchBar">
                <div class="CostSearch-searchBar--left">
                    <span class="CostSearch-budgetText">
                        {{ this.costSearchBudgetText }}
                    </span>
                    <input class="CostSearch-input" [(ngModel)]="cost" (keyup.enter)="search()">
                </div>
                <div class="CostSearch-searchBar--right">
                    <button class="CostSearch-cta CostSearch-travel" (click)="search()" (click)="scroll()">
                        {{ this.costSearchCTA }}
                    </button>
                    <button class="CostSearch-filterCTA" (click)="this.openFilters()">
                        <span class="CostSearch-filterCTA--text">
                            {{ this.costSearchFilterCTA }}
                        </span>
                        <img src="../../assets/filter.svg">
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="CostSearch-searchBar--secondary">
    <button class="CostSearch-sortPrice" (click)="this.sortByPrice()">
        {{ this.costSearchSortPrice }}
        <div class="CostSearch-seeNotes">
            <img class="CostSearch-chevron" [ngClass]="this.rotateChevron ? 'rotate' : ''" src="../../assets/chevron.svg">
        </div>
    </button>
    <button class="CostSearch-shuffle" (click)="this.shuffleAllPlaces()">
        {{ this.costSearchShuffle }}
    </button>
</div>

<section *ngIf="searchExecuted">
    <div>
        <div *ngIf="allPassingPlaces.length > 0">
            <div class="CostSearch-list" infinite-scroll
            (scrolled)="onScrollDown()"
            (scrolledUp)="onScrollUp()"
            [scrollWindow]="true"
            [infiniteScrollDistance]="3"
            [infiniteScrollUpDistance]="3"
            [infiniteScrollThrottle]="150"
            [alwaysCallback]="true">
                <div *ngFor="let place of allPassingPlaces; let i=index">
                    <app-daycard [place]='place' [search]='expense' *ngIf="i < this.scrollIndex"></app-daycard>
                </div>
            </div>
        </div>
    </div>

    <div class="CostSearch-noResults" *ngIf="searchExecuted && allPassingPlaces.length == 0">
        We are budget travelers, not miracle workers. Try this random number on for size <button class="CostSearch-randomButton" (click)=searchRandomCost()><span>${{ this.randomCost }}</span></button>
    </div>
    <!-- TODO: <div class="CostSearch-noResultsFiltered" >
        Filters are hiding all of the results! Clear those? 
    </div> -->
</section>
