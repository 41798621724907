import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { Trip } from '../../models/Trip';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecentlyAddedService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  getRecent(): Observable<Trip[]> {
    return this.http.get<Trip[]>('/api/recentlyAdded')
      .pipe(
        retry(2)
      )
  }
}
