import { Component } from '@angular/core';
import { AuthService } from './auth.service';
import { OverlayService } from './overlay/overlay.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  excludedTagNames: string[] = [
    "app-filters", 
    "app-login",
    "button"
  ]

  constructor(
    private authService: AuthService, 
    protected overlayService: OverlayService
  ) {}

  ngOnInit() { }

  closeModal(evt) {
    console.log(evt);
    let target = evt.target;
    while (target != null) {
      if (this.excludedTagNames.indexOf(target.tagName.toLowerCase()) > -1) {
        return;
      }
      target = target.parentElement;
    }
    this.overlayService.close();
  }
}