import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { retry, map, shareReplay } from 'rxjs/operators';
import { Passport } from '../../models/Passport';
import { SharedService } from './shared.service';
import { Airport } from 'src/models/Airport';
import { Personality } from 'src/models/Personality';
import { Typeahead } from 'src/models/Typeahead';
import { Photos } from 'src/models/Photo';
import { Maps } from 'src/models/Maps';
import { Place } from 'src/models/Place';

@Injectable({
  providedIn: 'root'
})
export class SharedApiService {

  constructor(private http: HttpClient, private authService: AuthService, private sharedService: SharedService) { }

  getImage(mapURL: string): Observable<Blob> {
    return this.http.get('/' + mapURL, {responseType: "blob"});
  }

  getPhoto(photoUrl: string): Observable<any> {
    return this.http.get('/' + photoUrl);
  }

  registerHeart(PlaceId: number, isInternal: boolean): Observable<any> {
    if (isInternal) {
      return this.http.put('/api/internalTrips/registerHeart', {TripId: PlaceId})
        .pipe(
          retry(2)
        )
    } else {
      return this.http.put('/api/places/registerHeart', {PlaceId: PlaceId})
        .pipe(
          retry(2),
          map((result) => {
            // This is really hacky, but when a heart is registered we need to
            // invalidated the filledIdsCache.
            this.filledIdsCache = null;

            return result;
          })
        )
    }

  }

  filledIdsCache: Observable<number[]> = null;
  getFilledIds(): Observable<number[]> {
    if(this.filledIdsCache == null) {
      this.filledIdsCache = this.http.get<number[]>('/api/places/userLikedIds').pipe(
        retry(2),
        shareReplay(1, 30 * 1000)
      )
    }
    return this.filledIdsCache;    
  } 

  getIsFilled(PlaceId: number, isInternal: boolean): Observable<boolean> {
    if (isInternal) {
      return this.http.get<boolean>('/api/internalTrips/isHearted/' + PlaceId)
        .pipe(
          retry(2)
        )
    } else {
      return this.getFilledIds().pipe(map((all_likes) => all_likes.includes(PlaceId)));
    }
  }

  async getIsFilledAsync(PlaceId: number, isInternal: boolean): Promise<boolean> {
    if (isInternal) {
      return this.http.get<boolean>('/api/internalTrips/isHearted/' + PlaceId)
        .pipe(
          retry(2)
        ).toPromise();
    } else {
      return this.getFilledIds().pipe(map((all_likes) => {
        return all_likes.includes(PlaceId)
      })).toPromise();
    }
  }

  getMapForItineraryId(itineraryId: number): Observable<Maps[]> {
    return this.http.get<Maps[]>('/api/maps/itineraryId/' + itineraryId)
      .pipe(
        retry(2)
      )
  }

  // getPassportColor(country: string): Observable<string> {
  //   if (localStorage.getItem("UserPassportCountry") != null) {
  //     return this.setPassportColor(country);
  //   } else {
  //     this.getPassportCountry().pipe(map(
  //       result => {
  //         localStorage.setItem("UserPassportCountry", result);
  //         return this.setPassportColor(country);
  //       }
  //     ))      
  //     // subscribe((t: string) => {
  //     //    localStorage.setItem("UserPassportCountry", t);
  //     //    return this.setPassportColor(country);
  //     //  });
  //   }
  // }

  // setPassportColor(country: string): Observable<string> {
  //   if (localStorage.getItem("UserPassportCountry") != null) {    
  //     if (localStorage.getItem("PassportObject") == null) {
  //       this.getPassport(localStorage.getItem("UserPassportCountry")).subscribe((t: Passport) => {
  //         localStorage.setItem("PassportObject", JSON.stringify(t[0]));
  //         return this.sharedService.processPassportColor(t[0], country);
  //       });
  //     } else {
  //       return of(this.sharedService.processPassportColor(JSON.parse(localStorage.getItem("PassportObject")), country));
  //     }
  //   } else {
  //     return of("none");
  //   }
  // }

  getPassport(CountryOfOrigin: string): Observable<Passport> {
    return this.http.get<Passport>('/api/passports/countryOrigin/' + CountryOfOrigin)
      .pipe(
        retry(2)
      )
  }

  getPassportCountry(): Observable<string> {
    return this.http.get<string>('/api/users/passportCountry')
      .pipe(
        retry(2)
      )
  }

  getUserAirport(): Observable<Airport> {
    return this.http.get<Airport>('/api/users/airport')
      .pipe(
        retry(2)
      )
  }

  getUserPersonality(): Observable<Personality> {
    return this.http.get<Personality>('/api/users/personality')
      .pipe(
        retry(2)
      )
  }

  getAirportTypeAhead(fragment): Observable<Typeahead[]> {
    return this.http.get<Typeahead[]>('/api/flights/typeahead/' + fragment)
      .pipe(
        retry(2)
      )
  }

  updateAirport(airport): Observable<Airport> {
    return this.http.put<Airport>('/api/users/updateAirport', {Airport: airport})
      .pipe(
        retry(2)
      )
  }

  
  isAdmin: Observable<boolean> = null;
  getUserIsAdmin(): Observable<boolean> {
    if(this.isAdmin == null) {
      this.isAdmin = this.http.get<boolean>('/api/users/userIsAdmin')
      .pipe(
        retry(2),
        // Store the result for 30 seconds
        shareReplay(1, 30 * 1000)
      );
    }
    return this.isAdmin;
  }

  getMapIsFlagged(tripId: number): Observable<boolean> {
    return this.http.get<boolean>('/api/internalTrips/mapIsFlagged/' + tripId)
      .pipe(
        retry(2)
      );
  }

  shareItinerary(ItineraryId: number, ReceiverName: string, ReceiverEmail: string) {
    return this.http.post<string>('/api/itineraries/share', {
      ItineraryId: ItineraryId,
      ReceiverName: ReceiverName,
      ReceiverEmail: ReceiverEmail
    })
      .pipe(
        retry(2)
      )
  }

  getPhotosForPlaceName(Name: string): Observable<Photos[]> {
    return this.http.get<Photos[]>('/api/photos/name/' + Name)
      .pipe(
        retry(2)
      )
  }

  getPlaces(): Observable<Place[]> {
    return this.http.get<Place[]>('/api/places')
      .pipe(
        retry(2)
      )
  }

  assignPhotoFlag(htmlPhoto, value: boolean): Observable<Photos> {
    return this.http.put<Photos>('/api/photos/flag', {htmlPhoto: htmlPhoto, Value: value})
      .pipe(
        retry(2)
      )
  }
}
