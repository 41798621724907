<app-modal [width]='600' [type]='"app-filters"'>
    <div class="Filters">
        <div class="Filters-header">
            <div class="Filters-heading">
                {{ this.filtersHeading }}
            </div>
        </div>
        
        <div class="Filters-content">
            <div class="Filters-row">
                <div class="Filters-subheading">
                    {{ this.filtersPeopleHeading }}
                </div>

                <img class="Filters-person" src="../../assets/orangeperson.svg" (click)="this.clickNumberOfPeople(1)">
                <img class="Filters-person" [src]="this.numberOfPeople >= 2 ? '../../assets/orangeperson.svg' : '../../assets/grayperson.svg'" (click)="this.clickNumberOfPeople(2)">
                <img class="Filters-person" [src]="this.numberOfPeople >= 3 ? '../../assets/orangeperson.svg' : '../../assets/grayperson.svg'" (click)="this.clickNumberOfPeople(3)">
                <img class="Filters-person" [src]="this.numberOfPeople >= 4 ? '../../assets/orangeperson.svg' : '../../assets/grayperson.svg'" (click)="this.clickNumberOfPeople(4)">
                <img class="Filters-person" [src]="this.numberOfPeople >= 5 ? '../../assets/orangeperson.svg' : '../../assets/grayperson.svg'" (click)="this.clickNumberOfPeople(5)">
            </div>
            <div class="Filters-row">
                <div class="Filters-subheading">
                    {{ this.filtersDaysHeading }}
                </div>

                <div class="Filters-daysRow">
                    <div class="Filters-rangeHeader" (click)="clickRange('days')">
                        <img [src]="this.useDays ? '../../assets/circlefilled.svg' : '../../assets/circleempty.svg'">
                        <span class="Filters-rangeHeader--text">Days</span>
                    </div>
                    <div class="Filters-numPeople">
                        <button class="Filters-plusminus Filters-minus" [ngClass]="this.useDays ? '' : 'Filters-range--inactive'" [disabled]="!this.useDays" (click)="handleMinus('days')">-</button>
                        <div class="Filters-inputCenter">
                            <input type="number" class="Filters-number" [ngClass]="this.useDays ? '' : 'Filters-range--inactive'"  [disabled]="!this.useDays" [(ngModel)]="daysValue" />
                            <span class="Filters-daysText" [ngClass]="this.useDays ? '' : 'Filters-range--inactive'">Day{{ this.daysValue == 1 ? '' : 's' }}</span>
                        </div>
                        <button class="Filters-plusminus Filters-plus" [ngClass]="this.useDays ? '' : 'Filters-range--inactive'" [disabled]="!this.useDays" (click)="handlePlus('days')">+</button>
                    </div>
                </div>
                <div class="Filters-daysRow">
                    <div class="Filters-rangeHeader" (click)="clickRange('range')">
                        <img [src]="this.useDays ? '../../assets/circleempty.svg' : '../../assets/circlefilled.svg'">
                        <span class="Filters-rangeHeader--text">Range</span>
                    </div>
                    <div class="Filters-numPeople">
                        <button class="Filters-plusminus Filters-minus" [ngClass]="this.useDays ? 'Filters-range--inactive' : ''" [disabled]="this.useDays" (click)="handleMinus('rangeLow')">-</button>
                        <div class="Filters-inputCenter">
                            <input type="number" class="Filters-number" [ngClass]="this.useDays ? 'Filters-range--inactive' : ''" [disabled]="this.useDays" [(ngModel)]="lowDays" /> 
                            <span class="Filters-daysText" [ngClass]="this.useDays ? 'Filters-range--inactive' : ''">Day{{ this.lowDays == 1 ? '' : 's' }}</span>
                        </div>  
                        <button class="Filters-plusminus Filters-plus" [ngClass]="this.useDays ? 'Filters-range--inactive' : ''" [disabled]="this.useDays" (click)="handlePlus('rangeLow')">+</button>
                    </div>
                    <div class="Filters-rangeDivider" [ngClass]="this.useDays ? 'Filters-range--inactive' : ''">
                        to
                    </div>
                    <div class="Filters-numPeople Filters-numPeople--last">
                        <button class="Filters-plusminus Filters-minus" [ngClass]="this.useDays ? 'Filters-range--inactive' : ''" [disabled]="this.useDays" (click)="handleMinus('rangeHigh')">-</button>
                        <div class="Filters-inputCenter">
                            <input type="number" class="Filters-number" [ngClass]="this.useDays ? 'Filters-range--inactive' : ''" [disabled]="this.useDays" [(ngModel)]="highDays" />
                            <span class="Filters-daysText" [ngClass]="this.useDays ? 'Filters-range--inactive' : ''">Day{{ this.highDays == 1 ? '' : 's' }}</span>
                        </div>
                        <button class="Filters-plusminus Filters-plus" [ngClass]="this.useDays ? 'Filters-range--inactive' : ''" [disabled]="this.useDays" (click)="handlePlus('rangeHigh')">+</button>
                    </div>
                </div>
            </div>
            <div class="Filters-row">
                <div class="Filters-subheading">
                    Select trip type:
                </div>
                <div class="Filters-budgetsWrapper">
                    <div class="Filters-budgetInner">
                        <div class="Filters-budgets Filters-budget" [ngClass]="budgetFilter == true ? 'Filters-budgetFilled' : ''" (click)="filterButton('Budget')">
                            <div>
                                Budget
                            </div>
                            <div>
                                <img src="../../assets/whitecheckmark.svg">
                            </div>
                        </div>
                        <div class="Filters-budgetText">
                            Shared hostel room, street food or cooking, walking, etc.
                        </div>
                    </div>
                    <div class="Filters-budgetInner">
                        <div class="Filters-budgets Filters-midRange" [ngClass]="midRangeFilter == true ? 'Filters-midRangeFilled' : ''"  (click)="filterButton('MidRange')">
                            <div>
                                Mid-range
                            </div>
                            <div>
                                <img src="../../assets/whitecheckmark.svg">
                            </div>
                        </div>
                        <div class="Filters-budgetText">
                            {{ this.filtersMidrangeText}}
                        </div>
                    </div>
                    <div class="Filters-budgetInner">
                        <div class="Filters-budgets Filters-luxury" [ngClass]="luxuryFilter == true ? 'Filters-luxuryFilled' : ''"  (click)="filterButton('Luxury')">
                            <div>
                                Luxury
                            </div>
                            <div>
                                <img src="../../assets/whitecheckmark.svg">
                            </div>
                        </div>
                        <div class="Filters-budgetText">
                            {{ this.filtersLuxuryText }}
                        </div>
                    </div>
                </div>
            </div>
                <div class="Filters-row">
                <div class="Filters-subheading">
                    <!-- Where d'ya wanna go? -->
                    <!-- Continents you're cool with: -->
                    <!-- Continents could be caught @: -->
                    <!-- TODO vulgarity -->
                    <!-- Catch me in: -->
                    I'll go to:
                </div>
                <div class="Filters-buttonsWrapper">
                    <div class="Filters-buttonWrapper">
                        <button [ngClass]="this.northAmerica ? 'Filters-buttonChecked' : 'Filters-buttonGray'" (click)="this.northAmerica=!this.northAmerica">
                            <span class="Filters-buttonText">
                                North America
                            </span>
                            <img [src]="this.northAmerica ? '../../assets/orangecheckmark.svg' : '../../assets/graycheckmark.svg'">
                        </button>
                    </div>
                    <div class="Filters-buttonWrapper">
                        <button [ngClass]="this.southAmerica ? 'Filters-buttonChecked' : 'Filters-buttonGray'" (click)="this.southAmerica=!this.southAmerica">
                            <span class="Filters-buttonText">
                                South America
                            </span>
                            <img [src]="this.southAmerica ? '../../assets/orangecheckmark.svg' : '../../assets/graycheckmark.svg'">
                        </button>
                    </div>
                    <div class="Filters-buttonWrapper">
                        <button [ngClass]="this.europe ? 'Filters-buttonChecked' : 'Filters-buttonGray'" (click)="this.europe=!this.europe">
                            <span class="Filters-buttonText">
                                Europe
                            </span>
                            <img [src]="this.europe ? '../../assets/orangecheckmark.svg' : '../../assets/graycheckmark.svg'">
                        </button>
                    </div>
                    <div class="Filters-buttonWrapper">
                        <button [ngClass]="this.africa ? 'Filters-buttonChecked' : 'Filters-buttonGray'" (click)="this.africa=!this.africa">
                            <span class="Filters-buttonText">
                                Africa
                            </span>
                            <img [src]="this.africa ? '../../assets/orangecheckmark.svg' : '../../assets/graycheckmark.svg'">
                        </button>
                    </div>
                    <div class="Filters-buttonWrapper">
                        <button [ngClass]="this.asia ? 'Filters-buttonChecked' : 'Filters-buttonGray'" (click)="this.asia=!this.asia">
                            <span class="Filters-buttonText">
                                Asia
                            </span>
                            <img [src]="this.asia ? '../../assets/orangecheckmark.svg' : '../../assets/graycheckmark.svg'">

                        </button>
                    </div>
                    <div class="Filters-buttonWrapper">
                        <button [ngClass]="this.oceania ? 'Filters-buttonChecked' : 'Filters-buttonGray'" (click)="this.oceania=!this.oceania">
                            <span class="Filters-buttonText">
                                Oceania
                            </span>
                            <img [src]="this.oceania ? '../../assets/orangecheckmark.svg' : '../../assets/graycheckmark.svg'">

                        </button>
                        <div class="Filters-oceaniaBaby" *ngIf="this.personality == 2">
                            <!-- TODO vulgarity -->
                            <!-- <a href="/oceania">¿where tf is that?</a> -->
                            <a href="/oceania">¿where is that?</a>
                            <!-- 
                                Pompous: The location of oceania escapes my memory, refresh my mind would you?
                                Professional: ¿where is that?
                                Salty: ¿where tf is that?
                            -->
                        </div>
                    </div>
                </div>

                <div>
                    <div class="Filters-subheading">
                        Include domestic destinations: 
                        <!-- 
                            Pompous: Is travel within the bounds of the nation's frontier acceptable:
                            Professional: Include domestic destinations:
                            Salty: wanna see what's in this country also:
                        -->
                    </div>
                    <div class="Filters-buttonWrapper">
                        <button [ngClass]="this.domestic ? 'Filters-buttonChecked' : 'Filters-buttonGray'" (click)="this.domestic=!this.domestic">
                            <span class="Filters-buttonText">
                                {{this.domestic ? 'Yes' : 'No'}}
                            </span>
                            <img [src]="this.domestic ? '../../assets/orangecheckmark.svg' : '../../assets/graycheckmark.svg'">    
                        </button>
                        
                    </div>
                </div>
            </div>
            <div class="Filters-row">
                <div class="Filters-subheading">
                    <!-- Getting a visa can be onerous, if you can't be on that vibe let us know. -->
                    <!-- TODO vulgarity -->
                    <!-- Passport stuff: -->
                    Visa requirements:
                    <!-- 
                        Pompous: Eliminate certain categories of visa imposition
                        Professional: Visa requirements:
                        Salty: Passport stuff
                    -->
                </div>
                <div class="Filters-buttonsWrapper">
                    <div class="Filters-buttonWrapper">
                        <button [ngClass]="this.visaVF ? 'Filters-buttonChecked' : 'Filters-buttonGray'" (click)="this.visaVF=!this.visaVF">
                            <span class="Filters-buttonText">
                                Visa Free
                            </span>
                            <img [src]="this.visaVF ? '../../assets/orangecheckmark.svg' : '../../assets/graycheckmark.svg'">
                        </button>
                    </div>
                    <div class="Filters-buttonWrapper">
                        <button [ngClass]="this.visaVOA ? 'Filters-buttonChecked' : 'Filters-buttonGray'" (click)="this.visaVOA=!this.visaVOA">
                            <span class="Filters-buttonText">
                                Visa on arrival
                            </span>
                            <img [src]="this.visaVOA ? '../../assets/orangecheckmark.svg' : '../../assets/graycheckmark.svg'">
                        </button>
                    </div>
                    <div class="Filters-buttonWrapper">
                        <button [ngClass]="this.visaVE ? 'Filters-buttonChecked' : 'Filters-buttonGray'" (click)="this.visaVE=!this.visaVE">
                            <span class="Filters-buttonText">
                                eVisa
                            </span>
                            <img [src]="this.visaVE ? '../../assets/orangecheckmark.svg' : '../../assets/graycheckmark.svg'">
                        </button>
                    </div>
                    <div class="Filters-buttonWrapper">
                        <button [ngClass]="this.visaVR ? 'Filters-buttonChecked' : 'Filters-buttonGray'" (click)="this.visaVR=!this.visaVR">
                            <span class="Filters-buttonText">
                                Visa Required
                            </span>
                            <img [src]="this.visaVR ? '../../assets/orangecheckmark.svg' : '../../assets/graycheckmark.svg'">
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="Filters-footer">
            <div class="Filters-apply">
                <button class="Filters-clearButton" (click)="this.clearFilters()">
                    Clear Filters
                </button>
                <button class="Filters-applyButton" (click)="this.apply()">
                    Apply
                </button>
            </div>
        </div>

    </div>
</app-modal>