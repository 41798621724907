import { Component, OnInit } from '@angular/core';
import { Trip } from '../../models/Trip';
import { CreateService } from './create.service';
import { SharedService } from '../shared/shared.service';
import { AuthService } from '../auth.service';
import { OverlayService } from '../overlay/overlay.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  internalIndex: number;
  Create: Trip;
  screwed: boolean;
  individualCost: string;
  dailyCost: string;
  man: boolean;
  chad: boolean;
  link: string;
  sheet: File;

  constructor(
    private createService: CreateService, 
    private sharedService: SharedService, 
    private overlayService: OverlayService, 
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.internalIndex = 1;
    this.screwed = false;
    this.formInit();
    this.setWidth();
    this.man = false;
    this.chad = false;
    this.link = "";
    this.sheet = null;
    // this.setAutoFocus();
    // this.individualCost = 0;
    // this.dailyCost = 0;
  }

  formInit(): void {
    this.Create = <Trip> {
      Place: ''
    };
    this.individualCost = "";
    this.dailyCost = "";
    this.link = "";
  }

  // setAutoFocus() {
  //   let inputs = document.getElementsByTagName("input");
  //   inputs[this.internalIndex].focus();
  // }

  setWidth() {
    var pixels = Math.floor(this.internalIndex / 8 * 100);
    document.getElementsByClassName("Create-bar")[0].setAttribute("style", "width:" + pixels.toString() + "%");
  }

  next()
  {
    this.internalIndex += 1;
    this.setWidth();
    // this.setAutoFocus();
  }

  previous() {
    this.internalIndex -= 1;
    this.setWidth();
  }

  screwIt() {
    this.screwed = !this.screwed;
  }

  setCost(): string {
    if (this.Create.Cost != "" && this.Create.Cost !== undefined) {
      return this.Create.Cost
    } else if (this.individualCost != "") {
      return (this.sharedService.convertCostToNumber(this.individualCost) * parseInt(this.Create.NumberOfPeople)).toString();
    } else if (this.dailyCost != "") {
      return (this.sharedService.convertCostToNumber(this.dailyCost) * parseInt(this.sharedService.convertDurationToDays(this.Create.Duration))).toString();
    }
    return this.Create.Cost;
  }

  addByUser(): void {
    if (this.authService.isLoggedIn()) {
      this.Create.Cost = this.setCost();
      this.createService.addByUser(this.Create).subscribe((t: Trip[]) => {
        this.formInit();
        location.assign("/profile");
      });
    } else {
      this.overlayService.open("login");
    }
  }

  submitLink(): void {
    if (this.authService.isLoggedIn()) {
      this.createService.submitLink(this.link).subscribe((t: string) => {
        this.formInit();
        location.assign("/profile"); // TODO probably need to do something other than this
      });
    } else {
      this.overlayService.open("login");
    }
    
  }

  submitSheet(): void {
    if (this.authService.isLoggedIn()) {
      this.createService.submitSheet(this.sheet).subscribe((t: string) => {
        this.formInit();
        location.assign("/profile"); // TODO probably need to do something other than this
      });
    } else {
      this.overlayService.open("login");
    }
    
  }

}
