import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { Trip } from 'src/models/Trip';
import { Maps } from 'src/models/Maps';
import { Place } from 'src/models/Place';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  getUserIsAdmin(): Observable<boolean> {
    return this.http.get<boolean>('/api/users/userIsAdmin')
      .pipe(
        retry(2)
      );
  }

  getTripsForApproval(): Observable<Trip[]> {
    return this.http.get<Trip[]>('/api/recentlyAdded')
      .pipe(
        retry(2)
      );
  }

  updateTripStatus(id, body): Observable<Trip> {
    return this.http.put<Trip>('/api/recentlyAdded/' + id, body)
      .pipe(
        retry(2)
      );
  }

  getFlaggedMaps(): Observable<Maps[]> {
    return this.http.get<Maps[]>('/api/maps/getFlaggedMaps')
      .pipe(
        retry(2)
      );
  }

  getMaps(): Observable<Maps[]> {
    return this.http.get<Maps[]>('/api/maps')
      .pipe(
        retry(2)
      );
  }

  searchForNewMap(query: string): Observable<string[]> {
    return this.http.get<string[]>('/api/maps/geocodeWithQuery/' + query)
      .pipe(
        retry(2)
      );
  }

  issueProvisionalWithLatLng(latitude: string, longitude: string): Observable<JSON> {
    return this.http.post<JSON>('/api/maps/issueProvisionalWithLatLng', {Longitude: longitude, Latitude: latitude})
      .pipe(
        retry(2)
      );
  }

  issueProvisionalWithPlace(place: string): Observable<JSON> {
    return this.http.post<JSON>('/api/maps/issueProvisionalWithPlace', {Place: place})
      .pipe(
        retry(2)
      );
  }

  updateMap(id: string, latitude: string, longitude: string, place: string): Observable<string> {
    return this.http.put<string>('/api/maps/acceptNewMap/' + id, {Latitude: latitude, Longitude: longitude, PlaceQuery: place})
      .pipe(
        retry(2)
      );
  }

  newMap(queryName: string, placeName: string): Observable<string> {
    return this.http.post<string>('/api/maps/newMap', {QueryName: queryName, Place: placeName})
      .pipe(
        retry(2)
      );
  }

  toggleMapFlag(id: string, value: boolean): Observable<Maps> {
    return this.http.put<Maps>('/api/maps/assignFlag', {Id: id, Value: value})
      .pipe(
        retry(2)
      );
  }

  getPlaces(): Observable<Place[]> {
    return this.http.get<Place[]>('/api/places')
    .pipe(
      retry(2)
    )
  }

  generateItinerary(place: string, budget: string, duration: string): Observable<string> {
    return this.http.post<string>('/api/ai/GenerateItinerary', {place: place, budget: budget, duration: duration})
      .pipe(
        retry(2)
      );
  }

  createAiTrip(place: string, luxury: string, duration: string, cost: string, currency: string, numberOfPeople: number, itinerary: string): Observable<Trip> {
    return this.http.post<Trip>('api/aiTrips/createNew', {
      Place: place,
      Luxury: luxury,
      Duration: duration,
      Cost: cost,
      Currency: currency,
      NumberOfPeople: numberOfPeople,
      Itinerary: itinerary
    })
    .pipe(
      retry(2)
    );
  }
}
