import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { HeaderService } from './header.service';
import { OverlayService } from '../overlay/overlay.service';
import { trigger, transition, animate, style } from '@angular/animations';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('350ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('350ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit {
  isLoggedIn: boolean
  showMobileHeader: boolean;

  constructor(
    private headerService: HeaderService, 
    private authService: AuthService, 
    protected overlayService: OverlayService
  ) { }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.showMobileHeader = false;
  }

}
