import { Injectable } from '@angular/core';
import { Personality } from 'src/models/Personality';

@Injectable({
  providedIn: 'root'
})
export class CopyService {

  constructor() { }

  randomFromArray(array) {
    return array[Math.round(Math.random() * 100) % array.length];
  }

  randomCostSearchHeader(personality) {
    if (personality == Personality.Pompous) {
      return this.randomFromArray([
        'What quantity do you intend to pay for this embarcation?'
      ])
    } else if (personality == Personality.Professional) {
      return this.randomFromArray([
        'What\'s your budget?'
      ])
    } else if (personality == Personality.Salty) {
      return this.randomFromArray([
        'what you tryna pay?'
      ])
    }
  }

  randomCostSearchBudgetText(personality) {
    if (personality == Personality.Pompous) {
      return this.randomFromArray([
        'The sum you wish to divulge:'
      ])
    } else if (personality == Personality.Professional) {
      return this.randomFromArray([
        'Budget:'
      ])
    } else if (personality == Personality.Salty) {
      return this.randomFromArray([
        'how cheap is you:'
      ])
    }
  }

  randomCostSearchCTA(personality) {
    if (personality == Personality.Pompous) {
      return this.randomFromArray([
        'Execute search functionality',
        'Proceed'
      ])
    } else if (personality == Personality.Professional) {
      return this.randomFromArray([
        'Go'
      ])
    } else if (personality == Personality.Salty) {
      return this.randomFromArray([
        'Go already',
        'Move it!'
      ])
    }
  }

  randomCostSearchFilterCTA(personality) {
    if (personality == Personality.Pompous) {
      return this.randomFromArray([
        'Parameterize voyage'
      ])
    } else if (personality == Personality.Professional) {
      return this.randomFromArray([
        'Filter'
      ])
    } else if (personality == Personality.Salty) {
      return this.randomFromArray([
        'filter'
      ])
    }
  }

  randomCostSearchSortPrice(personality) {
    if (personality == Personality.Pompous) {
      return this.randomFromArray([
        'Cascade results according to monetary imposition'
      ])
    } else if (personality == Personality.Professional) {
      return this.randomFromArray([
        'Sort by price'
      ])
    } else if (personality == Personality.Salty) {
      return this.randomFromArray([
        'Man why isn\'t this sorted by price'
      ])
    }
  }

  randomCostSearchShuffle(personality) {
    if (personality == Personality.Pompous) {
      return this.randomFromArray([
        'I seek befuddlement as regards desintations, Flabbergast me with randomized destinations'
      ])
    } else if (personality == Personality.Professional) {
      return this.randomFromArray([
        'Surprise me'
      ])
    } else if (personality == Personality.Salty) {
      return this.randomFromArray([
        'I wanna see something new',
        'Show me something different'
      ])
    }
  }
}
