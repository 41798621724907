import { Component, Input } from '@angular/core';
import { Itinerary } from 'src/models/Itinerary';
import { SharedApiService } from '../shared/shared-api.service';
import { SharedService } from '../shared/shared.service';
import { Maps } from 'src/models/Maps';
import { OverlayService } from '../overlay/overlay.service';

@Component({
  selector: 'app-itineraryprofileview',
  templateUrl: './itineraryprofileview.component.html',
  styleUrls: ['./itineraryprofileview.component.scss']
})
export class ItineraryprofileviewComponent {
  @Input() itinerary: Itinerary;
  @Input() index: number;
  totalPeople: number;
  itineraryPhoto: any;
  showItineraryShare: boolean;
  showItineraryShareIndex: number;
  friendEmail: string;
  friendName: string;
  itineraryMap: Maps;
  mapBin: string;
  userName: string;

  constructor(private sharedApiService: SharedApiService, private sharedService: SharedService, private overlayService: OverlayService) { }

  ngOnInit() {
    this.setTotalPeople();
    this.getPhotos();
    this.getUserName();
  }

  getPhotos() {
    this.sharedApiService.getPhotosForPlaceName(this.itinerary.Destinations[0].Place.Name)
    .subscribe(data => {
      this.itineraryPhoto= this.sharedService.randomPhoto(data);
    });

    this.sharedApiService.getMapForItineraryId(this.itinerary.Id)
    .subscribe(data => {
      if (data.length > 0) {
        this.itineraryMap = data[0];
        this.mapBin = this.itineraryMap.MapBinary['bin'];
      }
    })
  }

  getUserName() {

  }

  showItineraryShareModal(index) {
    this.showItineraryShare = true;
    this.showItineraryShareIndex = index;
    this.overlayService.open('itinerary-share');
  }

  setTotalPeople() {
    this.totalPeople = 1;
    if (this.itinerary.SharedWithEmailsEdit) {
      this.totalPeople += this.itinerary.SharedWithEmailsEdit.length;
    }
    if (this.itinerary.SharedWithEmailsView) {
      this.totalPeople += this.itinerary.SharedWithEmailsView.length;
    }
  }

  shareItinerary() {
    this.sharedApiService.shareItinerary(this.itinerary.Id, this.friendName, this.friendEmail).subscribe( () => {
      // need to show a success message
      this.showItineraryShare = false;
      window.location.reload();
    });
  }

}
