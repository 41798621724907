    <!-- 
      The `defer` attribute causes the callback to execute after the full HTML
      document has been parsed. For non-blocking uses, avoiding race conditions,
      and consistent behavior across browsers, consider loading using Promises.
      See https://developers.google.com/maps/documentation/javascript/load-maps-js-api
      for more information.
      -->
    <!-- <script
      src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg&callback=initMap&v=weekly"
      defer
    ></script> -->

    <!-- <google-map (mapClick)="click($event)">
            <map-marker
            *ngFor="let marker of markers"
            [position]="marker.position"
            [label]="marker.label"
            [title]="marker.title"
            [options]="marker.options"
          >
          </map-marker>
          <map-info-window>{{ infoContent }}</map-info-window>
    </google-map> -->


    <div class="map">
        <google-map height="80vh"
                    width="50%"
                    [options]="mapOptions"
                    (mapInitialized)="handleMapInitialized($event)">
        </google-map>
    </div>