import { Component, OnInit, Input } from '@angular/core';
import { Trip } from 'src/models/Trip';
import { SharedApiService } from '../shared/shared-api.service';
import { SharedService } from '../shared/shared.service';
import { Personality } from 'src/models/Personality';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() trip: Trip;
  @Input() includeLuxury: boolean;
  showChecked: boolean;
  showCredit: boolean;
  personality: Personality;
  intro: string;

  constructor(private sharedApiService: SharedApiService, private sharedService: SharedService) { }

  ngOnInit() {
    this.showChecked = false;
    this.showCredit = false;

    if (this.trip) {
      this.sharedApiService.getPhotosForPlaceName(this.trip.Place)
      .subscribe(data => {
        this.trip.Photo = this.sharedService.randomPhoto(data);
      });
    }

    this.subscribePersonality();
  }

  public get personalityType(): typeof Personality {
    return Personality; 
  }

  subscribePersonality() {
    this.sharedService.personality$.subscribe(p => {
      this.personality = p;
      if (this.personality == Personality.Pompous) {
        this.intro = this.randomIntroPompous();
      } else if (this.personality == Personality.Professional) {
        this.intro = this.randomIntroProfessional();
      } else if (this.personality == Personality.Salty) {
        this.intro = this.randomIntroSalty();
      } else {
        this.intro = this.randomIntroProfessional();
      }
    })
  }

  randomIntroPompous() {
    let pompous = [
      'Embark upon an expedition to',
      'Powder your nose in the storied location of',
      'Mosey on down to'
    ];
    return pompous[Math.round(Math.random() * 100) % pompous.length];
  }

  randomIntroProfessional() {
    return 'Set off to';
  }

  randomIntroSalty() {
    let salty = [
      'Queen get yo ass to', 
      'slither outta', 
      'scurry on down to', 
      'See yo pretty little self off to',
      'slide ova to'
    ];
    return salty[Math.round(Math.random() * 100) % salty.length];
  }

}
