import { Component, OnInit, Input } from '@angular/core';
import { Trip } from 'src/models/Trip';
import { TripdetailService } from './tripdetail.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { SharedApiService } from '../shared/shared-api.service';
import { SharedService } from '../shared/shared.service';
import { FlightOffer } from 'src/models/FlightOffer';
import { Airport } from 'src/models/Airport';
import { Typeahead } from 'src/models/Typeahead';
import { OverlayService } from '../overlay/overlay.service';
import { Maps } from 'src/models/Maps';

@Component({
  selector: 'app-tripdetail',
  templateUrl: './tripdetail.component.html',
  styleUrls: ['./tripdetail.component.scss']
})
export class TripdetailComponent implements OnInit {
  trip: Trip;
  perPersonPerDay: number;
  htmlPhoto: string;
  photographer: string;
  links: string;
  showCredit: boolean;
  numPeople: number;
  numDays: number;
  isFilled: boolean;
  hovered: boolean;
  showMap: boolean;
  showChecked: boolean;
  showAirport: boolean;
  tripAirport: Airport;
  userAirport: Airport;
  flightOffers: FlightOffer[];
  flightCost: string;
  showLoadingIcon: boolean;
  departureDate: string;
  returnDate: string;
  numberOfPeeps: number;
  iataports: Typeahead[];
  airport: string;
  showError: boolean;
  flagIsFilled: boolean;
  userIsAdmin: boolean;
  maps: Maps[]
  mapBin: string;


  constructor(
    private tripdetailService: TripdetailService, 
    private router: Router, 
    private sharedApiService: SharedApiService, 
    private authService: AuthService, 
    private sharedService: SharedService,
    private overlayService: OverlayService
  ) {}

  ngOnInit() {
    this.showMap = false;
    this.hovered = false;
    this.showChecked = false;
    this.showLoadingIcon = false;
  
    this.userIsAdmin = false;
    this.mapBin = null;
    if (this.router.url.includes('aiTrip')) {
      this.tripdetailService.getTripAi(this.router.url.split('/').pop())
      .subscribe((t: Trip) => {
        this.trip = t;
        this.finishInit();
      })
    } else {
      this.tripdetailService.getTrip(this.router.url.split('/').pop())
      .subscribe((t: Trip) => {
        this.trip = t;
        this.finishInit();
      })
    }
  }

  finishInit() {
    this.perPersonPerDay = this.getPerPersonPerDay();
    if (this.trip.Airports && this.trip.Airports.length > 0) {
      this.tripAirport = this.trip.Airports[0];
    }
    this.getMaps();
    if (this.authService.isLoggedIn()) {
      this.sharedApiService.getIsFilled(this.trip.Id, true).subscribe(
        (u: boolean) => {
          this.isFilled = u;
        }
      )
      this.sharedApiService.getUserIsAdmin().subscribe((d) => {
        this.userIsAdmin = d;
        this.sharedApiService.getMapIsFlagged(this.trip.Id).subscribe((bool) => {
          this.flagIsFilled = bool;
        })
      })
    }
  }

  getMaps(): void {
    this.tripdetailService.getMaps(this.trip.Place)
      .subscribe((t: Maps[]) => {
        this.maps = t;
        this.mapBin = this.maps[0].MapBinary['bin'];
      });
  }

  cleanCost(cost: string): number {
    if (cost[0] == '$') {
      return parseFloat(cost.slice(1));
    }
    return parseFloat(cost);
  }

  getPerPersonPerDay(): number {
    let cost = this.cleanCost(this.trip.Cost);
    let dangos = parseInt(this.trip.NumberOfPeople);
    if (dangos > 0 && parseInt(this.sharedService.convertDurationToDays(this.trip.Duration)) > 0) {
      return cost / dangos / parseInt(this.sharedService.convertDurationToDays(this.trip.Duration));
    }
    return 0;
  }

  heart() {
    if (this.authService.isLoggedIn()) {
      if (this.isHearted()) {
        this.unRegisterHeart();
      } else {
        this.registerHeart();
      }
    } else {
      this.overlayService.open("login");
    }
  }

  unRegisterHeart() {
    this.isFilled = false;
    this.sharedApiService.registerHeart(this.trip.Id, true).subscribe();
  }

  registerHeart() {
    this.isFilled = true;
    this.sharedApiService.registerHeart(this.trip.Id, true).subscribe();
  }

  isHearted() {
    return this.isFilled;
  }

  toggleFlag() {
    this.flagIsFilled = !this.flagIsFilled;
    this.tripdetailService.assignFlag(this.maps[0].Id, this.flagIsFilled).subscribe();
  }
}
