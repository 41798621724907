<div class="Safety-fullRow">
    About this section

    <div>
        Getting data on safety is hard because of gaps in reporting. Safety matters to everyone, but often matters to women more. Data about women 
        is often harder to come by. This book, <a href="https://www.penguin.co.uk/books/435554/invisible-women-by-caroline-criado-perez/9781784706289">Invisible Women</a>, does a great job talking about the data gap between men and women.
    </div>

    <div>
        Here are the data sources that influenced the safety scores assigned to the places on this website. Data is aggregated at the country level, which is one limitation of the data.
    </div>
</div>