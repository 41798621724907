import { Injectable } from '@angular/core';
import { Trip } from '../../models/Trip';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreateService {

  constructor(private http: HttpClient) { }

  addByUser(t: Trip): Observable<Trip[]> {
    return this.http.post<Trip[]>('/api/internalTrips/addByUser', t)
      .pipe(
        retry(2)
      )
  }

  submitLink(t: string): Observable<string> {
    return this.http.post<string>('/api/internalTrips/addLinkByUser', {Link: t})
      .pipe(
        retry(2)
      )
  }

  // We probably need to submit the data as a buffer with content type string
  submitSheet(t: File): Observable<string> {
    return this.http.post<string>('/api/internalTrips/addSheetByUser', {Sheet: t})
      .pipe(
        retry(2)
      )
  }
}
