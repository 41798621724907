<section *ngFor="let luxury of luxuries; let i = index">
    <div class="Placard" [ngStyle]="{'background-image': photos[i] ? 'url(' + photos[i] + ')' : 'url( ' + place.ImageBlob + ')', 'background-size': 'cover'}">
        <div class="Placard-heading--right Placard-heading--{{ luxury }}">
            <h2>
                {{ luxury }}
            </h2>
        </div>
        <div class="Placard-content">
            <div>
                <div class="Placard-middle">
                    <div class="Placard-name">
                        {{ place.Name | titlecase }}
                    </div>
                </div>
                <div class="Placard-bottom">
                    <div class="Placard-cost--perDay">
                        <div>
                            {{ costs[i] | number: '1.2-2' }}
                            <span class="Placard-cost--perDaySpan" (mouseover)="hovered = true" (mouseout)="hovered = false">/ pppd </span> 
                            <div class="Placard-tooltip" [ngClass]="hovered == true ? 
                            'Placard-tooltip--displayed' : ''">
                                <span class="Placard-cost--hover">per person per day</span>
                            </div>
                        </div>
                    </div>
                    <div class="Placard-heartWrapper" (click)="heart()">
                        <img class="Placard-heart" src="assets/heart-thin-svgrepo-com.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>