import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Place } from '../../models/Place';

import { retry } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class CostSearchService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  getPlaces(): Observable<Place[]> {
    return this.http.get<Place[]>('/api/places')
      .pipe(
        retry(2)
      )
  }

  sendSearch(cost: string): Observable<any> {
    return this.http.post('/api/places/sendSearch', {Cost: cost});
  }
}
