import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Transit } from 'src/models/Transit';
import { Destination, Luxury } from 'src/models/Itinerary';
import { trigger, transition, animate, style } from '@angular/animations';
import { SharedService } from '../shared/shared.service';
import { SharedApiService } from '../shared/shared-api.service';
import { AuthService } from '../auth.service';
import { CountryCodes } from '../countryCodes';


@Component({
  selector: 'app-itinerarycard',
  templateUrl: './itinerarycard.component.html',
  styleUrls: ['./itinerarycard.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({height: 0, marginTop: 0, opacity: 0}),
        animate('200ms ease-out', style({height: '123px', marginTop: '20px', opacity: 1}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({height: 0, marginTop: 0, opacity: 0}))
      ])
    ])
  ]
})
export class ItinerarycardComponent implements OnInit {
  @Input() PreviousDestination: Destination;
  @Input() TransitFromPreviousPlace: Transit;
  @Input() destination: Destination;
  @Input() destinations: Destination[];
  @Input() transits: Transit[];

  rotateChevron: boolean
  totalCost: number;
  currentLuxury: string;
  notes: string;
  visaStatus: string;
  visaMessage: string;
  showTotal: boolean;
  mapCode: string;

  constructor(private sharedService: SharedService, private sharedApiService: SharedApiService, private authService: AuthService) { }

  ngOnInit() {
    this.rotateChevron = false;
    this.notes = "";
    this.visaStatus = "";
    this.visaMessage = "";
    this.getVisaStatus();
    this.showTotal = true;
    this.mapCode = "";
    this.getMapCode();
  }

  rotate() {
    this.rotateChevron = !this.rotateChevron;
  }

  getLuxuryTypes() {
    return Luxury;
  }

  handleMinus(target: string) {
    if (target == 'days') {
      if (this.destination.NumberOfDays > 1) {
        this.destination.NumberOfDays -= 1;
      };
    } else if (target == 'people') {
      if (this.destination.NumberOfPeople > 1) {
        this.destination.NumberOfPeople -= 1;
      };
    }
    this.updateCost();
  }

  handlePlus(target : string) {
    if (target == 'days') {
      this.destination.NumberOfDays += 1;
    } else if (target == 'people') {
      this.destination.NumberOfPeople += 1;
    }
    this.updateCost();
  }

  getTotalCost(): number {
    return this.destination.NumberOfPeople * this.destination.NumberOfDays * this.getCurrentLuxuryAverageCost();
  }

  setLuxury(luxury: Luxury) {
    this.destination.Luxury = luxury
    this.updateCost();
  }

  updateCost() {
    this.destination.Cost = this.getTotalCost();
  }
  
  getCurrentLuxuryAverageCostNoAi() {
    switch (this.destination.Luxury) {
      case Luxury.Budget:
        return this.destination.Place.BudgetAverageCost;
      case Luxury.MidRange:
        return this.destination.Place.MidRangeAverageCost;
      case Luxury.Luxury:
        return this.destination.Place.LuxuryAverageCost;
    }
    return 1;
  }
  
  getCurrentLuxuryAverageCostWithAi() {
    switch (this.destination.Luxury) {
      case Luxury.Budget:
        return this.destination.Place.BudgetAverageCostAi;
      case Luxury.MidRange:
        return this.destination.Place.MidRangeAverageCostAi;
      case Luxury.Luxury:
        return this.destination.Place.LuxuryAverageCostAi;
    }
    return 1;
  }

  getWeightedLuxuryAverageCost() {
    switch (this.destination.Luxury) {
      case Luxury.Budget:
        return this.destination.Place.WeightedBudgetCost;
      case Luxury.MidRange:
        return this.destination.Place.WeightedMidRangeCost;
      case Luxury.Luxury:
        return this.destination.Place.WeightedLuxuryCost;
    }
    return 1;
  }

  getCurrentLuxuryAverageCost() {
    switch (this.destination.Luxury) {
      case Luxury.Budget:
        // return this.destination.Place.BudgetAverageCost;
        return this.destination.Place.WeightedBudgetCost;
      case Luxury.MidRange:
        // return this.destination.Place.MidRangeAverageCost;
        return this.destination.Place.WeightedMidRangeCost;
      case Luxury.Luxury:
        // return this.destination.Place.LuxuryAverageCost;
        return this.destination.Place.WeightedLuxuryCost;
    }
    return 1;
  }

  calculateAveragePriceDifference(price: number) {
    let difference = price - this.getCurrentLuxuryAverageCost();
    if (difference > 0) {
      return "+$" + difference.toFixed(2);
    } else {
      return "-$" + (-1 * difference).toFixed(2)
    }
  }

  getVisaStatus() {
    if (this.authService.isLoggedIn()) {
      this.sharedApiService.getPassportCountry().subscribe(passportString => {
        this.sharedApiService.getPassport(passportString).subscribe(passport => {
          this.visaStatus = this.sharedService.processPassportColor(passport[0], this.destination.Place.Country);
          this.setVisaMessage();
        })
      })
    } else {
      this.sharedApiService.getPassport("USA").subscribe(passport => {
        this.visaStatus = this.sharedService.processPassportColor(passport[0], this.destination.Place.Country);
        this.setVisaMessage();
      })
    }    
  }

  setVisaMessage() {
    if (this.visaStatus == "green") {
      this.visaMessage = " does not require a visa!"
    } else if (this.visaStatus == "light-green") { 
      this.visaMessage = " gives you a visa on arrival"
    } else if (this.visaStatus == "yellow") {
      this.visaMessage = " requires an eVisa, apply before you leave"
    } else if (this.visaStatus == "red") {
      this.visaMessage = " requires a visa"
    } else {
      this.visaMessage = " visa status unknown :("
    }
  }

  getMapCode() {
    for (let key in CountryCodes) {
      if (CountryCodes[key] == this.destination.Place.Country) {
        this.mapCode = key;
      }
    }
  }

  remove() {
    let index = this.destinations.indexOf(this.destination);
    this.destinations = this.destinations.splice(index, 1);
    this.transits = this.transits.splice(index, 1);
  }
}
