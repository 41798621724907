import { Component, Input } from '@angular/core';
import { CountryDetail } from 'src/models/Passport';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'app-visas',
  templateUrl: './visas.component.html',
  styleUrls: ['./visas.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({'max-height': '0px'}),
        animate('350ms ease-in', style({'max-height': '1000px'}))
      ])
    ])
  ]
})
export class VisasComponent {
  @Input() visa: CountryDetail;
  @Input() visaColor: string;
  rotateChevron: boolean


  constructor() {}

  ngOnInit() {
    this.visa.Notes = this.visa.Notes.trim();
    this.rotateChevron = false;
  }

  rotate() {
    this.rotateChevron = !this.rotateChevron;
  }

}
