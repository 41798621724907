import * as moment from 'moment';

import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { User } from '../models/User';
import { retry, catchError, tap, shareReplay, share, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(email:string, password:string ) {
    return this.http.post<User>('/login', {email, password})
        .pipe(
          tap(res => this.setLocalStorage(res)),
          shareReplay()
        )
  }

  // Edit this to handle errors for already existing users
  register(FirstName:string, LastName:string, Email:string, Password:string) {
    return this.http.post<User>('/register', {FirstName, LastName, Email, Password})
      .pipe(
        retry(2)
      )
  }

  /**
   *
   */
  private setLocalStorage(authResult) {
    // Takes the JWT expiresIn value and add that number of seconds
    // to the current "moment" in time to get an expiry date
    const expiresAt = moment().add(authResult.expiresIn, 'days');
    
    // Stores our JWT token and its expiry date in localStorage
    localStorage.setItem('id_token', authResult.token);
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
  }

  // By removing the token from localStorage, we have essentially "lost" our
  // JWT in space and will need to re-authenticate with the Express app to get
  // another one.
  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }
}
