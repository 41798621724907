import { Injectable } from '@angular/core';
import { Passport, CountryDetail } from 'src/models/Passport';
import { Trip } from 'src/models/Trip';
import { BehaviorSubject, Observable } from 'rxjs';
import { Photos } from 'src/models/Photo';
import { Place } from 'src/models/Place';
import { AuthService } from '../auth.service';
import { CostedTrip } from 'src/models/CostedTrip';
import { HttpClient } from '@angular/common/http';
import { retry, map } from 'rxjs/operators';
import { Personality } from 'src/models/Personality';

// TODO, this should be done through a call to the db. Stop being lazy
const SupportedPassports = [
  "Australia",
  "Canada",
  "Ireland",
  "New Zealand",
  "United Kingdom",
  "USA"
]

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private _visaVR: BehaviorSubject<boolean>;
  visaVR$: Observable<boolean>;
  private _visaVOA: BehaviorSubject<boolean>;
  visaVOA$: Observable<boolean>;
  private _visaVF: BehaviorSubject<boolean>;
  visaVF$: Observable<boolean>;
  private _visaVE: BehaviorSubject<boolean>;
  visaVE$: Observable<boolean>;
  private _numberOfPeople: BehaviorSubject<number>;
  numberOfPeople$: Observable<number>;
  private _useDays: BehaviorSubject<boolean>;
  useDays$: Observable<boolean>;
  private _numberOfDays: BehaviorSubject<number>;
  numberOfDays$: Observable<number>;
  private _lowDays: BehaviorSubject<number>;
  lowDays$: Observable<number>;
  private _highDays: BehaviorSubject<number>;
  highDays$: Observable<number>;
  private _budgetFilter: BehaviorSubject<boolean>;
  budgetFilter$: Observable<boolean>;
  private _midRangeFilter: BehaviorSubject<boolean>;
  midRangeFilter$: Observable<boolean>;
  private _luxuryFilter: BehaviorSubject<boolean>;
  luxuryFilter$: Observable<boolean>;
  private _northAmerica: BehaviorSubject<boolean>;
  northAmerica$: Observable<boolean>;
  private _southAmerica: BehaviorSubject<boolean>;
  southAmerica$: Observable<boolean>;
  private _europe: BehaviorSubject<boolean>;
  europe$: Observable<boolean>;
  private _africa: BehaviorSubject<boolean>;
  africa$: Observable<boolean>;
  private _asia: BehaviorSubject<boolean>;
  asia$: Observable<boolean>;
  private _oceania: BehaviorSubject<boolean>;
  oceania$: Observable<boolean>;
  private _domestic: BehaviorSubject<boolean>;
  domestic$: Observable<boolean>;
  private _aiAllowed: BehaviorSubject<boolean>;
  aiAllowed$: Observable<boolean>;
  private _passingTrips: BehaviorSubject<CostedTrip[]>;
  passingTrips$: Observable<CostedTrip[]>;
  private _passingPlaces: BehaviorSubject<Place[]>;
  passingPlaces$: Observable<Place[]>;
  private _personality: BehaviorSubject<Personality>;
  personality$: Observable<Personality>;
  
  randomCost: number;
  places: Place[];
  allTrips: CostedTrip[];
  userPassport: string;
  passportObject: Passport;


  constructor(private authService: AuthService, private http: HttpClient) { 

    if (localStorage.getItem("northAmerica") != null) {
      this._northAmerica = new BehaviorSubject(localStorage.getItem("northAmerica") == "true") as BehaviorSubject<boolean>;
    } else {
      this._northAmerica = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.northAmerica$ = this._northAmerica.asObservable();

    if (localStorage.getItem("southAmerica") != null) {
      this._southAmerica = new BehaviorSubject(localStorage.getItem("southAmerica") == "true") as BehaviorSubject<boolean>;
    } else {
      this._southAmerica = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.southAmerica$ = this._southAmerica.asObservable();

    if (localStorage.getItem("europe") != null) {
      this._europe = new BehaviorSubject(localStorage.getItem("europe") == "true") as BehaviorSubject<boolean>;
    } else {
      this._europe = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.europe$ = this._europe.asObservable();

    if (localStorage.getItem("africa") != null) {
      this._africa = new BehaviorSubject(localStorage.getItem("africa") == "true") as BehaviorSubject<boolean>;
    } else {
      this._africa = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.africa$ = this._africa.asObservable();

    if (localStorage.getItem("asia") != null) {
      this._asia = new BehaviorSubject(localStorage.getItem("asia") == "true") as BehaviorSubject<boolean>;
    } else {
      this._asia = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.asia$ = this._asia.asObservable();

    if (localStorage.getItem("oceania") != null) {
      this._oceania = new BehaviorSubject(localStorage.getItem("oceania") == "true") as BehaviorSubject<boolean>;
    } else {
      this._oceania = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.oceania$ = this._oceania.asObservable();

    if (localStorage.getItem("domestic") != null) {
      this._domestic = new BehaviorSubject(localStorage.getItem("domestic") == "true") as BehaviorSubject<boolean>;
    } else {
      this._domestic = new BehaviorSubject(false) as BehaviorSubject<boolean>;
    }
    this.domestic$ = this._domestic.asObservable();

    if (localStorage.getItem("aiAllowed") != null) {
      this._aiAllowed = new BehaviorSubject(localStorage.getItem("aiAllowed") == "true") as BehaviorSubject<boolean>;
    } else {
      this._aiAllowed = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.aiAllowed$ = this._aiAllowed.asObservable();

    if (localStorage.getItem("visaVR") != null) {
      this._visaVR = new BehaviorSubject(localStorage.getItem("visaVR") == "true") as BehaviorSubject<boolean>;
    } else {
      this._visaVR = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.visaVR$ = this._visaVR.asObservable();
    
    if (localStorage.getItem("visaVOA") != null) {
      this._visaVOA = new BehaviorSubject(localStorage.getItem("visaVOA") == "true") as BehaviorSubject<boolean>;
    } else {
      this._visaVOA = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.visaVOA$ = this._visaVOA.asObservable();
    
    if (localStorage.getItem("visaVF") != null) {
      this._visaVF = new BehaviorSubject(localStorage.getItem("visaVF") == "true") as BehaviorSubject<boolean>;
    } else {
      this._visaVF = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.visaVF$ = this._visaVF.asObservable();
    
    if (localStorage.getItem("visaVE") != null) {
      this._visaVE = new BehaviorSubject(localStorage.getItem("visaVE") == "true") as BehaviorSubject<boolean>;
    } else {
      this._visaVE = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.visaVE$ = this._visaVE.asObservable();

    if (localStorage.getItem("numberOfPeople") != null) {
      this._numberOfPeople = new BehaviorSubject(parseInt(localStorage.getItem("numberOfPeople"))) as BehaviorSubject<number>;
    } else {
      this._numberOfPeople = new BehaviorSubject(1) as BehaviorSubject<number>;
    }
    this.numberOfPeople$ = this._numberOfPeople.asObservable();

    if (localStorage.getItem("useDays") != null) {
      this._useDays = new BehaviorSubject(localStorage.getItem("useDays") == "true") as BehaviorSubject<boolean>;
    } else {
      this._useDays = new BehaviorSubject(false) as BehaviorSubject<boolean>;
    }
    this.useDays$ = this._useDays.asObservable();

    if (localStorage.getItem("numberOfDays") != null) {
      this._numberOfDays = new BehaviorSubject(parseInt(localStorage.getItem("numberOfDays"))) as BehaviorSubject<number>;  
    } else {
      this._numberOfDays = new BehaviorSubject(-1) as BehaviorSubject<number>;
    }
    this.numberOfDays$ = this._numberOfDays.asObservable();
    
    if (localStorage.getItem("lowDays") != null) {
      this._lowDays = new BehaviorSubject(parseInt(localStorage.getItem("lowDays"))) as BehaviorSubject<number>;  
    } else {
      this._lowDays = new BehaviorSubject(-1) as BehaviorSubject<number>;
    }
    this.lowDays$ = this._lowDays.asObservable();
    
    if (localStorage.getItem("highDays") != null) {
      this._highDays = new BehaviorSubject(parseInt(localStorage.getItem("highDays"))) as BehaviorSubject<number>;  
    } else {
      this._highDays = new BehaviorSubject(999999) as BehaviorSubject<number>;
    }
    this.highDays$ = this._highDays.asObservable();

    if (localStorage.getItem("budgetFilter") != null) {
      this._budgetFilter = new BehaviorSubject(localStorage.getItem("budgetFilter") == "true") as BehaviorSubject<boolean>;
    } else {
      this._budgetFilter = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.budgetFilter$ = this._budgetFilter.asObservable();

    if (localStorage.getItem("midRangeFilter") != null) {
      this._midRangeFilter = new BehaviorSubject(localStorage.getItem("midRangeFilter") == "true") as BehaviorSubject<boolean>;
    } else {
      this._midRangeFilter = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.midRangeFilter$ = this._midRangeFilter.asObservable();

    if (localStorage.getItem("luxuryFilter") != null) {
      this._luxuryFilter = new BehaviorSubject(localStorage.getItem("luxuryFilter") == "true") as BehaviorSubject<boolean>;
    } else {
      this._luxuryFilter = new BehaviorSubject(true) as BehaviorSubject<boolean>;
    }
    this.luxuryFilter$ = this._luxuryFilter.asObservable();

    if (localStorage.getItem("randomCost") != null) {
      this.randomCost = parseFloat(localStorage.getItem("randomCost"));
    } else {
      // this.randomCost = Math.max(Math.floor(Math.random()*2500), 500);
      this.randomCost = 0;
      // localStorage.setItem("randomCost", this.randomCost.toString());
    }

    this._passingTrips = new BehaviorSubject([]) as BehaviorSubject<CostedTrip[]>
    this.passingTrips$ = this._passingTrips.asObservable();
    this._passingPlaces = new BehaviorSubject([]) as BehaviorSubject<Place[]>
    this.passingPlaces$ = this._passingPlaces.asObservable();


    if (this.authService.isLoggedIn()) {
      this.getPassportCountry().subscribe((t: string) => {
        this.userPassport = t;
        if (this.userPassport == "" || this.userPassport == null) {
          this.getPassport("USA").subscribe((u: Passport) => {
            this.userPassport = "USA";
            this.passportObject = u[0];
            this.getAllPlaces().subscribe(data => {
              this.places = data;
              this._passingTrips.next(this.getPassingCostedTrips(data, this.randomCost, this.passportObject, this.userPassport));
              this._passingPlaces.next(this.getPassingPlaces(data, this.randomCost, this.passportObject, this.userPassport));
            });
          });
        } else {
          this.getPassport(this.userPassport).subscribe((u: Passport) => {
            this.passportObject = u[0];
            this.getAllPlaces().subscribe(data => {
              this.places = data;
              this._passingTrips.next(this.getPassingCostedTrips(data, this.randomCost, this.passportObject, this.userPassport));
              this._passingPlaces.next(this.getPassingPlaces(data, this.randomCost, this.passportObject, this.userPassport));
            });
          });
        }
      });
    } else {
      this.userPassport = "USA";
      this.getPassport("USA").subscribe((u: Passport) => {
        this.passportObject = u[0];
        this.getAllPlaces().subscribe(data => {
          this.places = data;
          this._passingTrips.next(this.getPassingCostedTrips(data, this.randomCost, this.passportObject, this.userPassport));
          this._passingPlaces.next(this.getPassingPlaces(data, this.randomCost, this.passportObject, this.userPassport));
        });
      });
    }

    this._personality = new BehaviorSubject(Personality.Professional) as BehaviorSubject<Personality>;
    this.personality$ = this._personality.asObservable();

    if (authService.isLoggedIn()) {
      this.getPersonality().subscribe(personality => {
        if (personality == null) {
          this._personality.next(Personality.Professional)
        } else {
          this._personality.next(personality);
        }
      })
    }
  }

  northAmerica(continent: boolean) {
    localStorage.setItem("northAmerica", continent ? 'true' : 'false');
    this._northAmerica.next(continent);
  }

  southAmerica(continent: boolean) {
    localStorage.setItem("southAmerica", continent ? 'true' : 'false');
    this._southAmerica.next(continent);
  }

  europe(continent: boolean) {
    localStorage.setItem("europe", continent ? 'true' : 'false');
    this._europe.next(continent);
  }

  africa(continent: boolean) {
    localStorage.setItem("africa", continent ? 'true' : 'false');
    this._africa.next(continent);
  }

  asia(continent: boolean) {
    localStorage.setItem("asia", continent ? 'true' : 'false');
    this._asia.next(continent);
  }

  oceania(continent: boolean) {
    localStorage.setItem("oceania", continent ? 'true' : 'false');
    this._oceania.next(continent);
  }

  domestic(allowed: boolean) {
    localStorage.setItem("domestic", allowed ? 'true' : 'false');
    this._domestic.next(allowed);
  }

  aiAllowed(bool: boolean) {
    localStorage.setItem("aiAllowed", bool ? 'true' : 'false');
    this._aiAllowed.next(bool);
  }

  visaVR(visa: boolean) {
    localStorage.setItem("visaVR", visa ? 'true' : 'false');
    this._visaVR.next(visa);
  }
  
  visaVF(visa: boolean) {
    localStorage.setItem("visaVF", visa ? 'true' : 'false');
    this._visaVF.next(visa);
  }
  
  visaVE(visa: boolean) {
    localStorage.setItem("visaVE", visa ? 'true' : 'false');
    this._visaVE.next(visa);
  }
  
  visaVOA(visa: boolean) {
    localStorage.setItem("visaVOA", visa ? 'true' : 'false');
    this._visaVOA.next(visa);
  }

  numberOfPeople(people: number) {
    localStorage.setItem("numberOfPeople", people.toString());
    this._numberOfPeople.next(people);
  }

  useDays(bool: boolean) {
    localStorage.setItem("useDays", bool ? 'true': 'false');
    this._useDays.next(bool);
  }

  numberOfDays(days: number) {
    if (days != 0) {
      localStorage.setItem("numberOfDays", days.toString());
      this._numberOfDays.next(days);
    } else {
      localStorage.setItem("numberOfDays", "-1");
      this._numberOfDays.next(-1);
    }
  }
  
  // Also should check if low > high and then reverse them
  lowDays(days: number) {
    if (days != 0) {
      localStorage.setItem("lowDays", days.toString());
      this._lowDays.next(days);
    } else {
      localStorage.setItem("lowDays", "-1");
      this._lowDays.next(-1);
    }
  }

  // Also should check if high < low and then reverse them
  highDays(days: number) {
    if (days != 0) {
      localStorage.setItem("highDays", days.toString());
      this._highDays.next(days);  
    } else {
      localStorage.setItem("highDays", "999999");
      this._highDays.next(999999);
    }
  }

  budgetFilter(filter: boolean) {
    localStorage.setItem("budgetFilter", filter ? 'true' : 'false');
    this._budgetFilter.next(filter);
  }

  midRangeFilter(filter: boolean) {
    localStorage.setItem("midRangeFilter", filter ? 'true' : 'false');
    this._midRangeFilter.next(filter);
  }

  luxuryFilter(filter: boolean) {
    localStorage.setItem("luxuryFilter", filter ? 'true' : 'false');
    this._luxuryFilter.next(filter);
  }

  personality(p: Personality) {
    localStorage.setItem("personality", p.toString());
    this._personality.next(p);
  }

  getCountriesFromModel(listOfCountries: CountryDetail[]) {
    let final = [];
    listOfCountries.forEach(element => {
      final.push(element.Name);
    });
    return final;
  }

  processPassportColor(passport: Passport, country: string): string {
    if (this.getCountriesFromModel(passport.EVisaCountries).includes(country)) {
      return "yellow";
    } else if (this.getCountriesFromModel(passport.TravelRestrictedCountries).includes(country)) {
      return "red";
    } else if (this.getCountriesFromModel(passport.VisaFreeCountries).includes(country)) {
      return "green";
    } else if (this.getCountriesFromModel(passport.VisaOnArrivalCountries).includes(country)) {
      return "light-green";
    } else if (this.getCountriesFromModel(passport.VisaRequiredCountries).includes(country)) {
      return "red";
    } else {
      return "none";
    }
  }

  returnVisaDetailsForPassportAndCountry(passport: Passport, country: string): CountryDetail {
    if (this.getCountriesFromModel(passport.EVisaCountries).includes(country)) {
      return passport.EVisaCountries[this.getCountriesFromModel(passport.EVisaCountries).indexOf(country)];
    } else if (this.getCountriesFromModel(passport.TravelRestrictedCountries).includes(country)) {
      return passport.TravelRestrictedCountries[this.getCountriesFromModel(passport.TravelRestrictedCountries).indexOf(country)];
    } else if (this.getCountriesFromModel(passport.VisaFreeCountries).includes(country)) {
      return passport.VisaFreeCountries[this.getCountriesFromModel(passport.VisaFreeCountries).indexOf(country)];
    } else if (this.getCountriesFromModel(passport.VisaOnArrivalCountries).includes(country)) {
      return passport.VisaOnArrivalCountries[this.getCountriesFromModel(passport.VisaOnArrivalCountries).indexOf(country)];
    } else if (this.getCountriesFromModel(passport.VisaRequiredCountries).includes(country)) {
      return passport.VisaRequiredCountries[this.getCountriesFromModel(passport.VisaRequiredCountries).indexOf(country)];
    } else {
      return null;
    }
  }

  passesVisaFilter(passport: Passport, country: string, visaVR: boolean, visaVOA: boolean, visaVF: boolean, visaVE: boolean) {
    const color = this.processPassportColor(passport, country);
    if (color == "green" && visaVF) {
      return true;
    } else if (color == "light-green" && visaVOA) {
      return true;
    } else if (color == "yellow" && visaVE) {
      return true;
    } else if (color == "red" && visaVR) {
      return true;
    } else {
      return false;
    }
  }

  countryPassportInformationExists(country: string): boolean {
    return SupportedPassports.includes(country);
  }

  convertCostToNumber(cost: string) {
    if (cost == "" || cost == "$") {
      return 0;
    } else if (cost[0] == "$" && cost.length > 1) {
      return parseInt(cost.slice(1));
    } else if (parseInt(cost)) {
      return parseInt(cost);
    } else if (parseInt(cost.split[' '][0])) {
      return parseInt(cost.split[' '][0]);
    } else {
      return 0;
    }
  }

  // This won't work if someone puts in weeks
  convertDurationToDays(duration: string) {
    return(duration.split(" ")[0]);
  }

  createImageFromBlob(image: Blob, element: Trip) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      element.ImageBlob = reader.result;
    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
  }

  randomPhoto(photos: Photos[]) {
    let pictureIndex = Math.floor(Math.random() * photos.length);
    return photos[pictureIndex].Photo;
  }

  passesAllFilters(costedTrip: CostedTrip, passport: Passport, userPassport: string, luxury: string) {
    return this.passesPassport(passport, costedTrip.place, userPassport) && this.passesDays(costedTrip.days) && this.passesLuxury(luxury) && this.passesContinent(costedTrip.place) && this.passesDomestic(costedTrip.place);
  }

  passesAllFiltersPlace(place: Place, expense: number, passport: Passport, userPassport: string) {
    // need to combine passesDays and passesLuxury 
    return this.passesPassport(passport, place, userPassport) && this.passesAtLeastMinimumDaysAndLuxury(place, expense) && this.passesContinent(place) && this.passesDomestic(place);
  }

  getPassportColor(passport: Passport, place: Place, userPassport: string) {
    if (userPassport != "" && this.countryPassportInformationExists(userPassport)) {
      return this.processPassportColor(passport, place.Country);
    } else {
      return "none";
    }
  }

  passesPassport(passport: Passport, place: Place, userPassport: string) {
    // Need to implement a null check here and default to the usa passport
    let color = this.getPassportColor(passport, place, userPassport)
    if (this.authService.isLoggedIn() && this.countryPassportInformationExists(userPassport)) {
      return ((color == 'red' && this._visaVR.getValue()) || (color == 'green' && this._visaVF.getValue()) || 
      (color == 'yellow' && this._visaVE.getValue()) || (color == 'light-green' && this._visaVOA.getValue()) || (color == 'none'))
    } else {
      // TODO: This section changed to assume USA passport
      if (this.countryPassportInformationExists(userPassport)) {
        return ((color == 'red' && this._visaVR.getValue()) || (color == 'green' && this._visaVF.getValue()) || 
        (color == 'yellow' && this._visaVE.getValue()) || (color == 'light-green' && this._visaVOA.getValue()) || (color == 'none'))
      } else {
        return true;
      }
    }
  }

  // this includes a setter, will need to handle this locally as well
  passesDays(days: number) {
    if (this._useDays.getValue()) {
      if (days >= this._numberOfDays.getValue()) {
        // this.coercePlaceDaysToUpperBound(this._numberOfDays.getValue());
        // TODO make this coerce alter the passed element or call a setter to do it for you
      }
      
      return (days >= this._numberOfDays.getValue());
    } else {
      if (days > this._highDays.getValue()) {
        // this.coercePlaceDaysToUpperBound(this._highDays.getValue());
      }
      return (days >= this._lowDays.getValue())
    }
  }

  passesDaysPure(days: number, useDays: boolean, numberOfDays: number, lowDays: number) {
    if (useDays) {      
      return (days >= numberOfDays);
    } else {
      return (days >= lowDays);
    }
  }

  passesLuxury(luxury: string) {
    return ((luxury == 'Luxury' && this._luxuryFilter.getValue() == true) || (luxury == 'Mid-range' && this._midRangeFilter.getValue() == true) || 
    (luxury == 'Budget' && this._budgetFilter.getValue() == true))
  }

  passesAtLeastMinimumDaysAndLuxury(place: Place, expense: number) {

    if (this._luxuryFilter.getValue()) {
      // assess days for weightedCostLuxury
      if (place.WeightedLuxuryCost != 0) {
        if (expense <= 0) {
          return true;
        }
        let days = Math.floor(expense / place.WeightedLuxuryCost);
        if (this.passesDays(days)) {
          return true;
        }
      }
    }
    if (this._midRangeFilter.getValue()) {
      if (place.WeightedMidRangeCost != 0) {
        if (expense <= 0) {
          return true;
        }
        let days = Math.floor(expense / place.WeightedMidRangeCost);
        if (this.passesDays(days)) {
          return true;
        }
      }
    }
    if (this._budgetFilter.getValue()) {
      if (place.WeightedBudgetCost != 0) {
        if (expense <= 0) {
          return true;
        }
        let days = Math.floor(expense / place.WeightedBudgetCost);
        if (this.passesDays(days)) {
          return true;
        }
      }
    }

    return false;
  }

  passesContinent(place: Place) {
    if (place.Continent) {
      let continents = place.Continent;
      var ret = false;
      continents.forEach(element => {
        if (this._northAmerica.getValue() && element == 'North America') {
          ret = true;
        }
        if (this._southAmerica.getValue() && element == 'South America') {
          ret = true;
        }
        if (this._europe.getValue() && element == 'Europe') {
          ret = true;
        }
        if (this._africa.getValue() && element == 'Africa') {
          ret = true;
        }
        if (this._asia.getValue() && element == 'Asia') {
          ret = true;
        }
        if (this._oceania.getValue() && element == 'Oceania') {
          ret = true;
        }
      });
      return ret;
    }
    return true;
  }

  // TODO, don't just hardcode USA
  passesDomestic(place: Place) {
    if (!this._domestic.getValue() && place.Country == "United States") {
      return false;
    }
    return true
  }

  getPlaces(): Place[] {
    return this.places;
  }

  // want to deprecate this
  convertPlacesToCostedTrips(places: Place[], expense: number): CostedTrip[] {
    let costedTrips = this.createCostedTripList(places, expense);
    return this.cleanCostedTripsForBrokenDays(costedTrips);
  }

  // this might need to take costed trips
  getPassingCostedTrips(places: Place[], expense: number, passport: Passport, userPassport: string): CostedTrip[] {
    this.randomCost = expense;
    let costedTrips = this.convertPlacesToCostedTrips(places, expense);
    let finalCostedTrips = [];
    costedTrips.forEach(element => {
      if (this.passesAllFilters(element, passport, userPassport, element.luxury)) {
        finalCostedTrips.push(element);
      }
    });
    return finalCostedTrips;
  }

  setPassingTripsWithoutInputData() {
    let trips = this.getPassingCostedTrips(this.places, this.randomCost, this.passportObject, this.userPassport);
    this.setPassingTrips(trips);
  }

  setPassingTrips(costedTrips: CostedTrip[]) {
    this._passingTrips.next(costedTrips);
  }

  // What this function needs to do is verify that the places include at least one budget level that works with the expense
  getPassingPlaces(places: Place[], expense: number, passport: Passport, userPassport: string): Place[] {
    this.randomCost = expense;
    let finalPlaces = [];
    places.forEach(element => {
      if (this.passesAllFiltersPlace(element, expense, passport, userPassport)) {
        finalPlaces.push(element);
      }
    })
    return finalPlaces;
  }

  setPassingPlacesWithoutInputData() {
    let places = this.getPassingPlaces(this.places, this.randomCost, this.passportObject, this.userPassport);
    this.setPassingPlaces(places);
  }

  setPassingPlaces(passingPlaces: Place[]) {
    this._passingPlaces.next(passingPlaces);
  }

  createCostedTripListWithAi(places: Place[], expense: number) {
    let finalCostedTrips = [];
    places.forEach(element => {
      if (element.LuxuryAverageCostAi > 0 ) {
        let luxuryDays = Math.floor(expense / element.LuxuryAverageCostAi);
        var luxuryTrip: CostedTrip = {
          place: element,
          days: luxuryDays,
          luxury: "Luxury"
        }
        finalCostedTrips.push(luxuryTrip);
      } else {
        let luxuryDays = Math.floor(expense / element.LuxuryAverageCost);
        var luxuryTrip: CostedTrip = {
          place: element,
          days: luxuryDays,
          luxury: "Luxury"
        }
        finalCostedTrips.push(luxuryTrip);
      }
      
      if (element.MidRangeAverageCostAi > 0 ) {
        let midrangeDays = Math.floor(expense / element.MidRangeAverageCostAi);
        var midrangeTrip: CostedTrip = {
          place: element,
          days: midrangeDays,
          luxury: "Mid-range"
        } 
        finalCostedTrips.push(midrangeTrip);
      } else {
        let midrangeDays = Math.floor(expense / element.MidRangeAverageCost);
        var midrangeTrip: CostedTrip = {
          place: element,
          days: midrangeDays,
          luxury: "Mid-range"
        }
        finalCostedTrips.push(midrangeTrip);
      }
      
      if (element.BudgetAverageCostAi > 0 ) {
        let budgetDays = Math.floor(expense / element.BudgetAverageCostAi);
        var budgetTrip: CostedTrip = {
          place: element,
          days: budgetDays,
          luxury: "Budget"
        }
        // this.applyLengths(budgetTrip);
        finalCostedTrips.push(budgetTrip);
      } else {
        let budgetDays = Math.floor(expense / element.BudgetAverageCost);
        var budgetTrip: CostedTrip = {
          place: element,
          days: budgetDays,
          luxury: "Budget"
        }
        // this.applyLengths(budgetTrip);
        finalCostedTrips.push(budgetTrip);
      }
      
    });
    return finalCostedTrips;
  }

  createCostedTripListWithoutAi(places: Place[], expense: number) {
    let finalCostedTrips = []
    places.forEach(element => {
      let luxuryDays = Math.floor(expense / element.LuxuryAverageCost);
      var luxuryTrip: CostedTrip = {
        place: element,
        days: luxuryDays,
        luxury: "Luxury"
      }
      finalCostedTrips.push(luxuryTrip);
      let midrangeDays = Math.floor(expense / element.MidRangeAverageCost);
      var midrangeTrip: CostedTrip = {
        place: element,
        days: midrangeDays,
        luxury: "Mid-range"
      }
      finalCostedTrips.push(midrangeTrip);
      let budgetDays = Math.floor(expense / element.BudgetAverageCost);
      var budgetTrip: CostedTrip = {
        place: element,
        days: budgetDays,
        luxury: "Budget"
      }
      finalCostedTrips.push(budgetTrip);
    });
    return finalCostedTrips;
  }

  createCostedTripList(places: Place[], expense: number): CostedTrip[] {
    if (this.aiAllowed) {
      return this.createCostedTripListWithAi(places, expense);
    } else {
      return this.createCostedTripListWithoutAi(places, expense);
    }
  }

  cleanCostedTripsForBrokenDays(trips: CostedTrip[]): CostedTrip[] {
    let finalTrips = [];
    trips.forEach(trip => {
      if (trip.days > 0 && trip.days < 1000000) {
        finalTrips.push(trip);
      }
    })
    return finalTrips;
  }

  getPersonality(): Observable<Personality> {
    return this.http.get<Personality>('/api/users/personality')
      .pipe(
        retry(2)
      )
  }

  getAllPlaces(): Observable<Place[]> {
    return this.http.get<Place[]>('/api/places')
      .pipe(
        retry(2)
      )
  }

  getPassport(CountryOfOrigin: string): Observable<Passport> {
    return this.http.get<Passport>('/api/passports/countryOrigin/' + CountryOfOrigin)
      .pipe(
        retry(2)
      )
  }

  getPassportCountry(): Observable<string> {
    return this.http.get<string>('/api/users/passportCountry')
      .pipe(
        retry(2)
      )
  }

  public get personalityType(): typeof Personality {
    return Personality; 
  }

  shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  // What this currently doesn't take into account is whether or not a place is allowed financially
  // for example, if you sort ascending but the budget or the number of days doens't work then 
  // you may end up with incorrect results
  // e.g. filter allows all luxury levels
  // the search is for 10 days - 20 days
  // the budget is $2000
  // The highest luxury place costs $9000 pppd
  // However at midrange it is less expensive than a different place
  // The sort will not return the correct order, which should have the most expensive mid range (aka the most expensive passing trip)
  sortByDaysPlaceAscending(places: Place[]): Place[] {
    let budgetFilter = this._budgetFilter.getValue();
    let midRangeFilter = this._midRangeFilter.getValue();
    let luxuryFilter = this._luxuryFilter.getValue();
    console.log("Ascending") 

    return places.sort(function(a, b) {
      if (budgetFilter) {
        if (a.WeightedBudgetCost && b.WeightedBudgetCost) {
          if (a.WeightedBudgetCost < b.WeightedBudgetCost) {
            return -1;
          } 
          if (b.WeightedBudgetCost < a.WeightedBudgetCost) {
            return 1;
          }
        } else if (a.WeightedBudgetCost) {
          return -1;
        } else if (b.WeightedBudgetCost) {
          return 1;
        }
      } else if (midRangeFilter) {
        if (a.WeightedMidRangeCost && b.WeightedMidRangeCost) {
          if (a.WeightedMidRangeCost < b.WeightedMidRangeCost) {
            return -1;
          } 
          if (b.WeightedMidRangeCost < a.WeightedMidRangeCost) {
            return 1;
          }
        } else if (a.WeightedMidRangeCost) {
          return -1;
        } else if (b.WeightedMidRangeCost) {
          return 1;
        }
      } else if (luxuryFilter) {
        if (a.WeightedLuxuryCost && b.WeightedLuxuryCost) {
          if (a.WeightedLuxuryCost < b.WeightedLuxuryCost) {
            return -1;
          } 
          if (b.WeightedLuxuryCost < a.WeightedLuxuryCost) {
            return 1;
          }
        } else if (a.WeightedLuxuryCost) {
          return -1;
        } else if (b.WeightedLuxuryCost) {
          return 1;
        }
      }
      return 0;
    });
  }

  sortByDaysPlaceDescending(places: Place[], expense: number, fn: (days: number, useDays: boolean, numberOfDays: number, lowDays: number) => boolean): Place[] {
    let budgetFilter = this._budgetFilter.getValue();
    let midRangeFilter = this._midRangeFilter.getValue();
    let luxuryFilter = this._luxuryFilter.getValue();
    let useDays = this._useDays.getValue();
    let numberOfDays = this._numberOfDays.getValue();
    let lowDays = this._lowDays.getValue();

    
    // Slight bug in here that if a place has a midrange cost that exceeds another places luxury cost the midrange will be sorted lower than the luxury
    // Not worried about this bug for first round
    return places.sort(function(a, b) {
      let aPassesLuxury = a.WeightedLuxuryCost != 0 && (expense == 0 || fn(Math.floor(expense / a.WeightedLuxuryCost), useDays, numberOfDays, lowDays));
      let bPassesLuxury = b.WeightedLuxuryCost != 0 && (expense == 0 || fn(Math.floor(expense / b.WeightedLuxuryCost), useDays, numberOfDays, lowDays));
      let aPassesMidRange = a.WeightedMidRangeCost != 0 && (expense == 0 || fn(Math.floor(expense / a.WeightedMidRangeCost), useDays, numberOfDays, lowDays));
      let bPassesMidRange = b.WeightedMidRangeCost != 0 && (expense == 0 || fn(Math.floor(expense / b.WeightedMidRangeCost), useDays, numberOfDays, lowDays));
      let aPassesBudget = a.WeightedBudgetCost != 0 && (expense == 0 || fn(Math.floor(expense / a.WeightedBudgetCost), useDays, numberOfDays, lowDays));
      let bPassesBudget = b.WeightedBudgetCost != 0 && (expense == 0 || fn(Math.floor(expense / b.WeightedBudgetCost), useDays, numberOfDays, lowDays));

      if (luxuryFilter) {
        if (aPassesLuxury && bPassesLuxury) {
          if ((a.WeightedLuxuryCost > b.WeightedLuxuryCost)) {
            return -1;
          } 
          if ((b.WeightedLuxuryCost > a.WeightedLuxuryCost)) {
            return 1;
          }
        } else if (aPassesLuxury) {
          return -1;
        } else if (bPassesLuxury) {
          return 1;
        }
      }
      if (midRangeFilter) {
        if (aPassesMidRange && bPassesMidRange) {
          if (a.WeightedMidRangeCost > b.WeightedMidRangeCost) {
            return -1;
          } 
          if (b.WeightedMidRangeCost > a.WeightedMidRangeCost) {
            return 1;
          }
        } else if (aPassesMidRange) {
          return -1;
        } else if (bPassesMidRange) {
          return 1;
        }
      }
      if (budgetFilter) {
        if (aPassesBudget && bPassesBudget) {
          if (a.WeightedBudgetCost > b.WeightedBudgetCost) {
            return -1;
          } 
          if (b.WeightedBudgetCost > a.WeightedBudgetCost) {
            return 1;
          }
        } else if (aPassesBudget) {
          return -1;
        } else if (bPassesBudget) {
          return 1;
        }
      }
      return 0;
    });
  }
}
