import { Component, OnInit, Input } from '@angular/core';
import { Trip } from '../../models/Trip';
import { AuthService } from '../auth.service';
import { SharedApiService } from '../shared/shared-api.service';
import { SharedService } from '../shared/shared.service';
import { Router } from '@angular/router';
import { OverlayService } from '../overlay/overlay.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() trip: Trip;
  hovered: boolean;
  perPersonPerDay: number;
  isFilled: boolean;
  showCredit: boolean;
  photoLoaded: boolean;


  constructor(
    private authService: AuthService, 
    private sharedApiService: SharedApiService, 
    private sharedService: SharedService, 
    private overlayService: OverlayService, 
    private router: Router,
  ) {}

  ngOnInit() {
    this.setPerPersonPerDay();
    this.hovered = false;
    this.showCredit = true;

    this.sharedApiService.getPhotosForPlaceName(this.trip.Place)
    .subscribe(data => {
      this.trip.Photo= this.sharedService.randomPhoto(data);
      this.photoLoaded = true;
    });

    this.sharedApiService.getIsFilled(this.trip.Id, true).subscribe(
      (u: boolean) => {
        this.isFilled = u;
      }
    )
  }

  setPerPersonPerDay() {
    if (this.trip.Cost && this.trip.Duration && this.trip.NumberOfPeople) {
      this.perPersonPerDay = this.sharedService.convertCostToNumber(this.trip.Cost) / parseInt(this.sharedService.convertDurationToDays(this.trip.Duration)) / this.sharedService.convertCostToNumber(this.trip.NumberOfPeople);
    } else {
      this.perPersonPerDay = 0;
    }
  }

  heart() {
    if (this.authService.isLoggedIn()) {
      if (this.isHearted()) {
        this.unRegisterHeart();
      } else {
        this.registerHeart();
      }
    } else {
      this.overlayService.open("login");
    }
  }

  unRegisterHeart() {
    this.isFilled = false;
    this.sharedApiService.registerHeart(this.trip.Id, true).subscribe();
  }

  registerHeart() {
    this.isFilled = true;
    this.sharedApiService.registerHeart(this.trip.Id, true).subscribe();
  }

  isHearted() {
    return this.isFilled;
  }

  navigate(target) {
    if (target.className.includes("Card-photoLink") || target.className.includes("Card-heart") || target.className.includes("Card-passport")) {
      console.log("Easter is coming");
    } else {
      if (this.trip.AiGenerated) {
        this.router.navigate(['/aiTrip/' + this.trip.Id]);
        
      } else {
        this.router.navigate(['/trip/' + this.trip.Id]);
      }
    }
  }
}
