<section class="Profile">
    <div class="Profile-settingsWrapper">
        <div>
            <button class="Profile-button" [routerLink]="['/profile']"
                [queryParams]="{page: 'itineraries'}" [ngClass]= "itinerariesVisible ? '' : 'Profile-buttonInverse'">
                My Itineraries
            </button>
        </div>
        <!-- <div>
            <button class="Profile-button" [routerLink]="['/profile']"
                [queryParams]="{page: 'created'}" [ngClass]= "createdVisible ? '' : 'Profile-buttonInverse'">
                My Created Trips
            </button>
        </div> -->
        <div>
            <button class="Profile-button" [routerLink]="['/profile']"
                [queryParams]="{page: 'liked'}" [ngClass]= "likedVisible ? '' : 'Profile-buttonInverse'">
                My Wishlist
            </button>
        </div>
        <div>
            <button class="Profile-button" [routerLink]="['/profile']"
                [queryParams]="{page: 'settings'}" [ngClass]= "settingsVisible ? '' : 'Profile-buttonInverse'">
                Settings
            </button>
        </div>
    </div>
    <div class="Profile-settings" *ngIf="settingsVisible">
        <div class="Profile-hello">
            Hello <span class="Profile-email" *ngIf="this.email">{{ this.email }}</span>! So nice to address people by their email, what's in a name anyway?
        </div>
    
        <div class="Profile-passportWrapper">
            <div class="Profile-passportTitle">
                Passport
            </div>
            <div>Your current passport country on file is: {{ this.userPassport || "no country :(, guess you're from nowhere." }}</div>
            <div class="Profile-passportRow">
                You can change it here: 
                <!-- TODO vulgarity -->
                <!-- If that ain't right fix it right quick fella: -->
                <select class="Profile-passport--select" (change)="watchForOtherSelect($event.target.value)">
                    <option value="Country">
                        Country
                    </option>
                    <option value="USA">
                        USA
                    </option>
                    <option value="Australia">
                        Australia
                    </option>
                    <option value="Canada">
                        Canada
                    </option>
                    <option value="Ireland">
                        Ireland
                    </option>
                    <option value="New Zealand">
                        New Zealand
                    </option>
                    <option value="Singapore">
                        Singapore
                    </option>
                    <option value="United Kingdom">
                        United Kingdom
                    </option>
                    <option value="Other">
                        Other
                    </option>
                </select>
                <span *ngIf="this.showInput">
                    <input class="Profile-passport" placeholder="France" [(ngModel)]="passportCountry"> 
                </span>
                <button class="Profile-button Profile-passportButton" (click)="updatePassport()">Update Passport</button>
            </div>
            
        </div>

        <div class="Profile-airportWrapper">
            <div class="Profile-airportTitle">
                Airport
            </div>
            <div>
                <!-- TODO vulgarity -->
                <!-- Home slice, I gotta know what your home airport is to get you as accurate an estimate as possible. -->
                Where do you typically fly from? We can factor this in to your itineraries to improve price estimates.
            </div>
            <div>
                Right now you've listed: <span class="Profile-airport--text">{{ this.userAirport ? this.userAirport.IATACode  : "no airport to speak of, maybe you're grounded"}}</span>
                <input list="airportsData" class="Profile-airport" (input)="watchAirportInput($event.target.value)" placeholder="IAD,DCA,BWI" [(ngModel)]="this.userEnteredAirport">
                <datalist id="airportsData" *ngIf="this.iataports">
                    <option *ngFor="let port of this.iataports" [value]="port.iataCode">
                        {{ port.name }}
                    </option>
                </datalist>
                <button class="Profile-button" (click)="updateAirports()">Update Airport</button>
            </div>
        </div>


        <div class="Profile-airportWrapper">
            <div class="Profile-title">
                Website Personality Setting
            </div>
            <!-- Salty (Pompous, Professional aka boring)  -->
            <div class="Profile-personalityOptions">
                <div class="Profile-optionWrapper">
                    <div class="Profile-option" [ngClass]="this.personality == personalityType.Pompous ? 'Profile-option--selected' : ''" (click)="this.personality=personalityType.Pompous">
                        Pompous
                    </div>
                    <div class="Profile-optionSeparator">
                        <hr class="Profile-optionHr">                    
                    </div>
                    <div class="Profile-option" [ngClass]="this.personality == personalityType.Professional ? 'Profile-option--selected' : ''" (click)="this.personality=personalityType.Professional">
                        Professional
                    </div>
                    <div class="Profile-optionSeparator">
                        <hr class="Profile-optionHr">
                    </div>
                    <div class="Profile-option" [ngClass]="this.personality == personalityType.Salty ? 'Profile-option--selected' : ''" (click)="this.personality=personalityType.Salty">
                        Salty
                    </div>
                </div>
                <div>
                    <button class="Profile-saveButton" (click)="updatePersonality()">
                        Save Personality
                    </button>
                </div>
            </div>
        </div>

        <div class="Profile-buttonWrapper">
            <button class="Profile-button" (click)="logout()">
                Logout
            </button>
        </div>

        <!-- Implement approvals here, probably don't want to use recently added component -->
        <app-admin *ngIf="this.admin" class="Profile-approval"></app-admin>
    </div>


    <div class="Profile-itineraries" *ngIf="itinerariesVisible">
        <div *ngFor="let itinerary of userItineraries; let i = index">
            <app-itineraryprofileview [itinerary]='itinerary' [index]='i'></app-itineraryprofileview>
        </div>
    </div>

    <div class="Profile-createdTrips" *ngIf="createdVisible">
        <div *ngFor="let createdTrip of userCreatedTrips">
            <app-card [trip]='createdTrip'></app-card>
        </div>
    </div>

    <div class="Profile-likedWrapper" *ngIf="likedVisible">
        <div class="Profile-likedTrips">
            <div *ngFor="let likedTrip of userLikedTrips">
                <app-card [trip]='likedTrip'></app-card>
            </div>
        </div>
    
        <div class="Profile-likedPlaces" *ngIf="likedVisible && ((this.userPassport && this.passportObject) || this.userPassportUnspecified)">
            <div *ngFor="let likedPlace of userLikedPlaces">
                <app-daycard [place]='likedPlace' [search]='0'></app-daycard>
            </div>
        </div>
    </div>


    <!-- <div class="Profile-transportDeduction">
        <div>
            Okay so here's the deal, we don't know how much it's going to cost you to train, fly, boat, or ski to your destination because we don't
            know how you're getting to where you're going. Capiche?
        </div>

        <div>
            So what we recommend is to put a standard deduction for transport here, so that every
            search calculates your duration taking into account the cost you think it will take you to get there.
        </div>

        <input class="Profile-transportDeduction" placeholder="Don't know what to put, try: $500">
    </div> -->
</section>

