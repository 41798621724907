import { Component, Input } from '@angular/core';
import { OverlayService } from './../overlay/overlay.service';
import { trigger, transition, style, animate } from '@angular/animations';

const startScaleStyle = {transform: 'scale(0.95)', opacity: 0}
const endScaleStyle = {transform: 'scale(1)', opacity: 1}

const startFadeStyle = {opacity: 0}
const endFadeStyle = {opacity: 1}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('scaleInOut', [
      transition(':enter', [
        style(startScaleStyle),
        animate('200ms ease-out', style(endScaleStyle))
      ]),
      transition(':leave', [
        animate('200ms ease-out', style(startScaleStyle))
      ])
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style(startFadeStyle),
        animate('200ms ease-out', style(endFadeStyle))
      ]),
      transition(':leave', [
        animate('200ms ease-out', style(startFadeStyle))
      ])
    ])

  ]
})
export class ModalComponent {
  @Input() width: number;
  @Input() type: string;

  constructor(
    protected overlayService: OverlayService
  ) {}

  closeModal(evt?) {
    let target = evt ? evt.target : null;
    while (target != null) {
      if (target.className.includes("Modal-content")) {
        return;
      }
      target = target.parentElement;
    }
    this.overlayService.close();
  }
}
