import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GoogleMapsModule } from '@angular/google-maps';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth.interceptor';
import { SearchComponent } from './search/search.component';
import { RecentlyAddedComponent } from './recently-added/recently-added.component';
import { CardComponent } from './card/card.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from './authguard';
import { CreateComponent } from './create/create.component';
import { PlacardComponent } from './placard/placard.component';
import { CostSearchComponent } from './cost-search/cost-search.component';
import { HeaderComponent } from './header/header.component';
import { DaycardComponent } from './daycard/daycard.component';
import { BannerComponent } from './banner/banner.component';
import { TripdetailComponent } from './tripdetail/tripdetail.component';
import { FiltersComponent } from './filters/filters.component';
import { OceaniaComponent } from './oceania/oceania.component';
import { SafetyComponent } from './safety/safety.component';
import { FlightsComponent } from './flights/flights.component';
import { ItineraryComponent } from './itinerary/itinerary.component';
import { ItinerarycardComponent } from './itinerarycard/itinerarycard.component';
import { ItineraryprofileviewComponent } from './itineraryprofileview/itineraryprofileview.component';
import { VisasComponent } from './visas/visas.component';
import { ModalComponent } from './modal/modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ViatorComponent } from './viator/viator.component';
import { TransitComponent } from './transit/transit.component';
import { AdminComponent } from './admin/admin.component';
import { BasemapComponent } from './basemap/basemap.component';


const routes: Routes = [
  { path: 'recent', component: RecentlyAddedComponent },
  { path: '', component: CostSearchComponent },
  { path: 'login', component: LoginComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [ AuthGuard ] },
  { path: 'create', component: CreateComponent },
  { path: 'place', component: SearchComponent },
  { path: 'trip/:id', component: TripdetailComponent},
  { path: 'aiTrip/:id', component: TripdetailComponent},
  { path: 'place/:id', component: DaycardComponent},
  { path: 'oceania', component: OceaniaComponent},
  { path: 'itinerary/:id', component: ItineraryComponent},
  { path: 'createItinerary', component: ItineraryComponent}
  // { path: 'maps', component: BasemapComponent} TODO -- maps is still work in progress
]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SearchComponent,
    RecentlyAddedComponent,
    CardComponent,
    ProfileComponent,
    CreateComponent,
    PlacardComponent,
    CostSearchComponent,
    HeaderComponent,
    DaycardComponent,
    BannerComponent,
    TripdetailComponent,
    FiltersComponent,
    OceaniaComponent,
    SafetyComponent,
    FlightsComponent,
    ItineraryComponent,
    ItinerarycardComponent,
    ItineraryprofileviewComponent,
    VisasComponent,
    ModalComponent,
    ViatorComponent,
    TransitComponent,
    AdminComponent,
    BasemapComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    InfiniteScrollModule,
    DragDropModule,
    GoogleMapsModule
  ],
  exports: [RouterModule],
  providers: [{
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthGuard
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
