import { Component, Input } from '@angular/core';
import { ViatorService } from './viator.service';
import { DatePipe } from '@angular/common';
import { ViatorProducts } from 'src/models/ViatorProduct';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'app-viator',
  templateUrl: './viator.component.html',
  styleUrls: ['./viator.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({'max-height': '0px'}),
        animate('350ms ease-in', style({'max-height': '1000px'}))
      ])
    ])
  ]
})
export class ViatorComponent {
  @Input() place: string;
  @Input() country: string;
  viatorProducts: ViatorProducts;
  showTours: boolean;
  willHaveViatorProducts: boolean;
  showLoadingIcon: boolean;

  constructor(
    private viatorService: ViatorService
  ) {}

  ngOnInit() {
    this.showTours = false;
    let today = new Date();
    const datePipe = new DatePipe('en-US');
    let formattedDate = datePipe.transform(today, 'YYYY-MM-dd');

    let futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 14);
    let formattedFutureDate = datePipe.transform(futureDate, 'YYYY-MM-dd');

    this.viatorService.getViatorDestination(this.place, this.country).subscribe((data) => {
      if (data.length == 1) {
        this.willHaveViatorProducts = true;
        this.viatorService.getViatorTours(data[0].destinationId, 5, 500, formattedDate, formattedFutureDate, 5, 600).subscribe(
          (newData) => {
            this.showLoadingIcon = false;
            this.viatorProducts = newData;
          }
        )
      }
    });
  }

  rotate() {
    this.showTours = !this.showTours;
    if (this.showTours && this.willHaveViatorProducts && this.viatorProducts == null) {
      this.showLoadingIcon = true;
    } 
  }
}
