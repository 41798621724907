<div class="Header">
    <div class="Header-title">
        <a class="Header-logo" href="/"><img class="Header-logoImage" src="../../assets/logo-white.svg"></a>
    </div>
    <a class="Header-link" href="/">Home</a>
    <a class="Header-link" href="/place">Search by place</a>
    <!-- 
        Pompous: Perform search by locale rather than according to financial expenditure
        Professional: Search by place
        Salty: I don't <i>do</i> numbers
    -->
    <a class="Header-profileLink" *ngIf="this.isLoggedIn" href="/profile">
        <button class="Header-profileButton">My Profile</button>
    </a>
    <div *ngIf="!this.isLoggedIn">
        <button class="Header-topCta" (click)="this.overlayService.open('login')">Login</button>
    </div>
    <a href="/createItinerary">
        <button class="Header-topCta">
            Create Itinerary
        </button>
    </a>

    <div class="Header-midSection" *ngIf="!this.isLoggedIn">
        We assumed you have a USA passport, if that's not right, log in to change it!
        <!-- 
            Pompous: Without access to information concerning the country wherein you have acquired a passport we have assumed that you hail from the US of A
            Professional: We assumed you have a USA passport, if that's not right, log in to change it!
            Salty: if you dont tell us where youre from we gonna assume usa
        -->
    </div>


    <!-- lol who puts a footer in a header... -->
    <div class="Footer">
        <!-- TODO remove comment when we implement Vulgarity score -->
        <!-- Need something? Email our best friend Will @ <a href="mailto:will-reply@ballpark.travel">will-reply@ballpark.travel</a> -->
        Need something? Contact us at <a href="mailto:will-reply@ballpark.travel">will-reply&#64;ballpark.travel</a>

    </div>
</div>

<div class="Header-mobile">
    <div class="Header-hamburger" (click)="this.showMobileHeader=true">
        <img src="../../assets/hamburger.svg">
    </div>
    <div class="Header-mobileTitle">
        <a class="Header-mobileTitle--link" href="/">Ballpark Travel</a>
    </div>
    <div class="Header-mobileCTA">
        <a class="Header-profileText" *ngIf="this.isLoggedIn" href="/profile">
            My Profile
        </a>
        <div *ngIf="!this.isLoggedIn">
            <span class="Header-link" (click)="this.overlayService.open('login')">Login</span>
        </div>
    </div>
</div>

<div class="Header-drawer" *ngIf="this.showMobileHeader" [@slideInOut]>
    <span (click)="this.showMobileHeader=!this.showMobileHeader" class="Header-drawer--close">
        <img src="../../assets/orangeclosesmall.svg">
    </span>
    <div class="Header-title">
        <a class="Header-logo" href="/"><img class="Header-logoImage" src="../../assets/logo-white.svg"></a>
    </div>
    <a class="Header-link" href="/">Home</a>
    <a class="Header-link" href="/place">Search by place</a>
    <a class="Header-profileLink" *ngIf="this.isLoggedIn" href="/profile">
        <button class="Header-profileButton">My Profile</button>
    </a>
    <div *ngIf="!this.isLoggedIn">
        <button class="Header-topCta" (click)="this.overlayService.open('login')">Login</button>
    </div>
    <a href="/createItinerary">
        <button class="Header-topCta">
            Create Itinerary
        </button>
    </a>
    
    <div class="Header-midSection" *ngIf="!this.isLoggedIn">
        We assumed you have a USA passport, if that's not right, log in to change it!
    </div>

    <div class="Header-drawer--footer">
        Need something? Email our best friend Will &#64; <a href="mailto:will-reply@ballpark.travel">will-reply&#64;ballpark.travel</a>
    </div>
</div>