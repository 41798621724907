import { Airport } from './Airport';

interface Trip {
    Name: string,
    Latitude: number,
    Longitude: number,
    MapURL: string,
    MapId: number,
    MapIsFlagged: boolean,
    PhotoURL: string,
    Place: string,
    State: string,
    Region: string,
    Country: string,
    Continent: string[],
    Luxury: string,
    Component: number, // Make this a component
    Duration: string, // Days but needs to be better
    NumberOfPeople: string,
    URL: URL,
    OtherPlaces: string, // this can be changed later
    Cost: string,
    Tags: string, // Make this its own component as well
    NumberOfHelpfuls: number,
    Hearts: number,
    ImageBlob: any,
    Photo: any,
    CreatedDate: Date,
    ApprovalStatus: ApprovalStatus,
    LinkedRecentlyAddedTripId?: number,
    LinkedInternalTripId?: number,
    Airports: Airport[],
    AiGenerated: boolean,
    AdminVerified: boolean,
    AiItinerary: string,

  
    // Backend Only
    UserId?: number,
    Id?: number
  }

enum ApprovalStatus {
  Rejected = 1,
  Approved = 2,
  UserModificationRequired = 3,
  InternalModificationRequired = 4,
}  


export {
  Trip,
  ApprovalStatus
}
  