import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

 
@Injectable()
export class AuthGuard  {
 
    constructor(private authService: AuthService,
                private router: Router) {
    }
 
    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isLoggedIn()) {
            return true;
        }
 
        this.router.navigateByUrl("/");
 
        return false;
    }
}