import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Trip } from '../../models/Trip';
import { Place } from '../../models/Place';
import { retry } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  search(t: string): Observable<Place[]> {
    // console.log("SearchService::Search");
    return this.http.post<Place[]>('/api/places/search', {Name: t})
      .pipe(
        retry(2)
      )
  }

  searchTrips(t: Trip): Observable<Trip[]> {
    // console.log("SearchService::Search");
    return this.http.post<Trip[]>('/api/internalTrips/search', t)
      .pipe(
        retry(2)
      )
  }

  searchTripById(t: number): Observable<Trip> {
    // console.log("SearchService::Search");
    return this.http.get<Trip>('/api/internalTrips/getById/' + t)
      .pipe(
        retry(2)
      )
  }

  executeSearch(t: string): Observable<Trip[]> { 
    return this.http.post<Trip[]>('/api/places/executePlaceSearch', {Name: t})
    .pipe(
      retry(2)
    )
  }

  // Interested to see if this works
  searchAiTrips(t: Trip): Observable<Trip[]> {
    return this.http.post<Trip[]>('/api/aiTrips/search', t)
      .pipe(
        retry(2)
      )
  }

  searchAiTripById(t: number): Observable<Trip> {
    // console.log("SearchService::Search");
    return this.http.get<Trip>('/api/aiTrips/getById/' + t)
      .pipe(
        retry(2)
      )
  }

  askAi(t: string): Observable<string> {
    return this.http.post<string>('/api/ai/qa', {query: t})
      .pipe(
        retry(2)
      )
  }
}