<section class="IPV">
    <div class="IPV-wrapper">
        <div class="IPV-left">
            <div class="IPV-sharedWith">
                <div *ngFor="let friend of this.itinerary.SharedWithEmailsEdit; let i = index" class="IPV-friendIcon--{{i}} IPV-friendIcon">
                    <!-- TODO: updated to friend.Name[0] -->
                    {{ friend[0] }}
                </div>
                <button class="IPV-shareIcon" (click)="showItineraryShareModal(this.index)">
                    <span>+</span>
                </button>
            </div>
            <div class="IPV-headline">
                <div class="IPV-name">
                    {{ this.itinerary.Name ? this.itinerary.Name : 'My Itinerary' }}
                </div>
                <div class="IPV-costPerson">
                    ${{ this.itinerary.Cost / this.totalPeople | number : '1.0-0'}}
                </div>
                <div class="IPV-perPerson">
                    Per Person
                </div>
                <!-- <div class="IPV-invite">
                    <button class="IPV-inviteButton" (click)="this.showItineraryShare=!this.showItineraryShare">Invite</button>
                </div> -->
            </div>
        </div>
        <div class="IPV-content IPV-center">
            <div class="IPV-stats">
                <div class="IPV-friends IPV-block">
                    <!-- TODO vulgarity -->
                    <!-- {{ this.totalPeople }} amigoz -->
                    <img src="../../assets/orangeperson.svg" class="IPV-person"> {{ this.totalPeople }} {{ this.totalPeople == 1 ? 'person' : 'people' }}
                </div>
                <div class="IPV-duration IPV-block">
                    <img src="../../assets/orangecalendar.svg" class="IPV-calendar"> {{ this.itinerary.Days }} day{{ this.itinerary.Days == 1 ? '' : 's' }}
                </div>
                <div class="IPV-cost IPV-block">
                    <img src="../../assets/orangemoney.svg" class="IPV-money"> {{ this.itinerary.Cost | number: '1.0-0' }} dollars
                </div>
            </div>

            <!-- <div class="IPV-subtext">
                <span *ngFor="let dest of this.itinerary.Destinations; last as isLast">
                    <span>{{dest.Place.Name}}</span><span *ngIf="!isLast">, </span> 
                </span>
            </div> -->
            <div class="IPV-ctaWrapper">
                <a class="IPV-cta" href="/itinerary/{{ this.itinerary.Id }}">
                    Expand view
                </a>
            </div>
        </div>
        <div class="IPV-imageWrapper IPV-right" *ngIf="this.itineraryPhoto">
            <span *ngIf="this.itineraryPhoto">
                <img class="IPV-image" *ngIf="this.mapBin" [ngStyle]="{'background-image': 'url(data:image/jpg;base64,' + this.mapBin + ')', 'background-size': 'cover'}">
            </span>
        </div>
    </div>
</section>


<!-- TODO: We need to figure out how to close the ones that aren't open anymore because showItineraryShare is never reset to false -->
<app-modal width="1000" type="itinerary-share" *ngIf="showItineraryShare && this.showItineraryShareIndex == this.index">
    <div class="IPV-share IPV-modal">
        <div class="IPV-modalContentWrapper">
            <div class="IPV-modalContent">
                <div>
                    <input class="IPV-input" class="IPV-modalInput" placeholder="Friend Email" [(ngModel)]="friendEmail">
                </div>
                <div>
                    <input class="IPV-input" class="IPV-modalInput" placeholder="Friend Name" [(ngModel)]="friendName">
                </div>
                <div>
                    <button class="IPV-modalShare" (click)="shareItinerary()">Share</button>
                </div>
            </div>
        </div>
    </div>
</app-modal>