<!-- Everything commented out here is for use by the insane. If you run across someone who just needs to be mentally destroyed send this their way.
<section class="Detail" *ngIf="trip">
    <div class="Detail-line">
        what up goon learn something bout {{ trip.Place }} fool
    </div>
    <div class="Detail-line">
        You can find {{ trip.Place }} at {{ trip.Latitude || 'some number of' }} degrees latitude and {{ trip.Longitude || 'some number of'}} degrees longitude
    </div>
    <div class="Detail-line">
        Pull out that sextant and start swimming you discolored narwhal.
    </div>
    <div class="Detail-line">
        Still here? You wanna see a picture of {{ trip.Place }}? Fine. Here:
        <div class="Detail-line">
            <img src="{{trip.Photo}}">
        </div>
        Sexy right? You can thank <a href="{{this.links}}">{{ this.photographer }}</a> for <a href="{{this.htmlPhoto}}">that splendid display.</a>
    </div>
    <div class="Detail-line">
        You're still reading? Okay your choice bro 
    </div>
    <div class="Detail-line">
        You're probably actually interested in learning about the trip you clicked on huh guy. mmk.
    </div>
    <div class="Detail-line">
        {{ trip.NumberOfPeople }} dangos went on this trip
    </div>
    <div class="Detail-line">
        Those {{ trip.NumberOfPeople }} mofos stayed in {{ trip.Place }} for {{ trip.Duration }} days.
    </div>
    <div class="Detail-line">
        And the lot of them paid ${{ trip.Cost }}
    </div>
    <div class="Detail-line">
        So because your bumass failed high school algebra before you get out that calcaulator I'll just tell you the cost per person per day
    </div>
    <div *ngIf="trip.Duration && trip.Cost" class="Detail-cost--perDay">
        <div class="Detail-line">
            It's this much: ${{this.perPersonPerDay | number: '1.2-2' }}
        </div>
    </div>
    <div class="Detail-line">
        But since we all know you're incompetent I put these text boxes here so that you can calculate how much it would cost you to go to {{ trip.Place }}
    </div>
    <div class="Detail-line">
        And in case you don't remember the fucking commutative property of multiplication I'll remind you that it doesn't matter in which order you put the number of people and the number of days in the boxes.
    </div>
    <div class="Detail-line">
        But I labelled them anyway so you and your pea sized brain don't get confused
    </div>
    <div class="Detail-line"> 
        <label>People...</label>
        <input [(ngModel)]="this.numPeople" placeholder="Add your number of people here pumpkin">
    </div>
    <div class="Detail-line">
        <label>Days...</label> 
        <input [(ngModel)]="this.numDays" placeholder="Add your number of days here sugar">
    </div>
    <div *ngIf="this.numPeople && this.numDays" class="Detail-line">
        <div class="Detail-line">
            Your total at the next register: ${{ this.numPeople * this.perPersonPerDay * this.numDays }}
        </div>
        <div class="Detail-line">
            Wow calculators are amazing.
        </div>
    </div>
    

    <div class="Detail-line">
        What type of trip did these drywall sniffers take?
    </div>
    <div class="Detail-line">
        The short-nailed stork muggers said it was a {{ trip.Luxury }} trip
    </div>
    <div class="Detail-line">
        and they tagged it with this stuff: {{ trip.Tags }}
    </div>
    <div class="Detail-line">
        If you have no conception of where {{ trip.Place }} is this map probably won't help you.
        <div class="Detail-line">
            <img src="{{ trip.ImageBlob }}">
        </div>
    </div>
    <div class="Detail-line">
        Happy swimming bitch.
    </div>
</section> -->

<section class="Detail" *ngIf="trip">
    <div class="Detail-banner">
        <app-banner [trip]='trip' [includeLuxury]=true></app-banner>
    </div>

    <div class="Detail-row">
        <div class="Detail-card">
            <div class="Detail-card--left">
                <div class="Detail-card--title">
                    The Deets
                </div>
                <div class="Detail-cardRow--wrapper">
                    <div class="Detail-cardRow">
                        <img src="../../assets/orangeperson.svg"> <span class="Detail-cardRow--text">{{ trip.NumberOfPeople }} {{ trip.NumberOfPeople == '1' ? 'Person' : 'People'}}</span>
                    </div>
                    <div class="Detail-cardRow">
                        <img src="../../assets/orangecalendar.svg"> <span class="Detail-cardRow--text">{{ trip.Duration }} {{ trip.Duration == '1' ? 'Day' : 'Days'}}</span>
                    </div>
                    <div class="Detail-cardRow">
                        <img src="../../assets/orangemoney.svg"> <span class="Detail-cardRow--text">${{ this.cleanCost(trip.Cost) }} Total</span>
                    </div>
                </div>
            </div>
            <div class="Detail-card--right">
                <div *ngIf="trip.NumberOfPeople && trip.Duration && trip.Cost" class="Detail-card--cost">
                    ${{this.perPersonPerDay | number: '1.0-0' }}
                </div>
                <div class="Detail-card--pppd">
                    Per Person Per Day
                </div>
                <div *ngIf="trip.URL" class="Detail-card--url">
                    <div>
                        <a class="Detail-card--url" href="{{ trip.URL }}">See blog post</a>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="Detail-mapWrapper" *ngIf="this.mapBin" [ngStyle]="{'background-image': 'url(data:image/jpg;base64,' + this.mapBin + ')', 'background-size': 'cover'}" >
            <a href="http://mapbox.com/about/maps" class='mapbox-logo' target="_blank">Mapbox</a>
            <div class="mapbox-attribution-container">
                © <a class="mapbox-link" href="https://www.mapbox.com/about/maps/" target="_blank">Mapbox</a>
                &nbsp;© <a class="mapbox-link" href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>
                &nbsp;<a class="mapbox-link" href="https://www.mapbox.com/map-feedback/" target="_blank"><strong>Improve this map</strong></a>
            </div>
        </div>

    </div>

    <div class="Detail-flagWrapper" *ngIf="this.userIsAdmin">
        {{ this.flagIsFilled ? 'Unf' : 'F'}}lag this map
        <div (click)="toggleFlag()" *ngIf="!this.flagIsFilled">
                <img class="Detail-flag" src="../../assets/flag-empty.svg" >
        </div>
        
        <div (click)="toggleFlag()" *ngIf="this.flagIsFilled">
            <img class="Detail-flag" src="../../assets/flag-filled.svg" >
        </div>        
    </div>

    <!-- <div class="Detail-fullRow" *ngIf="trip.OtherPlaces">
        <div *ngIf="trip.OtherPlaces" class="Detail-otherPlaces">
            This trip included a visit to {{ trip.OtherPlaces }}
        </div>
    </div> -->
    <!-- 
    <div class="Detail-fullRow" *ngIf="trip.AiItinerary" [innerHTML]="trip.AiItinerary" style="white-space: pre-line">
    </div> -->


    <div *ngIf="this.tripAirport">
        <app-flights [tripAirport]='tripAirport'></app-flights>
    </div>

    <div> 
        <app-viator [place]='trip.Place' [country]='trip.Country'></app-viator>
    </div>


    
    <div class="Detail-likeRow">
        <div class="Detail-heartDiv">
            <img class="Detail-heartOutline" (click)="heart()" [src]="isFilled ? '../../assets/orangeheartchecked.svg' : '../../assets/orangeheartoutline.svg'">
        </div>
        <div class="Detail-saveText">
            <!-- TODO vulgarity -->
            <!-- {{ isFilled ? 'You got this one on file, slick' : 'Save this trip?' }} -->
            {{ isFilled ? 'Already saved!' : 'Add to wishlist?' }}
        </div>
        <div class="Detail-saveSecondary">
            <!-- TODO vulgarity -->
            <!-- {{ isFilled ? 'But linger here as long as you like :)' : 'Don\'t hate, participate...' }}  -->
            <!-- {{ isFilled ? 'But linger here as long as you like ;)' : 'Don\'t hate, participate...' }}  -->
        </div>
    </div>

</section>