import { Component, OnInit } from '@angular/core';
import { Trip, ApprovalStatus } from '../../models/Trip';
import { AuthService } from '../auth.service';
import { RecentlyAddedService } from './recently-added.service';
import { SharedService } from '../shared/shared.service';
import { SharedApiService } from '../shared/shared-api.service';

@Component({
  selector: 'app-recently-added',
  templateUrl: './recently-added.component.html',
  styleUrls: ['./recently-added.component.scss']
})
export class RecentlyAddedComponent implements OnInit {
  userAddedTrips: Trip[];
  imageToShow: any[];

  constructor(private recentlyAddedService: RecentlyAddedService, private authService: AuthService, private sharedService: SharedService, private sharedApiService: SharedApiService) {
    this.getTrips();
  }

  ngOnInit() {
    this.imageToShow = [];
  }

  getTrips(): void {
    this.recentlyAddedService.getRecent()
      .subscribe((t: Trip[]) => {
        // Move these filters to the DB call
        this.userAddedTrips = t.filter(element => {
          return (element.Luxury == "Luxury" || element.Luxury == "Medium" || element.Luxury == "Budget") && element.ApprovalStatus != ApprovalStatus.Rejected
        });
        this.userAddedTrips = this.userAddedTrips.reverse().slice(0, 6);
        this.userAddedTrips.forEach(element => {
          element.Duration = element.Duration.split(" ")[0];
          if (element.Cost[0] == "$" && element.Cost.length > 1) {
            element.Cost = element.Cost.slice(1);
          }

          if (element.PhotoURL) {
            this.sharedApiService.getPhoto(element.PhotoURL)
            .subscribe( data => {
              //let max = data['total'];
              //let pictureIndex = Math.floor(Math.random() * max);
              let pictureIndex = Math.floor(Math.random() * 10); // There are 10 photos per page
              element.Photo = data['results'][pictureIndex]['urls']['small'];
            })
          }
        });
      });
    
    
  }

  thumbsUp(trip: Trip): void {
    console.log("thumbs up");
    // TODO
  }

}
