import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CostedTrip } from 'src/models/CostedTrip';
import { AuthService } from '../auth.service';
import { Passport, CountryDetail } from 'src/models/Passport';
import { SharedService } from '../shared/shared.service';
import { SharedApiService } from '../shared/shared-api.service';
import { Router } from '@angular/router';
import { OverlayService } from '../overlay/overlay.service';
import { Place } from 'src/models/Place';

@Component({
  selector: 'app-daycard',
  templateUrl: './daycard.component.html',
  styleUrls: ['./daycard.component.scss']
})
export class DaycardComponent implements OnInit {
  @Input() place: Place;
  @Input() search: number;

  isFilled: boolean;
  showPhoto: boolean;
  showCredit: boolean;
  passportColor: string;
  visaVR: boolean = true;
  visaVF: boolean = true;
  visaVOA: boolean = true;
  visaVE: boolean = true;
  peopleMultiplier: number = 1;
  copyOfTripDays: number
  shouldNotBeFiltered: boolean;
  lowDays: number = 0;
  highDays: number = 999999;
  useDays: boolean;
  numberOfDays: number;
  budgetFilter: boolean;
  midRangeFilter: boolean;
  luxuryFilter: boolean;
  northAmerica: boolean;
  southAmerica: boolean;
  europe: boolean;
  africa: boolean;
  asia: boolean;
  oceania: boolean;
  pppd: string;
  hovered: boolean;
  userIsAdmin: boolean;
  flagIsFilled: boolean;
  visa: CountryDetail;
  budgetPassing: boolean = true;
  midrangePassing: boolean = true;
  luxuryPassing: boolean = true;
  budgetDays: number;
  midrangeDays: number;
  luxuryDays: number;
  selectedLuxury: string;
  selectedDays: number;


  constructor(
    private authService: AuthService, 
    private sharedService: SharedService, 
    private sharedApiService: SharedApiService, 
    private overlayService: OverlayService, 
    private router: Router
  ) {}

  ngOnInit() {
    this.hovered = false;
    this.showPhoto = true;
    this.showCredit = true;
    if (this.authService.isLoggedIn()) {
      this.getIsFilledIn();
      this.sharedApiService.getUserIsAdmin().subscribe((d) => {
        this.userIsAdmin = d;
        this.flagIsFilled = false;
      })
    };

    this.subscribeFilters();
    this.subscribePeople();
    this.getPhotoIfNecessary();
  }

  postSubscriptionSetter() {
    this.invalidateDataGaps();
    if (this.search == 0) {
      this.selectedLuxury = this.randomizeStart();
      this.setPppd();
    } else {
      this.identifyPassingLuxuries();
      this.selectedLuxury = this.randomizeStart();
      if (this.useDays) {
        this.selectedDays = this.numberOfDays;
      } else {
        this.selectedDays = this.selectDaysWithBudget(this.selectedLuxury);
      }
      this.setPppd();
    }
  }

  invalidateDataGaps() {
    if (this.place.WeightedBudgetCost == 0) {
      this.budgetPassing = false;
    }
    if (this.place.WeightedMidRangeCost == 0) {
      this.midrangePassing = false;
    }
    if (this.place.WeightedLuxuryCost == 0) {
      this.luxuryPassing = false;
    }
  }

  randomizeStart() {
    let randomNumber = Math.floor(Math.random() * 100)
    let selectFromList = [];
    if (this.budgetPassing) {
      selectFromList.push("Budget");
    }
    if (this.midrangePassing) {
      selectFromList.push("Mid-range");
    }
    if (this.luxuryPassing) {
      selectFromList.push("Luxury")
    }
    if (selectFromList.length > 0) {
      return selectFromList[randomNumber % selectFromList.length];
    } else {
      return "";
    }
  }

  checkFilters() {
    if (!this.budgetFilter) {
      this.budgetPassing = false;
    }
    if (!this.midRangeFilter) {
      this.midrangePassing = false;
    } 
    if (!this.luxuryFilter) {
      this.luxuryFilter = false;
    }
  }

  selectDaysWithBudget(budget) {
    if (budget == 'Budget') {
      return this.budgetDays;
    } else if (budget == 'Mid-range') {
      return this.midrangeDays;
    } else if (budget == 'Luxury') {
      return this.luxuryDays;
    }
  }

  getLowestDays() {
    if (this.useDays) {
        return this.numberOfDays;
    } else {
      return this.lowDays;
    }
  }

  identifyPassingLuxuries() {
    let price = this.search;
    let weightedBudget = this.place.WeightedBudgetCost;
    let weightedMidRangeCost = this.place.WeightedMidRangeCost;
    let weightedLuxuryCost = this.place.WeightedLuxuryCost;
    this.budgetPassing = this.budgetPassing && weightedBudget != 0 && Math.floor(price / weightedBudget / this.getLowestDays() / this.peopleMultiplier) >= 1;
    this.budgetDays = Math.floor(price / weightedBudget / this.peopleMultiplier);
    this.midrangePassing = this.midrangePassing && weightedMidRangeCost != 0 && Math.floor(price / weightedMidRangeCost / this.getLowestDays() / this.peopleMultiplier) >= 1;
    this.midrangeDays = Math.floor(price / weightedMidRangeCost / this.peopleMultiplier);
    this.luxuryPassing = this.luxuryPassing && weightedLuxuryCost != 0 && Math.floor(price / weightedLuxuryCost / this.getLowestDays() / this.peopleMultiplier) >= 1;
    this.luxuryDays = Math.floor(price / weightedLuxuryCost / this.peopleMultiplier);
  }

  getPhotoIfNecessary() {
    if (this.place.Photo == null && this.place.Name != "" && this.place.Name != null) {
      this.sharedApiService.getPhotosForPlaceName(this.place.Name)
      .subscribe(data => {
        if (data.length != 0) {
          this.place.Photo = this.sharedService.randomPhoto(data);
        }
      });
    }
  }

  passesDaysPlace(interpolatedDays) {
    if (this.useDays) {
      if (interpolatedDays >= this.numberOfDays) {
        this.coercePlaceDaysToUpperBound(this.numberOfDays);
      }
      return (interpolatedDays >= this.numberOfDays);
    } else {
      if (interpolatedDays > this.highDays) {
        this.coercePlaceDaysToUpperBound(this.highDays);
      }
      return (interpolatedDays >= this.lowDays)
    }
  }

  async getIsFilledIn() {
    this.sharedApiService.getIsFilledAsync(this.place.Id, false).then(
      data => {
        this.isFilled = data;
      }
    );
  }

  changeSelected(budget) {
    if (budget == 'budget') {
      this.selectedLuxury = 'Budget';
      this.selectedDays = this.budgetDays;
    } else if (budget == 'midrange') {
      this.selectedLuxury = 'Mid-range';
      this.selectedDays = this.midrangeDays;
    } else if (budget == 'luxury') {
      this.selectedLuxury = 'Luxury';
      this.selectedDays = this.luxuryDays;
    }
    this.setPppd();
  }

  navigate(target) {
    if (target.className.includes("Daycard-photoLink") || target.className.includes("Daycard-heart") || target.className.includes("Daycard-passport") || target.className.includes("Daycard-pill")) {
      console.log("Easter is coming");
    } else {
      this.router.navigate(['/place'], { queryParams: {q: this.place.Name, luxury: this.selectedLuxury}});
    }
  }

  setPppd() {
    if (this.selectedLuxury == 'Budget') {
      if (this.place.WeightedBudgetCost > 0) {
        this.pppd = this.place.WeightedBudgetCost.toFixed(2);
      } else if (this.place.BudgetAverageCostAi > 0) {
        this.pppd = this.place.BudgetAverageCostAi.toFixed(2);
      } else {
        this.pppd = this.place.BudgetAverageCost.toFixed(2);
      }
    } else if (this.selectedLuxury == 'Mid-range') {
      if (this.place.WeightedMidRangeCost > 0) {
        this.pppd = this.place.WeightedMidRangeCost.toFixed(2);
      } else if (this.place.MidRangeAverageCostAi > 0) {
        this.pppd = this.place.MidRangeAverageCostAi.toFixed(2);
      } else {
        this.pppd = this.place.MidRangeAverageCost.toFixed(2);
      }
    } else if (this.selectedLuxury == 'Luxury') {
      if (this.place.WeightedLuxuryCost > 0) {
        this.pppd = this.place.WeightedLuxuryCost.toFixed(2);
      } else if (this.place.LuxuryAverageCostAi > 0) {
        this.pppd = this.place.LuxuryAverageCostAi.toFixed(2);
      } else {
        this.pppd = this.place.LuxuryAverageCost.toFixed(2);
      }
    }
  }

  toggleFlag() {
    this.flagIsFilled = !this.flagIsFilled;
    this.sharedApiService.assignPhotoFlag(this.place.Photo.htmlPhoto, this.flagIsFilled).subscribe();
  }

  heart() {
    if (this.authService.isLoggedIn()) {
      if (this.isHearted()) {
        this.unRegisterHeart();
      } else {
        this.registerHeart();
      }
    } else {
      this.overlayService.open("login");
    }
  }

  unRegisterHeart() {
    this.isFilled = false;
    this.sharedApiService.registerHeart(this.place.Id, false).subscribe();
  }

  registerHeart() {
    this.isFilled = true;
    this.sharedApiService.registerHeart(this.place.Id, false).subscribe();
  }

  togglePhotoAndMap() {
    this.showPhoto = !this.showPhoto;
  }

  isHearted() {
    return this.isFilled;
  }

  subscribeFilters() {
    this.sharedService.budgetFilter$.subscribe(filter => {
      this.budgetFilter = filter;
      this.budgetPassing = this.budgetPassing && this.budgetFilter;
      this.postSubscriptionSetter();
    });
    this.sharedService.midRangeFilter$.subscribe(filter => {
      this.midRangeFilter = filter;
      this.midrangePassing = this.midrangePassing && this.midRangeFilter;
      this.postSubscriptionSetter();
    });
    this.sharedService.luxuryFilter$.subscribe(filter => {
      this.luxuryFilter = filter;
      this.luxuryPassing = this.luxuryPassing && this.luxuryFilter;
      this.postSubscriptionSetter();
    });
  }

  subscribePeople() {
    this.sharedService.numberOfPeople$.subscribe(people => {
      this.peopleMultiplier = people;
      this.subscribeDays();
    });
  }

  subscribeDays() {
    this.sharedService.useDays$.subscribe(bool => {
      this.useDays = bool;
      this.sharedService.numberOfDays$.subscribe(days => {
        this.numberOfDays = days;
        this.postSubscriptionSetter();
      });
      this.sharedService.lowDays$.subscribe(days => {
        this.lowDays = days;
        this.postSubscriptionSetter();
      });
      this.sharedService.highDays$.subscribe(days => {
        this.highDays = days;
      });
    })
  }

  coercePlaceDaysToUpperBound(upperBound: number) {
    this.copyOfTripDays = upperBound;
  }

}
