<!-- TODO for this section: Please include attribution for the urls -->

<section class="Banner" *ngIf="!trip">
    <div class="Banner-wrapper" style="background-image: url('../../assets/paris-wide.jpg')">
        <div class="Banner-content">
            <div class="Banner-intro Banner-inside">
                Place of the week
            </div>
            <div class="Banner-place Banner-inside">
                <h2 class="Banner-placeName">Paris, mon amour</h2>
            </div>
            <div class="Banner-cost Banner-inside">
                <button class="Banner-ctaInverted">
                    Mid-rangers &#64; $247.50 / pppd
                </button>
            </div>
            <a class="Banner-cta Banner-inside" [routerLink]="['/place']"
            [queryParams]="{q: 'Paris'}">
                Croissant is too hard to pronounce
            </a>
        </div>
    </div>
</section>


<section class="Banner-trip" *ngIf="trip" (mouseenter)="this.showCredit=true">
    <div class="Banner-heading Banner-heading--{{trip.Luxury}}" *ngIf="this.includeLuxury">
        {{ trip.Luxury == 'Medium' ? 'Mid-range' : trip.Luxury }}
    </div>

    <div class="Banner-wrapper--artsy" *ngIf="trip.Photo && trip.Photo.small" [ngStyle]="{'background-image': 'url(' + trip.Photo.small + ')'}">
        <div class="Banner-content">
<!-- 
            // TODO Implement the heart as soon as we get the nerve up to write the extra three lines of code to make it work.
            <div class="Banner-heartDiv">
                <img *ngIf="this.showChecked" src="../../assets/orangeheartchecked.svg" (click)="this.showChecked = !this.showChecked">
                <img *ngIf="!this.showChecked" src="../../assets/orangeheartoutline.svg" (click)="this.showChecked = !this.showChecked">
            </div> -->
            <div class="Banner-intro Banner-inside">
                {{ this.intro }}
            </div>
            <div class="Banner-place Banner-inside">
                <h2 class="Banner-placeName">{{trip.Place}}<span *ngIf="trip.Country && trip.Country != trip.Place">, <br>{{trip.Country}}</span></h2>
            </div>
            <!-- <div class="Banner-cost Banner-inside">
                <button class="Banner-ctaInverted">
                    Mid-rangers @ $247.50 / pppd
                </button>
            </div>
            <a class="Banner-cta Banner-inside" [routerLink]="['/place']"
            [queryParams]="{q: 'Paris'}">
                Croissant is too hard to pronounce
            </a> -->
            <div *ngIf="trip.Photo.small && this.showCredit" class="Banner-credits">
                Photo by <a class="Banner-photoLink" href="{{trip.Photo.links}}?utm_source=Ballpark&utm_medium=referral">{{trip.Photo.photographer}}</a> on <a class="Banner-photoLink" href="{{trip.Photo.htmlPhoto}}?utm_source=Ballpark&utm_medium=referral">Unsplash</a>
            </div>
        </div>
    </div>
</section>