<app-modal [width]='350' [type]='"login"'>
  <section *ngIf="this.showLogin">
    <div class="Login">
      <div class="Login-header">
        <div class="Login-headerText">
            Hello returning traveler, so nice to see you again :)
        </div>
      </div>
      <form class="Login-form" method="POST" action="/login">
        <div class="Login-row">
          <input class="Login-input" name="Username" [(ngModel)]="username" type="email" placeholder="Email">
        </div>
        <div class="Login-row">
          <input class="Login-input" name="Password" [(ngModel)]="password" type="password" placeholder="Password">
        </div>
        <div class="Login-buttonWrapper">
          <button class="Login-button" (click)="submit()">
            Login
          </button>
        </div>
      </form>
      
      <div class="Login-register">
          New in town? Welcome to Ballpark Travel!
          <div>
            <button class="Login-registerButton" (click)="switch($event)">
                Register here
            </button>
          </div>
      </div>
    </div>
  </section>


  <section *ngIf="!this.showLogin">
    <div class="Register"> 
      <div class="Register-header">
        <div class="Register-headerText">
            Welcome Wanderer!
        </div>
      </div>
      <form class="Register-form" method="POST" action="/register">
        <div class="Register-row">
          <input class="Register-input" name="rFirstName" [(ngModel)]="rFirstName" type="text" placeholder="First Name (optional)">
        </div>
        <div class="Register-row">
          <input class="Register-input" name="rLastName" [(ngModel)]="rLastName" type="text" placeholder="Last Name (optional)">
        </div>
        <div class="Register-row">
          <input class="Register-input" name="rUsername" [(ngModel)]="rUsername" type="email" placeholder="Email">
        </div>
        <div class="Register-row">
          <input class="Register-input" name="rPassword" [(ngModel)]="rPassword" type="password" placeholder="Password">
        </div>
        <div class="Register-buttonWrapper">
          <button class="Register-button" (click)="back($event)">
            Back
          </button>
          <button class="Register-button" (click)="register()">
            Register
          </button>
        </div>
      </form>
    </div>
  </section>
</app-modal>