import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Trip, ApprovalStatus } from 'src/models/Trip';
import { Maps } from 'src/models/Maps';
import { SharedApiService } from '../shared/shared-api.service';
import { SharedService } from '../shared/shared.service';
import { AdminService } from './admin.service';
import { Place } from 'src/models/Place';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  recentTrips: Trip[];
  admin: boolean;
  flaggedMapTrips: Trip[];
  pageParam: string;
  newMapQuery: string;
  separateNewMapQuery: string;
  newLocalMapURL: string;
  newMapLat: string;
  newMapLng: string;
  flaggedMaps: Maps[];
  newMapImage;
  newMapJSON: JSON;
  places: Place[];
  filteredPlaces: Place[];
  solicitedDays: string;
  newItinerary: string;
  itineraryPlace: string;
  itineraryLuxury: string;
  itineraryDuration: string;
  adminEnteredPrice: string;
  adminEnteredCurrency: string;
  adminNumberOfPeople: number;
  firstSubmission: boolean;
  separateNameQuery: boolean;
  separateOldMapJSON: JSON;
  separateNewMapJSON: JSON;
  placesWithoutMaps: Place[];

  constructor(private authService: AuthService, private sharedApiService: SharedApiService, private sharedService: SharedService, private adminService: AdminService) { }

  ngOnInit() {
    this.admin = false;
    this.adminService.getUserIsAdmin()
      .subscribe((isAdmin: boolean) => {
        this.admin = isAdmin;
    });
    this.adminEnteredCurrency = '$';
    this.adminNumberOfPeople = 1;
  };


  getTripsForApproval(): void {
    this.adminService.getTripsForApproval()
      .subscribe((t: Trip[]) => {
        this.recentTrips = t.filter(element => {
          return element.ApprovalStatus != ApprovalStatus.Approved && element.ApprovalStatus != ApprovalStatus.Rejected;
        });
      })
  }

  approve(t: Trip) {
    let body = {
      ApprovalStatus: ApprovalStatus.Approved
    }
    this.adminService.updateTripStatus(t.Id, body)
    .subscribe();
  }

  iModify(t: Trip) {
    let body = {
      ApprovalStatus: ApprovalStatus.InternalModificationRequired
    }
    this.adminService.updateTripStatus(t.Id, body)
    .subscribe();
  }

  eModify(t: Trip) {
    let body = {
      ApprovalStatus: ApprovalStatus.UserModificationRequired
    }
    this.adminService.updateTripStatus(t.Id, body)
    .subscribe();
  }

  reject(t: Trip) {
    let body = {
      ApprovalStatus: ApprovalStatus.Rejected
    }
    this.adminService.updateTripStatus(t.Id, body)
    .subscribe();
  }


  getFlaggedMaps() {
    this.adminService.getFlaggedMaps().subscribe((maps) => {
      this.flaggedMaps = maps;
    })
  }

  submitNewMapPlaceQuery() {
    this.adminService.issueProvisionalWithPlace(this.newMapQuery).subscribe((data) => {
      this.newMapJSON = data;
    });
  }

  submitNewMapLatLngQuery() {
    this.adminService.issueProvisionalWithLatLng(this.newMapLat, this.newMapLng).subscribe((data) => {
      this.newMapJSON = data;
    });
  }

  acceptNewMapAsReplacement(Id: string) {
    this.adminService.updateMap(Id, this.newMapLat, this.newMapLng, this.newMapQuery).subscribe(() => {
      window.location.assign('/trip/' + Id);
    });
  }

  createNewMap(queryName: string, placeName: string) {
    this.adminService.newMap(queryName, placeName).subscribe();
  }

  toggleFlag(Id: string, Value: boolean) {
    this.adminService.toggleMapFlag(Id, Value).subscribe();
  }

  getPlacesWithoutAllEstimates() {
    this.adminService.getPlaces().subscribe(place => {
      this.filteredPlaces = place.filter(element => {
        return !element.WeightedBudgetCost || !element.WeightedMidRangeCost || !element.WeightedLuxuryCost
      });
      this.filteredPlaces = this.sortFilteredPlaces();
    });
  }

  getPlacesWithoutMaps() {
    let listOfPlacesWithoutMaps = [];
    this.adminService.getPlaces().subscribe(places => {
      this.adminService.getMaps().subscribe(maps => {
        places.forEach(element => {
          let elementHasMap = false;
          maps.forEach(el => {
            if (el.Place == element.Name) {
              elementHasMap = true;
            }
          })
          
          if (!elementHasMap) {
            listOfPlacesWithoutMaps.push(element);
          }
        })
      });
      this.placesWithoutMaps = listOfPlacesWithoutMaps;
    });
  }

  getAllPlaces() {
    this.adminService.getPlaces().subscribe(data => {
      this.places = data;
    })
  }

  sortFilteredPlaces() {
    return this.filteredPlaces.sort(function(a, b) {
      if (a.Name != null && a.Country != null) {
        if (b.Country != null && b.Name != null) {
          return Number(a.Name == a.Country) - Number(b.Country == b.Name)
        } else {
          return -98;
        }
      } else {
        return -99;
      }
    });
  }

  generateItinerary(place, budget, duration) {
    let res = this.adminService.generateItinerary(place, budget, duration).subscribe(data => {
      this.newItinerary = data;
      this.itineraryPlace = place;
      this.itineraryLuxury = budget;
      this.itineraryDuration = duration;
    //    Attempt to categorize data 
    //    this.adminService.identifyCost 
    //    all below can be done later because we are only interested in filling out existing places right now not net new
    //    this.adminService.getAirportForPlaceIfNecessary
    //    this.adminService.getLatLngIfNecessary
    //    this.adminService.getProvisionalMapIfNecessary
    //    this.adminService.getPhotosIfNecessary
    //   console.log(data);
    });
  }

  firstSubmit() {
    this.firstSubmission = true;
  }

  submitAdminData() {
    if (this.itineraryDuration && this.itineraryPlace && this.newItinerary && this.itineraryLuxury && this.adminEnteredCurrency && this.adminEnteredPrice && this.adminNumberOfPeople) {
      this.adminService.createAiTrip(
        this.itineraryPlace,
        this.itineraryLuxury,
        this.itineraryDuration,
        this.adminEnteredPrice,
        this.adminEnteredCurrency,
        this.adminNumberOfPeople,
        this.newItinerary
      ).subscribe(() => {
        this.resetData();
      });
    }
  }

  resetData() {
    this.newItinerary = '';
    this.itineraryPlace = '';
    this.itineraryLuxury = '';
    this.itineraryDuration = '';
    this.adminEnteredCurrency = '$';
    this.adminEnteredPrice = '';
    this.adminNumberOfPeople = 1;
    this.firstSubmission = false;
  }

  openNameQuery() {
    this.separateNameQuery = true;
  }

  submitSeparateNewMapPlaceQuery() {

  }

  acceptSeparateNewMapAsReplacement(place: string) {

  }
}
