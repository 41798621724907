import { Component, OnInit, Input } from '@angular/core';
import { Trip } from 'src/models/Trip';
import { Airport } from 'src/models/Airport';
import { FlightOffer } from 'src/models/FlightOffer';
import { Typeahead } from 'src/models/Typeahead';
import { AuthService } from '../auth.service';
import { SharedService } from '../shared/shared.service';
import { SharedApiService } from '../shared/shared-api.service';
import { FlightsService } from './flights.service';
import { OverlayService } from '../overlay/overlay.service';

@Component({
  selector: 'app-flights',
  templateUrl: './flights.component.html',
  styleUrls: ['./flights.component.scss']
})
export class FlightsComponent implements OnInit {
  @Input() tripAirport: Airport;
  showChecked: boolean;
  showAirport: boolean;
  userAirport: Airport;
  flightOffers: FlightOffer[];
  flightCost: string;
  showLoadingIcon: boolean;
  departureDate: string;
  returnDate: string;
  numberOfPeeps: number;
  iataports: Typeahead[];
  airport: string;
  showError: boolean;
  loadingText: string;
  loadingDisplay: string;
  airportCity: string;
  inDelay: boolean;

  constructor(
    private flightsService: FlightsService, 
    private sharedApiService: SharedApiService, 
    private overlayService: OverlayService, 
    private authService: AuthService, 
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.showChecked = false;
    this.showLoadingIcon = false;
    this.airport = "";
    this.sharedApiService.getUserAirport().subscribe((t => {
      if (t) {
        this.userAirport = t;
      } else {
        this.showAirport = true;
      }
      
    }));
  
    // TODO figure out how to add datetime to set return date
    let date = new Date().toISOString();
    this.departureDate = date.slice(0,10);
    this.numberOfPeeps = 1;
    this.returnDate = "";
  }


  getFlight() {
    this.showError = false;
    if (this.tripAirport) {
      if (this.authService.isLoggedIn()) {
        if (!this.userAirport && this.airport != "" && this.airport != null) {
          this.showLoadingIcon = true;
          this.updateAirportsAndMakeFlightRequest();
        } else if (this.userAirport) {
          this.showLoadingIcon = true;
          this.airportCity = this.userAirport.City;
          this.makeFlightRequest(this.userAirport.IATACode);
        } else {
          this.showAirport = true;
        }
      } else {
        if (this.airport != "") {
          this.airportCity = this.airport;
          this.iataports[0].address.cityName;
          this.makeFlightRequest(this.airport);
          this.showLoadingIcon = true;
        } else {
          // Put an error telling the user they need to enter an airport
        }
      }
    }
  }

  setFlightCost() {
    let low = 9999999;
    this.flightOffers.forEach(element => {
      if (parseFloat(element.price.grandTotal) < low) {
        low = parseFloat(element.price.grandTotal);
      }
    })
    this.flightCost = low.toString();
  }

  makeFlightRequest(iataCode) {
    if (this.returnDate == "") {
      this.returnDate = "none";
    }
    if (this.numberOfPeeps == 0) {
      this.numberOfPeeps = 1;
    }
    this.flightsService.getFlight(iataCode, this.tripAirport.IATACode, this.departureDate, this.returnDate, this.numberOfPeeps).subscribe(data => {
      if (data.FlightOffers.length > 0) {
        if (data.cached) {
          // TODO handle cached - must manipulate based on params
          this.flightOffers = data.FlightOffers;
          // this.flightCost = this.flightOffers[0].price.grandTotal;
          this.setFlightCost();
        } else {
          this.flightOffers = data.FlightOffers;
          this.flightCost = this.flightOffers[0].price.grandTotal;
        }
      } else {
        this.showError = true;
      }
      this.showLoadingIcon = false;
    });
  }

  passesFlightCheck() {
    let yesterday = new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0,10);
    return this.departureDate != "" && this.numberOfPeeps < 20 && (this.returnDate == "" || this.returnDate >= this.departureDate) && this.departureDate >= yesterday; 
  }

  getCheapDates() {
    this.showError = false;
    if (this.tripAirport) {
      this.showLoadingIcon = true;
      if (this.authService.isLoggedIn()) {
        if (this.userAirport) {
          this.flightsService.getCheapDates(this.userAirport.IATACode, this.tripAirport.IATACode).subscribe(data => {
            if (data.length > 0) {
              this.flightOffers = data;

              this.flightCost = this.flightOffers[0].price.grandTotal;
            } else {
              this.showError = true;
            }
            this.showLoadingIcon = false;
          });
        } else {
          this.showAirport = true;
        }
      } else {
        this.overlayService.open("login");
      }
    }
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) ).then(() => this.inDelay = false);
  }

  watchAirportInput(value: string) {
    // API spamming is still an issue with delay so we should cache airport codes in a separate database
    if (!this.inDelay) {
      if (value.length > 2) {
        this.sharedApiService.getAirportTypeAhead(value).subscribe(data => {
          this.iataports = data;
        });
        this.inDelay = true;
        this.delay(500);
      }
    }
  }

  updateAirports() {
    if (this.airport != "" && this.airport != null) {
      this.iataports.forEach(element => {
        if (element.iataCode == this.airport) {
          let userAirport: Airport = {
            City: element.address.cityName,
            IATACode: element.iataCode,
            Country: element.address.countryName,
            Continent: "",
          };
          this.sharedApiService.updateAirport(userAirport).subscribe();
        }
      });
    }
  }

  updateAirportsAndMakeFlightRequest() {
    if (this.airport != "" && this.airport != null) {
      this.iataports.forEach(element => {
        if (element.iataCode == this.airport) {
          let userAirport: Airport = {
            City: element.address.cityName,
            IATACode: element.iataCode,
            Country: element.address.countryName,
            Continent: "",
          };
          this.sharedApiService.updateAirport(userAirport).subscribe(() => {
            this.userAirport = userAirport;
            this.airportCity = this.userAirport.City;
            this.makeFlightRequest(this.userAirport.IATACode);
          });
        }
      });
    }
  }
}
