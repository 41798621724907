<section>
    <div class="Visa-fullRow">
        <div class="Visa-left">
            <div class="Visa-imageWrapper">
                <img class="Visa-image" src="../../assets/{{this.visaColor}}passport.svg">
            </div>
            <div class="Visa-box Visa-box--{{this.visaColor}}">
                <div class="Visa-boxTitle">
                    {{ visa.VisaRequirement }}
                </div>
                <div class="Visa-boxSub" *ngIf="this.visaColor != 'red'">
                    Valid for {{ visa.AllowedStay }}
                </div>
            </div>
        </div>
        <!-- Need to implement the center one for each country -->
        <!-- <div class="Visa-center" *ngIf="this.visaColor != 'green'"> 
            <div class="Visa-centerHeader">
                Apply in person
            </div>
            <div class="Visa-centerLink">
                <a href="https://example.com">Link example</a>
            </div>
            <div class="Visa-centerCost">
                Approximately $50
            </div>
        </div> -->
        <div class="Visa-right" *ngIf="visa.Notes != ''">
            <div class="Visa-seeNotes" (click)="this.rotate()">
                <img class="Visa-chevron" [ngClass]="this.rotateChevron ? 'rotate' : ''" src="../../assets/chevron.svg"> {{ this.rotateChevron ? 'Collapse notes' : 'See notes' }} 
            </div>

            <div class="Visa-notes" *ngIf="this.rotateChevron" [@slideInOut]>
                {{ visa.Notes }}

                <div class="Visa-source">
                    Source: Wikipedia
                </div>
            </div>
        </div>
    </div>
</section>