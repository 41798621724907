<section *ngIf="this.admin" class="Admin-approval">
    <button class="Admin-button" (click)="getPlacesWithoutAllEstimates()">
        Get Places without budget, mid-range, and luxury estimates
    </button>
    <div *ngIf="this.filteredPlaces">
        <div class="Admin-placeTitle">
            Total places needing estimates: {{this.filteredPlaces.length}}
        </div>
        <div *ngFor="let place of this.filteredPlaces; let i=index">
            <div *ngIf="i < 10">
                <div class="Admin-placeContentWrapper">
                    <div class="Admin-placeTitle">
                        Current content for {{place.Name}}
                    </div>
                    <div>
                        <span class="Admin-placeContent">AI Trip Ids:</span> {{place.DirectTripIdsAi}}
                    </div>
                    <div>
                        <span class="Admin-placeContent">Budget Average AI:</span> {{place.BudgetAverageCostAi}}
                    </div>
                    <div>
                        <span class="Admin-placeContent">Midrange Average AI:</span> {{place.MidRangeAverageCostAi}}
                    </div>
                    <div>
                        <span class="Admin-placeContent">Luxury Average AI:</span> {{place.LuxuryAverageCostAi}}
                    </div>
                    <div>
                        <span class="Admin-placeContent">Weighted budget:</span> {{place.WeightedBudgetCost}}
                    </div>
                    <div>
                        <span class="Admin-placeContent">Weighted midrange:</span> {{place.WeightedMidRangeCost}}
                    </div>
                    <div>
                        <span class="Admin-placeContent">Weighted luxury:</span> {{place.WeightedLuxuryCost}}
                    </div>
                </div>

                <div>
                    <button class="Admin-button Admin-button--budget" (click)="generateItinerary(place.Name, 'Budget', '4')">
                        Solicit budget AI for {{place.Name}}
                    </button>
                    <button class="Admin-button Admin-button--midrange" (click)="generateItinerary(place.Name, 'Mid-range', '4')">
                        Solicit midrange AI for {{place.Name}}
                    </button>
                    <button class="Admin-button Admin-button--luxury" (click)="generateItinerary(place.Name, 'Luxury', '4')">
                        Solicit luxury AI for {{place.Name}}
                    </button>
                </div>
                <div *ngIf="newItinerary" class="Admin-itinerary">
                    <div class="Admin-itinerary--title">
                        New Itinerary:
                    </div>
                    <div>
                        {{ this.newItinerary }}
                    </div>
                    
                    <!-- // IDEAL Architecture:
                    // Front end says we need a new place
                    // Can specify days/country/luxury or just use defaults
                    // We run the process off the user input
                    // input returns to front end
                    // admin approves or not
                    // if approved send it to db
                    // Send some email alerts (put this whole thing on a jenkins job that runs recurringly) -->
                    <div class="Admin-newItineraryOptions">
                        <div class="Admin-placeTitle">
                            Based on the above itinerary here are a few questions:
                        </div>
                        <div class="Admin-question">
                            <div>
                                What is the price of the above itinerary?
                            </div>
                            <div>
                                <input class="Admin-input" placeholder="Price as number without currency e.g. 1,000" [(ngModel)]="this.adminEnteredPrice">
                            </div>
                        </div>
                        <div class="Admin-question">
                            <div>
                                What is the currency? ($, €, £)
                            </div>
                            <div>
                                <input class="Admin-input" placeholder="$" [(ngModel)]="this.adminEnteredCurrency">
                            </div>
                            <div>
                                <button class="Admin-button">Save</button>
                            </div>
                        </div>
                        <div class="Admin-question">
                            <div>
                                How many people?
                            </div>
                            <div>
                                <input class="Admin-input" placeholder="1" [(ngModel)]="this.adminNumberOfPeople">
                            </div>
                        </div>
                        <div class="Admin-question">
                            <button class="Admin-button" (click)="this.firstSubmit()">
                                Submit new data
                            </button>
                        </div>
                        <div class="Admin-prompt" *ngIf="this.firstSubmission">
                            <div class="Admin-placeTitle">
                                Please verify the following data about the above itinerary and click Submit or Cancel
                            </div>
                            
                        
                            <div>
                                <div class="Admin-row">
                                    Place: {{ this.itineraryPlace }}
                                </div>
                                <div class="Admin-row">
                                    Luxury level: {{ this.itineraryLuxury }}
                                </div>
                                <div class="Admin-row">
                                    Duration: {{ this.itineraryDuration }}
                                </div>
                                <div class="Admin-row">
                                    Total cost: {{ this.adminEnteredPrice }}
                                </div>
                                <div class="Admin-row">
                                    Currency: {{ this.adminEnteredCurrency }}
                                </div>
                                <div class="Admin-row">
                                    Number of people: {{ this.adminNumberOfPeople }}
                                </div>
                            </div>
                            <div>
                                <button (click)="this.submitAdminData()" class="Admin-submit">
                                    Submit
                                </button>
                                <button (click)="this.resetData()" class="Admin-cancel">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button class="Admin-button" (click)="getAllPlaces()">
        Get Places 
    </button>
    <button class="Admin-button" (click)="getTripsForApproval()">
        Get Unapproved Trips
    </button>
    <div *ngFor="let trip of recentTrips; let i=index">
        <pre>
            <div class="Admin-adminTrip">
                {{trip | json}}
            </div>
        </pre>
        
        <button class="Admin-tripCTA" (click)="approve(trip)">
            Approve
        </button>
        <button class="Admin-tripCTA" (click)="iModify(trip)">
            Require Internal Modification
        </button>
        <button class="Admin-tripCTA" (click)="eModify(trip)">
            Require User Modification
        </button>
        <button class="Admin-tripCTA" (click)="reject(trip)">
            Reject
        </button>
    </div>

    <button class="Admin-button" (click)="getPlacesWithoutMaps()">
        Get Places With No Maps
    </button>

    <div *ngFor="let place of this.placesWithoutMaps; let i=index">
        <div *ngIf="i < 10">
            {{ place.Name }}
            <div class="Admin-row">
                    <div>
                        <div class="Admin-mapQueryTitle">
                            New Place Name Query for {{ place.Name }}
                        </div>
                        <span>New Name Query</span>
                        <input [(ngModel)]="newMapQuery" class="Admin-input" placeholder="Halong Bay, Vietnam">
                        <button class="Admin-button" (click)="submitNewMapPlaceQuery()">
                            Submit Name Query
                        </button>
                    </div>
    
                    <div class="Admin-row" *ngIf="this.newMapJSON">
                        <div class="Admin-mapQueryTitle">
                            New map:
                        </div>
                        <div class="Admin-mapWrapper Profile-row" >
                            <img class="Admin-mapImage" *ngIf="newMapJSON" [ngStyle]="{'background-image': 'url(data:image/jpg;base64,' + newMapJSON + ')', 'background-size': 'cover'}">
                        </div>
                        <div class="Admin-row">
                            <button class="Admin-button" (click)="createNewMap(newMapQuery, place.Name)">
                                Accept new map
                            </button>
                        </div>
                    </div>
                </div>
                <hr>
        </div>
    </div>
 

    <button class="Admin-button" (click)="getFlaggedMaps()">
        Get Flagged Maps
    </button>
    <div class="Admin-flaggedMapsWrapper" *ngIf="this.flaggedMaps">
        <div *ngFor="let m of this.flaggedMaps" class="Admin-flaggedMap">
            <div class="Admin-mapQueryTitle">
                {{ m.Place }} map
            </div>
            <div class="Admin-row">
                {{ m.IsFlagged ? 'Unf' : 'F'}}lag this map
                <div> 
                    (only do this if the map shown is already correct, accepted submissions below will automatically unflag)
                </div>
                <div (click)="toggleFlag(m.Id, !m.IsFlagged); m.IsFlagged=!m.IsFlagged" *ngIf="!m.IsFlagged">
                        <img class="Admin-flag" src="../../assets/flag-empty.svg" >
                </div>
                
                <div (click)="toggleFlag(m.Id, !m.IsFlagged); m.IsFlagged=!m.IsFlagged" *ngIf="m.IsFlagged">
                    <img class="Admin-flag" src="../../assets/flag-filled.svg" >
                </div> 

            </div>
            <img class="Admin-mapImage" *ngIf="m.MapBinary.bin" [ngStyle]="{'background-image': 'url(data:image/jpg;base64,' + m.MapBinary.bin + ')', 'background-size': 'cover'}">
            <div class="Admin-row">
                <div>
                    <div class="Admin-mapQueryTitle">
                        New Place Name Query for {{ m.Place }}
                    </div>
                    <span>New Name Query</span>
                    <input [(ngModel)]="newMapQuery" class="Admin-input" placeholder="Halong Bay, Vietnam">
                    <button class="Admin-button" (click)="submitNewMapPlaceQuery()">
                        Submit Name Query
                    </button>
                </div>
                <div>
                    <div class="Admin-mapQueryTitle">
                        Latitude, Longitude Query for {{ m.Place }}
                    </div>
                    <span>
                        Latitude
                    </span>
                    <input [(ngModel)]="newMapLat" class="Admin-input" placeholder="40.416775">
                    <span>
                        Longitude
                    </span>
                    <input [(ngModel)]="newMapLng" class="Admin-input" placeholder="-3.703790">
                    <button class="Admin-button" (click)="submitNewMapLatLngQuery()">
                        Submit Lat, Lng Query
                    </button>

                </div>

                <div class="Admin-row" *ngIf="this.newMapJSON">
                    <div class="Admin-mapQueryTitle">
                        New map:
                    </div>
                    <div class="Admin-mapWrapper Profile-row" >
                        <img class="Admin-mapImage" *ngIf="newMapJSON" [ngStyle]="{'background-image': 'url(data:image/jpg;base64,' + newMapJSON + ')', 'background-size': 'cover'}">
                    </div>
                    <div class="Admin-row">
                        <button class="Admin-button" (click)="acceptNewMapAsReplacement(m.Id)">
                            Replace existing map with new map
                        </button>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>

    <button class="Admin-button" (click)="openNameQuery()">
        Submit name query for map
    </button>
    <div class="Admin-row" *ngIf="this.separateNameQuery">
        <div>
            <span>New Name Query</span>
            <input [(ngModel)]="separateNewMapQuery" class="Admin-input" placeholder="Halong Bay, Vietnam">
            <button class="Admin-button" (click)="submitSeparateNewMapPlaceQuery()">
                Submit Name Query
            </button>
        </div>
        <div class="Admin-row">
            <div class="Admin-mapQueryTitle">
                Old Map: (map appear blank if map does not exist)
            </div>
            <div class="Admin-mapWrapper Profile-row" >
                <img class="Admin-mapImage" *ngIf="separateOldMapJSON" [ngStyle]="{'background-image': 'url(data:image/jpg;base64,' + separateOldMapJSON + ')', 'background-size': 'cover'}">
            </div>
        </div>
        <div class="Admin-row" *ngIf="this.newMapJSON">
            <div class="Admin-mapQueryTitle">
                New map:
            </div>
            <div class="Admin-mapWrapper Profile-row" >
                <img class="Admin-mapImage" *ngIf="separateNewMapJSON" [ngStyle]="{'background-image': 'url(data:image/jpg;base64,' + separateNewMapJSON + ')', 'background-size': 'cover'}">
            </div>
            <div class="Admin-row">
                <button class="Admin-button" (click)="acceptSeparateNewMapAsReplacement(separateNewMapQuery)">
                    Replace existing map with new map
                </button>
            </div>
        </div>
    </div>
</section>