import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { Transit, TransitMode } from 'src/models/Transit';

@Component({
  selector: 'app-transit',
  templateUrl: './transit.component.html',
  styleUrls: ['./transit.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({'max-height': '0px'}),
        animate('350ms ease-in', style({'max-height': '1000px'}))
      ])
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({transform: 'translateY(-10%)'}),
        animate('350ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('0ms ease-out', style({transform: 'translateY(0%)'}))
      ])
    ]),
    trigger('rotator', [
      transition(':enter', [
        style({transform: 'rotate(180deg)'}),
        animate('350ms ease-in', style({transform: 'rotate(0deg)'}))
      ])
    ])
  ]
})
export class TransitComponent implements OnInit {
  selectedImage: string;
  transitSelected: boolean;
  showOptions: boolean;
  showAddTransit: boolean;

  @Input() transit: Transit;
  @Input() transits: Transit[];

  constructor() {}

  ngOnInit() {
    this.showOptions = false;
    this.showAddTransit = true;

    if (this.transit && this.transit.Mode != null) {
      this.transitSelected = true;
      this.showAddTransit = false;
      this.showOptions = true;
      this.selectedImage = this.imageFromOption(TransitMode[this.transit.Mode]);
    }
  }

  imageFromOption(option) {
    return "../../assets/white" + option.toLowerCase() + ".svg";
  }

  selectTransit(option) {
    this.transitSelected = true;
    this.showAddTransit = false;
    if (option == 'Plane') {
      this.selectedImage = "../../assets/whiteplane.svg"
      this.transit.Mode = TransitMode.Plane;
    } else if (option == "Train") {
      this.selectedImage = "../../assets/whitetrain.svg";
      this.transit.Mode = TransitMode.Train;
    } else if (option == "Bus") {
      this.selectedImage = "../../assets/whitebus.svg";
      this.transit.Mode = TransitMode.Bus;
    } else if (option == "Car") {
      this.selectedImage = "../../assets/whitecar.svg";
      this.transit.Mode = TransitMode.Car;
    } else if (option == "Boat") {
      this.selectedImage = "../../assets/whiteboat.svg";
      this.transit.Mode = TransitMode.Boat;
    } else {
      this.transitSelected = false;
      this.showAddTransit = true;
    }
  }

  deselectTransit() {
    this.transitSelected = false;
    this.showAddTransit = true;
    this.showOptions = false;
    this.transit.Mode = null;
  }
}
