<!---
Don't want to chat, but still want to contribute data? This way

Where'd ya go?
Awesome, how long were you in x?
Would you say you travelled Budget, Mid-range, or Luxury?
IF data:
  Sweet, we think it cost about 1000-2000, is that right?

  Nice glad to know we're on the mark. What did you spend?
Else:
  Congratulations! You are our first traveler to Morocco, how much did it cost you for x?

Did you visit anywhere else while you were in x?

Lastly, for this trip would you say you were a foodie, hotel lounger, attraction guru, or something else?

-->

<section class="Create-wrapper">
    <div class="Create">
        <div class="Create-content">
            <div class="Create-stepCounter">
                Step {{ this.internalIndex }} of 8
            </div>
            <div class="Create-progress">
                <div class="Create-bar"></div>
            </div>
            <div class="Create-row" *ngIf="this.internalIndex == 1">
                <div class="Create-internalRow">
                    <label class="Create-label">
                        Where's the main place you went?
                    </label>                   
                </div>
                <div class="Create-internalRow">
                    <input class="Create-input" placeholder="Bangkok" [(ngModel)]="Create.Place" (keyup.enter)="next()" autofocus>
                </div>
                <div class="Create-internalRow">
                    <button class="Create-cta" (click)="next()">
                        Next
                    </button>
                </div>
            </div>
            <div class="Create-row" *ngIf="this.internalIndex == 2">
                <div class="Create-internalRow">
                    <label class="Create-label">
                        Awesome, how long were you in {{ Create.Place }}?
                    </label>
                </div>
                <div class="Create-internalRow">
                    <input class="Create-input" placeholder="14 days" [(ngModel)]="Create.Duration" (keyup.enter)="next()">
                </div>
                <div class="Create-internalRow">

                    <button class="Create-cta" (click)="previous()">
                        Previous
                    </button>
                    <button class="Create-cta" (click)="next()">
                        Next
                    </button>
                </div>
            </div>
            <div class="Create-row" *ngIf="this.internalIndex == 3">
                <div class="Create-internalRow">
                    <label class="Create-label">
                        How many people were you?
                    </label>
                </div>
                <div class="Create-internalRow">
                    <input class="Create-input" placeholder="2" [(ngModel)]="Create.NumberOfPeople" (keyup.enter)="next()">
                </div>
                <div class="Create-internalRow">
                    <button class="Create-cta" (click)="previous()">
                        Previous
                    </button>
                    <button class="Create-cta" (click)="next()">
                        Next
                    </button>
                </div>
            </div>
            <div class="Create-row" *ngIf="this.internalIndex == 4">
                <div class="Create-internalRow">
                    <label class="Create-label">
                        <!-- There's a bug in this code where if someone doesn't choose number of people it says undefined, but it's funny so I'm leaving it -->
                        {{ Create.NumberOfPeople == '1' ? 
                            'How much did you spend? (no need to include airfare)': 
                            'How much did it cost for the ' + Create.NumberOfPeople + ' of you?'
                        }}                    
                    </label>
                </div>
                <div class="Create-internalRow">
                    <input class="Create-input" *ngIf="!this.chad && !this.man" placeholder="$2000" [(ngModel)]="Create.Cost" (keyup.enter)="next()">
                </div>
                <div class="Create-internalRow">
                    <button class="Create-cta" (click)="previous()">
                        Previous
                    </button>
                    <button class="Create-cta" (click)="next()">
                        Next
                    </button>
                </div>
                <div class="Create-internalRow">
                    <button class="Create-cta" (click)="this.chad=!this.chad">idk what those chads spent, but I know how much I spent</button>
                    <input class="Create-input" *ngIf="this.chad" placeholder="$1940.78" [(ngModel)]="individualCost" (keyup.enter)="next()">
                    <button class="Create-cta" (click)="this.man=!this.man">I just know my cost per day man</button>
                    <input class="Create-input" *ngIf="this.man" placeholder="$1021.22" [(ngModel)]="dailyCost" (keyup.enter)="next()">
                </div>
            </div>
            <div class="Create-row" *ngIf="this.internalIndex == 5">
                <div class="Create-internalRow">
                    <label class="Create-label">
                        Would you say you travelled Budget, Mid-range, or Luxury?
                    </label>
                </div>
                <div class="Create-internalRow">
                    <select [(ngModel)]="Create.Luxury" class="Create-input" (keyup.enter)="next()">
                        <option value="" selected>--Luxury Level--</option>
                        <option value="Budget">Budget</option>
                        <option value="Medium">Mid-range</option>
                        <option value="Luxury">Luxury</option>
                    </select>
                </div>
                <div class="Create-internalRow">

                    <button class="Create-cta" (click)="previous()">
                        Previous
                    </button>
                    <button class="Create-cta" (click)="next()">
                        Next
                    </button>
                </div>
            </div>
            <div class="Create-row" *ngIf="this.internalIndex == 6">
                <div class="Create-internalRow">
                    <label class="Create-label">
                        Did you visit any other places around {{ Create.Place }} ?
                    </label>
                </div>
                <div class="Create-internalRow">
                    <input class="Create-input" placeholder="Pattaya, Chiang Mai, Siem Reap" [(ngModel)]="Create.OtherPlaces" (keyup.enter)="next()">
                </div>
                <div class="Create-internalRow">
                    <button class="Create-cta" (click)="previous()">
                        Previous
                    </button>
                    <button class="Create-cta" (click)="next()">
                        Next
                    </button>
                </div>
                
            </div>
            <div class="Create-row" *ngIf="this.internalIndex == 7">
                <div class="Create-internalRow">
                    <label class="Create-label">
                        Credit baby, where do you want us to drive traffic that clicks on your post?
                    </label>
                </div>
                <div class="Create-internalRow">
                    <input class="Create-input" placeholder="" [(ngModel)]="Create.URL" (keyup.enter)="next()">
                </div>
                <div class="Create-internalRow">

                    <button class="Create-cta" (click)="previous()">
                        Previous
                    </button>
                    <button class="Create-cta" (click)="next()">
                        Next
                    </button>
                </div>
            </div>
            <div class="Create-row" *ngIf="this.internalIndex == 8">
                <div class="Create-internalRow">
                    <label class="Create-label">
                        Tag this post with something you think makes sense.
                    </label>
                </div>
                <div class="Create-internalRow">
                    <input class="Create-input" placeholder="Foodie" [(ngModel)]="Create.Tags" (keyup.enter)="addByUser()">
                </div>
                <div class="Create-internalRow">
                    <button class="Create-cta" (click)="previous()">
                        Previous
                    </button>
                    <button class="Create-cta Create-submitCTA" (click)="addByUser()">
                        Submit
                    </button>
                </div>
            </div>

            <!-- <div class="Create-raw">
                <a href="">
                    Screw stepped forms, let me see all 8 steps at once.
                </a>
            </div> -->
        </div>
    </div>

    <div class="Create-external" *ngIf="this.internalIndex < 3">
        psst... do you want us to just grab your data from a link somewhere?
        <div class="Create-internalRow">
            <input class="Create-input" placeholder="somewhere.com" [(ngModel)]="this.link" (keyup.enter)="submitLink()">
            <button class="Create-cta Create-crawl" (click)="submitLink()">
                Submit
                <!-- Crawl Me -->
            </button>
        </div>
        <!-- Not ready for prime time yet, need to look at the  -->
        <!-- <div class="Create-internalRow">
            Or throw us an excel sheet and we'll peep that
        </div>
        <div class="Create-internalRow">
            <input type="file" accept=".csv,.xls,.xlsx" class="Create-input" placeholder="What does this do" [(ngModel)]="this.sheet" (keyup.enter)="submitSheet()">
            <div>
                <button class="Create-cta Create-crawl" (click)="submitSheet()">
                    Submit
                </button>
            </div>
        </div> -->
    </div>

</section>